import type { ReactNode } from 'react';
import { memo, useContext, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLang, useMeta, useTitle } from 'hoofd';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import { AppContext } from '../context';
import useCurrentStore from '../hooks/useCurrentStore';
import MaintenanceBanner from '../../main/components/MaintenanceMode/MaintenanceBanner';
import { getMetaLinksFromAvailableLanguages } from '../util/getMetaLinksFromAvailableLanguages';
import useStoreInformation from '../../content/queries/useStoreInformation';
import { getCurrentURL } from '../util/getCurrentURL';
import Header from './Header';
import styles from './Layout.module.scss';
import Footer from './footer/StoreFooter';
import ScrollToTop from './ScrollToTop';
import withErrorBoundary from './errors/withErrorBoundary';
import ComponentSuspense from './router/ComponentSuspense';
import LazyComponent from './router/LazyComponent';
import { MetaLink } from './MetaLink';

interface LayoutProps {
    children: ReactNode;
}

const ImpersonationActiveBanner = LazyComponent(
    async () => import('./layout/ImpersonationActiveBanner')
);

export const Layout = withErrorBoundary(
    memo(({ children }: LayoutProps) => {
        const { language, availableLanguages } = useStoreAndLanguageParams();

        const { companyName: title } = useCurrentStore();
        const { isImpersonating } = useContext(AppContext);
        const { data: storeData } = useStoreInformation();
        const location = useLocation();

        const { pageTitle, description } = useMemo(() => {
            const seoConfig = storeData?.seo_config?.find(
                config => location.pathname.indexOf(config.page) !== -1
            );
            if (!seoConfig) {
                return {
                    pageTitle: title,
                    description: title,
                };
            }
            return {
                pageTitle: seoConfig.meta_fields.title,
                description: seoConfig.meta_fields.description,
            };
        }, [location.pathname, storeData?.seo_config, title]);

        const currentURL = getCurrentURL();
        const arePreOrderRoutes = window.location.href.includes('preorder');

        const headerRef = useRef<HTMLDivElement>(null);

        const metaLinks = useMemo(
            () =>
                getMetaLinksFromAvailableLanguages(
                    availableLanguages,
                    language
                ),
            [availableLanguages, language]
        );

        useTitle(pageTitle);
        useLang(language);
        useMeta({ name: 'description', content: description });

        return (
            <>
                {metaLinks.map(({ language: lang, href }) => (
                    <MetaLink
                        key={lang}
                        rel="alternate"
                        hreflang={lang}
                        href={href}
                    />
                ))}
                <MetaLink rel="alternate" href={currentURL} />
                <div className={styles.layout}>
                    {isImpersonating && (
                        <ComponentSuspense height="40px">
                            <ImpersonationActiveBanner />
                        </ComponentSuspense>
                    )}
                    <Header ref={headerRef} />
                    <MaintenanceBanner />
                    <main className={styles.mainContent}>{children}</main>
                    <Footer />
                </div>
                {arePreOrderRoutes && <ScrollToTop headerRef={headerRef} />}
            </>
        );
    })
);
