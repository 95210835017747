import type {
    Asset,
    BaseComponent,
    DateVisibility,
    HideForProps,
} from './base';
import type {
    CustomStoryblokComponents,
    RichComponent,
    SocialLinkComponent,
} from './customStoryblokComponents';
import type { BaseStoryblokComponents } from './storyblokComponents';

type CustomStoryblokComponentsHandlers = {
    [K in CustomStoryblokComponents['component']]: (
        params: Extract<CustomStoryblokComponents, { component: K }>,
        customStyles?: Styles
    ) => JSX.Element | null;
};

type BaseStoryblokComponentsHandlers = {
    [K in BaseStoryblokComponents['type']]: (
        params: Extract<BaseStoryblokComponents, { type: K }>,
        customStyles?: Styles
    ) => JSX.Element | null;
};

type ComponentsHandler = BaseStoryblokComponentsHandlers &
    CustomStoryblokComponentsHandlers;

type Components = BaseStoryblokComponents | CustomStoryblokComponents;

function isAtomicStoryblokComponents(
    component: Components
): component is BaseStoryblokComponents {
    return (component as { type?: string }).type !== undefined;
}

function isCustomStoryblokComponents(
    component: Components
): component is CustomStoryblokComponents {
    return (component as { component?: string }).component !== undefined;
}

function isHideForProps(component: unknown): component is HideForProps {
    return (component as { hide_for?: string }).hide_for !== undefined;
}

function isDateVisibility(component: unknown): component is DateVisibility {
    return (
        (component as { start_date?: string }).start_date !== undefined ||
        (component as { end_date?: string }).end_date !== undefined
    );
}

function isComponent(component: unknown): component is Components {
    return (
        isAtomicStoryblokComponents(component as Components) ||
        isCustomStoryblokComponents(component as Components)
    );
}

function isComponentsArray(array: unknown): array is Components[] {
    return (array as Components[]).some(isComponent);
}

interface ContentType {
    content: Components;
    id: number;
}

function isContentType(obj: unknown): obj is ContentType {
    return !!(
        (obj as { content?: string }).content && !!(obj as { id?: string }).id
    );
}

interface PageComponent extends BaseComponent {
    component: 'page';
    title: string;
    short_title: string;
    version: string;
    lang: string;
    id: string;
    attachment?: Asset;
    body?: Components[];
    meta_fields?: {
        title: string;
        description: string;
    };
}

interface SaleComponent extends BaseComponent {
    component: 'sale';
    short_title: string;
    version: string;
    banner_image: {
        alt: string;
        title: string;
        focus: string;
        filename: string;
    };
    description: string;
    filter_headline: string;
    filter_tagline: string;
    headline: string;
    id: string;
}

interface PostComponent extends BaseComponent {
    component: 'post';
    attachments?: { target: { url: string }; label: string[] }[];
    body?: Components[];
    date: string;
    featured_image: Asset;
    preview_image?: Asset;
    summary: string;
    title: string;
    preview_button_label: string;
    category?: {
        content?: {
            name: string;
        };
        uuid: string;
    };
    meta_fields?: {
        title: string;
        description: string;
    };
}

export interface PostStory {
    content: PostComponent;
    first_published_at: string;
    slug: string;
    id: string;
}

interface FooterSectionComponent extends BaseComponent {
    _uid: string;
    component: 'footer_section';
    title: string;
    links: Components[];
    links_v2?: Components[];
}

interface FooterComponent extends BaseComponent {
    component: 'footer';
    email: string;
    body: FooterSectionComponent[];
    social_links: SocialLinkComponent[];
    contact_information: Components;
    copyright: string;
    under_copyright_body: Components;
    company_address: RichComponent;
    contact_details: RichComponent;
}

interface Styles {
    link?: string;
    linkText?: string;
    paragraph?: string;
}

export type {
    Styles,
    BaseStoryblokComponents,
    CustomStoryblokComponents,
    ComponentsHandler,
    Components,
    PostComponent,
    PageComponent,
    FooterComponent,
    FooterSectionComponent,
    SaleComponent,
    ContentType,
};

export const STORYBLOK_ENVIRONMENTS = [
    'intercycle',
    'hostettler',
    'hostettlerMotorenAg',
    'staging',
];

export {
    isAtomicStoryblokComponents,
    isCustomStoryblokComponents,
    isHideForProps,
    isDateVisibility,
    isComponent,
    isComponentsArray,
    isContentType,
};
