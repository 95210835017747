import type { Language } from '../types';

export interface AvailableLanguage {
    language: Language;
}

export function getMetaLinksFromAvailableLanguages(
    availableLanguages: AvailableLanguage[],
    currentLanguage: Language
): { language: Language; href: string }[] {
    return availableLanguages.map(({ language }) => {
        const path = window.location.pathname;
        const lastOccurrence = path.lastIndexOf(currentLanguage); // check if language is already in the path
        const fullUrl = window.location.href;
        const baseUrl = window.location.origin;

        let href = '';

        if (lastOccurrence === -1) {
            // if language is not in the path, add it but strip the last slash if it exists
            href = fullUrl.endsWith('/')
                ? fullUrl + language
                : fullUrl + '/' + language;
        } else {
            // if language is in the path, replace last occurrence
            const pathWithReplacedLanguage =
                path.substring(0, lastOccurrence) + language;

            href = baseUrl + pathWithReplacedLanguage;
        }

        return {
            href,
            language,
        };
    });
}
