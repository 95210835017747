import type { ReactNode, ReactElement, AriaRole } from 'react';
import { memo, cloneElement, useMemo } from 'react';
import { clsx } from 'clsx';
import styles from './Stack.module.scss';

export const alignmentValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    baseline: 'baseline',
};

export const justifyValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    spaceBetween: 'space-between',
    center: 'center',
};

/**
 * Capitalize first character of input string.
 */
export function capitalize(value?: string) {
    if (!value) {
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Experimental component to resolve issues where we would want to avoid excessive DOM elements.
 *
 * Makes only sense for single elements. We could try limiting this to only known supported components.
 */
export const ExpandedItem = ({ children }: { children: ReactElement }) => {
    return cloneElement(children, {
        expanded: true,
    });
};

export type Justify =
    | 'start'
    | 'end'
    | 'spaceBetween'
    | 'spaceAround'
    | 'center'
    | 'responsiveSpaceBetweenTabletDefault';

export type Align =
    | 'start'
    | 'end'
    | 'center'
    | 'stretch'
    | 'baseline'
    | 'responsiveDefaultTabletCenter';

export interface StackProps {
    children: ReactNode;
    /**
     * Defines the alignment along the cross axis.
     * Cross axis is vertical when direction="row" or direction="rowReverse", horizontal otherwise.
     * @see {@link https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-align-items}
     */
    align?: Align;
    /**
     * Defines the alignment along the main axis.
     * Main axis is horizontal when direction="row" or direction="rowReverse", vertical otherwise.
     * @see {@link https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-justify-content}
     */
    justify?: Justify;
    /**
     * Allows children to wrap to next row/column if needed.
     * @see {@link https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-flex-wrap}
     */
    wrap?: boolean;
    /**
     * Apply one of the pre-defined spacing between children.
     * @see {@link https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-gap-row-gap-column-gap}
     */
    gap?:
        | 'none'
        | 'micro'
        | 'mini'
        | 'small'
        | 'smallMedium'
        | 'medium'
        | 'large'
        | 'huge'
        | 'responsiveSmallTabletMedium'
        | 'responsiveMiniTabletSmall'
        | 'responsiveNoneTabletLarge';
    /**
     * Direction in which the children should be laid.
     * @see {@link https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-flex-direction}
     */
    direction?:
        | 'row'
        | 'rowReverse'
        | 'column'
        | 'columnReverse'
        | 'responsiveColumnTabletRow'
        | 'responsiveColumnTabletRowReversed'
        | 'columnMobileWideRow'
        | 'responsiveColumnTabletWideRowReversed'
        | 'responsiveRowMobileWideColumn';
    /**
     * Component will use all available space.
     */
    expanded?: boolean;
    /**
     * Make container scrollable on overflow
     */
    scrollable?: boolean;
    /**
     * WAI-ARIA role for this stack component
     */
    role?: AriaRole;
    /**
     * id
     */
    id?: string;
}

interface ResponsiveStackProps extends StackProps {
    /**
     * Required because if the prop isn't used, there's no point
     * in using ResponsiveStack itself. Just Stack is sufficient in
     * those cases.
     */
    breakpoint: 'mobile' | 'tablet';
}

export const ResponsiveStack = memo(
    ({
        children,
        gap = 'none',
        wrap,
        align,
        justify,
        direction = 'row',
        expanded,
        breakpoint,
        ...ariaProps
    }: ResponsiveStackProps) => {
        const classNames = useMemo(
            () => ({
                [styles[`${gap}Gap`]]: gap !== 'none',
                [styles[`justify${capitalize(justify)}`]]: justify,
                [styles[`alignment${capitalize(align)}`]]: align,
                [styles.flexWrap]: wrap,
                [styles[`direction${capitalize(direction)}`]]: direction,
                [styles.flexGrow]: expanded,
                [styles[`switchToColumn${capitalize(breakpoint)}`]]: [
                    'row',
                    'rowReverse',
                ].includes(direction),
                [styles[`switchToRow${capitalize(breakpoint)}`]]: [
                    'column',
                    'columnReverse',
                ].includes(direction),
            }),
            [align, breakpoint, direction, expanded, gap, justify, wrap]
        );

        return (
            <div className={clsx(styles.flexLayout, classNames)} {...ariaProps}>
                {children}
            </div>
        );
    }
);

const Stack = ({
    children,
    gap = 'none',
    wrap,
    align,
    justify,
    direction = 'row',
    expanded,
    scrollable,
    ...ariaProps
}: StackProps) => {
    return (
        <div
            className={clsx(styles.flexLayout, {
                [styles[`${gap}Gap`]]: gap !== 'none',
                [styles.noGap]: gap === 'none',
                [styles[`justify${capitalize(justify)}`]]: justify,
                [styles[`alignment${capitalize(align)}`]]: align,
                [styles.flexWrap]: wrap,
                [styles[`direction${capitalize(direction)}`]]: direction,
                [styles.flexGrow]: expanded,
                [styles.scrollable]: scrollable,
            })}
            {...ariaProps}
        >
            {children}
        </div>
    );
};

export default memo(Stack);
