import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  NaiveDate: { input: any; output: any; }
  ProductOrderRestrictionConditions: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AccessoryPage = {
  __typename: 'AccessoryPage';
  label: LocalizedField;
  urlParam: Scalars['String']['output'];
};

export type AccessoryPageInput = {
  label: LocalizedFieldInput;
  urlParam: Scalars['String']['input'];
};

export type Address = {
  __typename: 'Address';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  country: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  customerNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  isPrimary: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
  purposes: Array<Scalars['String']['output']>;
  recId: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
};

export type AddressShippingMethod = {
  __typename: 'AddressShippingMethod';
  branch: Scalars['String']['output'];
  shippingMethodIds: Array<Scalars['String']['output']>;
};

export type ApplicableDeliveryDateRange = {
  __typename: 'ApplicableDeliveryDateRange';
  end: Scalars['String']['output'];
  start: Scalars['String']['output'];
};

export enum AppliedDiscountRule {
  SpecialPrice = 'specialPrice'
}

export enum ArticleCatalogDownloadFileFormat {
  Csv = 'csv',
  Txt = 'txt'
}

export type AvailableCatalog = {
  __typename: 'AvailableCatalog';
  availableAccessoryPages: Array<AccessoryPage>;
  availableBrands: Array<Scalars['String']['output']>;
  name: LocalizedField;
  subtitle: LocalizedField;
};

export type AvailableCatalogInput = {
  availableAccessoryPages: Array<AccessoryPageInput>;
  availableBrands: Array<Scalars['String']['input']>;
  name: LocalizedFieldInput;
  subtitle: LocalizedFieldInput;
};

/**
 * Represents an available document type and it's specification for a given productClass and/or ticket
 * type combination.
 */
export type AvailableDocuments = {
  __typename: 'AvailableDocuments';
  documentType: SupportTicketEventDocumentTypes;
  /** Optional product class to limit this document type to. */
  productClassId?: Maybe<Scalars['Int']['output']>;
  /**
   * No of files that are required to be uploaded for this document type.
   * 0 indicates that the document type is optional.
   */
  requiredFiles: Scalars['Int']['output'];
};

export type AvailablePermission = {
  __typename: 'AvailablePermission';
  /** Available options for specifier of this permission. */
  availableOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** Available children for the permission. */
  children?: Maybe<Array<AvailablePermission>>;
  /** Name of the children of current permission */
  childrenName?: Maybe<Scalars['String']['output']>;
  /** Description of the permission */
  description: Scalars['String']['output'];
  /** The raw permission */
  permission: Scalars['String']['output'];
  /** When a permission supports specifier, this field indicates the name of the specifier. "Company Code", for example. */
  specifierTitle?: Maybe<Scalars['String']['output']>;
  /** Whether the permission supports `specifier` which basically defines the scope of the permission. */
  supportsSpecifier: Scalars['Boolean']['output'];
};

export enum AxTable {
  LegacySalesLine = 'LEGACY_SALES_LINE',
  LegacySalesTable = 'LEGACY_SALES_TABLE',
  SalesLine = 'SALES_LINE',
  SalesTable = 'SALES_TABLE',
  Unknown = 'UNKNOWN'
}

export type BackorderSalesLineCancellationUpdateNotification = {
  __typename: 'BackorderSalesLineCancellationUpdateNotification';
  by: UserBasicInfo;
  eventId: Scalars['String']['output'];
  orderId: Scalars['Int']['output'];
  orderItemId: Scalars['Int']['output'];
  productName: LocalizedField;
  remainingQuantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
  upstreamOrderNumber: Scalars['String']['output'];
};

export type BackorderSalesLineCancellationUpdateNotificationFilters = {
  orderItemIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Branch = {
  __typename: 'Branch';
  id: Scalars['ID']['output'];
  isMainStock: Scalars['Boolean']['output'];
  /**
   * Branch name in user's profile language
   * @deprecated use other fields instead
   */
  name: Scalars['String']['output'];
  nameDE: Scalars['String']['output'];
  nameEN: Scalars['String']['output'];
  nameFR: Scalars['String']['output'];
  nameIT: Scalars['String']['output'];
};

export type Brand = {
  __typename: 'Brand';
  customLogo?: Maybe<Scalars['String']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export type BrandResult = {
  __typename: 'BrandResult';
  items: Array<CatalogBrand>;
  total: Scalars['Int']['output'];
};

export type CapabilitiesResult = {
  __typename: 'CapabilitiesResult';
  /** Is current user able to create new users. */
  canCreateUsers: Scalars['Boolean']['output'];
  /** Is current user able to manage cart prices for the given customer. */
  canEditCartPriceForCustomer: Scalars['Boolean']['output'];
  /** Is current user able to manage preorder prices for the given customer. */
  canEditPreorderPriceForCustomer: Scalars['Boolean']['output'];
  /** Is the current user able to impersonate customers in the given store. */
  canImpersonateCustomers: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage the given cart
   *
   * # Arguments
   *
   * * `id`: ID of the cart check manage capability for
   */
  canManageCart: Scalars['Boolean']['output'];
  /** Is the current user able to manage catalog data. */
  canManageCatalog: Scalars['Boolean']['output'];
  /**
   * Is current user able to manage the given company.
   *
   * # Arguments
   *
   * * `company_code`: Company code to check manage capability for
   */
  canManageCompany: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage to given customer.
   *
   * # Arguments
   *
   * * `customer_number`: Customer Number of the target customer check manage capability for
   */
  canManageCustomer: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage dealers for the given store.
   *
   * # Arguments
   *
   * * `store_code`: Store code to check manage capability for
   */
  canManageDealers: Scalars['Boolean']['output'];
  /** Is the current user able to manage permissions for other users. */
  canManagePermissions: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage the given preorder season.
   *
   * # Arguments
   *
   * * `company_code`: ID of the preorder season to check manage capability for
   */
  canManagePreorderSeason: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage the requested store.
   *
   * # Arguments
   *
   * * `store_code`: Store code to check manage capability for
   */
  canManageStore: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage the entire system. This is the top-level capability
   * that grants every other permission/capability.
   */
  canManageSystem: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage the given support ticket.
   *
   * # Arguments
   *
   * * `id`: Ticket id to check manage capability for
   */
  canManageTicket: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage the given user.
   *
   * # Arguments
   *
   * * `id`: ID of the target user check manage capability for
   */
  canManageUser: Scalars['Boolean']['output'];
  canQueryCapabilities: Scalars['Boolean']['output'];
  /**
   * Is the current user able to represent the given customer. This allows the current user
   * to impersonate customer and perform actions on customer's behalf.
   */
  canRepresentCustomer: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manually trigger a task run. Optionally can be used to check
   * if the current user is able to re-run a task. Re-runs are less strictly controlled.
   */
  canRunTask: Scalars['Boolean']['output'];
  /** Is the current user able to perform support actions for customers within the given company. */
  canSupportCompanyCustomer: Scalars['Boolean']['output'];
  /** Is the current user able to view carts for at least 1 customer */
  canViewCarts: Scalars['Boolean']['output'];
  /**
   * Is the current user able to view (query) catalog data. Optionally can be used to check
   * ability to view a specific catalog entitiy.
   *
   * # Arguments
   *
   * * `resource`: Optional catalog resource identifier to check view access for. Not providing this
   * will return true if the user is able to access any 1 of the catalog resources.
   */
  canViewCatalog: Scalars['Boolean']['output'];
  /** Is current user able to view at least one of the companies. */
  canViewCompanies: Scalars['Boolean']['output'];
  /** Is the current user able to view dealers for at least 1 store. */
  canViewDealers: Scalars['Boolean']['output'];
  /** Is the current user able to view at preorder seasons for at least 1 of the companies. */
  canViewPreorderSeasons: Scalars['Boolean']['output'];
  /** Is the current user able to view preorders for at least 1 customer. */
  canViewPreorders: Scalars['Boolean']['output'];
  /** Is the current user able to view at least one of the stores. */
  canViewStores: Scalars['Boolean']['output'];
  /** Is the current user able to view support tickets for at least 1 of the companies. */
  canViewTickets: Scalars['Boolean']['output'];
  /** Is current user able to view at least one of the users. */
  canViewUsers: Scalars['Boolean']['output'];
  /** Query all companies that the current user is able to manage customers for. */
  customerManagedCompanyCodes: ManagedCompanyCodes;
  /** Is the current user part of the after-sale support team. */
  isAfterSalesUser: Scalars['Boolean']['output'];
  /**
   * Is the current user able to manage at least one company. This capability co-relates to
   * the role of "Company Manager"
   */
  isCompanyManager: Scalars['Boolean']['output'];
  /** Query all companies that the current user is able to manage. */
  managedCompanyCodes: ManagedCompanyCodes;
  /** Query all companies that the current user is able to manage support tickets for. */
  ticketManagedCompanyCodes: ManagedCompanyCodes;
};


export type CapabilitiesResultCanEditCartPriceForCustomerArgs = {
  customerNumber: Scalars['Int']['input'];
};


export type CapabilitiesResultCanEditPreorderPriceForCustomerArgs = {
  customerNumber: Scalars['Int']['input'];
};


export type CapabilitiesResultCanImpersonateCustomersArgs = {
  store: Scalars['String']['input'];
};


export type CapabilitiesResultCanManageCartArgs = {
  id: Scalars['Int']['input'];
};


export type CapabilitiesResultCanManageCompanyArgs = {
  companyCode: Scalars['String']['input'];
};


export type CapabilitiesResultCanManageCustomerArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
};


export type CapabilitiesResultCanManageDealersArgs = {
  storeCode: Scalars['String']['input'];
};


export type CapabilitiesResultCanManagePreorderSeasonArgs = {
  id: Scalars['Int']['input'];
};


export type CapabilitiesResultCanManageStoreArgs = {
  storeCode: Scalars['String']['input'];
};


export type CapabilitiesResultCanManageTicketArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type CapabilitiesResultCanManageUserArgs = {
  id: Scalars['Int']['input'];
};


export type CapabilitiesResultCanRepresentCustomerArgs = {
  customerNumber: Scalars['Int']['input'];
};


export type CapabilitiesResultCanRunTaskArgs = {
  isRerun?: InputMaybe<Scalars['Boolean']['input']>;
};


export type CapabilitiesResultCanSupportCompanyCustomerArgs = {
  companyCode: Scalars['String']['input'];
};


export type CapabilitiesResultCanViewCatalogArgs = {
  resource?: InputMaybe<Scalars['String']['input']>;
};

export type Cart = {
  __typename: 'Cart';
  /** Tells if current user is allowed to submit this cart. */
  canSubmit: Scalars['Boolean']['output'];
  /**
   * If true the cart is a default cart.
   *
   * Default carts cannot be deleted.
   *
   * Default carts do not have names.
   */
  default: Scalars['Boolean']['output'];
  /** Instructs the client if the cart is meant to be hidden from regular users and only visible for sales representatives. */
  hidden: Scalars['Boolean']['output'];
  /** Globally unique identifier of a cart */
  id: Scalars['Int']['output'];
  /** List of cart items */
  items: Array<Maybe<CartItem>>;
  /** Name of cart, will be null for default carts. */
  name?: Maybe<Scalars['String']['output']>;
  /** ID of the preorder season that the cart is attached to. */
  preorderSeasonId?: Maybe<Scalars['Int']['output']>;
  /** Store that the cart belongs to, useful for caching. */
  store: Scalars['String']['output'];
  /** Cart type, useful for caching. */
  type: CartType;
};

export type CartFields = {
  __typename: 'CartFields';
  /** Order Comment - customer input text, max 255 characters */
  comment?: Maybe<Scalars['String']['output']>;
  /** Default delivery date for cart YYYY-MM-DD */
  defaultDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Custom delivery address */
  deliveryAddress?: Maybe<DeliveryAddress>;
  /** Customer Delivery Address ID -- cartGetDeliveryAddress's query */
  deliveryAddressId?: Maybe<Scalars['ID']['output']>;
  /** Delivery dates for sales lines YYYY-MM-DD */
  deliveryDates: Array<PreorderDeliveryDate>;
  /** Cart id. */
  id?: Maybe<Scalars['Int']['output']>;
  /** Internal note (Preorder only) */
  internalNote?: Maybe<Scalars['String']['output']>;
  /** Payment due date (ISO Date) (Can be enabled/disabled via store feature) */
  paymentDueDate?: Maybe<Scalars['String']['output']>;
  /** Payment term id */
  paymentTermId?: Maybe<Scalars['String']['output']>;
  /** Order Reference - customer input text, max 25 characters */
  reference?: Maybe<Scalars['String']['output']>;
  /** Shipping method ID -- cartGetDefaultShipping query */
  shippingMethod?: Maybe<Scalars['ID']['output']>;
  /** Branch Location ID -- me.branches query */
  stockLocation?: Maybe<Scalars['String']['output']>;
};

export type CartFieldsInput = {
  /** Order Comment - customer input text, max 255 characters */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Default preorder delivery date YYYY-MM-DD (Preorder only) */
  defaultDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Custom delivery address */
  deliveryAddress?: InputMaybe<DeliveryAddressInput>;
  /** Customer Delivery Address ID -- cartGetDeliveryAddress's query */
  deliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
  /** Preorder delivery dates for brands YYYY-MM-DD (Preorder only) */
  deliveryDates?: InputMaybe<Array<InputMaybe<PreorderDeliveryDateInput>>>;
  /** Internal note */
  internalNote?: InputMaybe<Scalars['String']['input']>;
  /** Payment due date (ISO Date) */
  paymentDueDate?: InputMaybe<Scalars['String']['input']>;
  /** Payment term id note */
  paymentTermId?: InputMaybe<Scalars['String']['input']>;
  /** Order Reference - customer input text, max 25 characters */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Shipping method ID -- cartGetDefaultShipping query */
  shippingMethod?: InputMaybe<Scalars['ID']['input']>;
  /** Branch Location ID -- me.branches query */
  stockLocation?: InputMaybe<Scalars['String']['input']>;
};

export type CartItem = {
  __typename: 'CartItem';
  comment?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
};

export type CartItemInput = {
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type CartPriceTotals = {
  __typename: 'CartPriceTotals';
  /** Price Currency */
  currency: Scalars['String']['output'];
  /** Percentage of the user's dealer discount */
  dealerDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Final price for the user */
  finalPrice?: Maybe<Scalars['Float']['output']>;
  /** General dealer price */
  generalDealerPrice?: Maybe<Scalars['Float']['output']>;
  /** Recommended retail / consumer price */
  retailPrice: Scalars['Float']['output'];
  /** If the item is on sale, the final sales percentage */
  salesDiscountPercentage?: Maybe<Scalars['Float']['output']>;
};

export type CartResult = {
  __typename: 'CartResult';
  items: Array<CustomerCart>;
  total: Scalars['Int']['output'];
};

export enum CartType {
  CustomerPreorder = 'customerPreorder',
  Regular = 'regular',
  User = 'user'
}

export type CatalogAttributeListItem = {
  __typename: 'CatalogAttributeListItem';
  localizedValue?: Maybe<LocalizedField>;
  localizedValues?: Maybe<LocalizedListField>;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type CatalogBrand = {
  __typename: 'CatalogBrand';
  created: Scalars['DateTime']['output'];
  customLogo?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['DateTime']['output']>;
  excludeAsKeyDealers: Scalars['JSON']['output'];
  id: Scalars['Int']['output'];
  includeAsKeyDealers: Scalars['JSON']['output'];
  logo: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  minimumKeyDealerTurnovers: Scalars['JSON']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  stores: Array<Scalars['Int']['output']>;
  updated: Scalars['DateTime']['output'];
  upstreamId: Scalars['Int']['output'];
};


export type CatalogBrandCustomLogoArgs = {
  store: Scalars['String']['input'];
};

export type CatalogBrandModelSort = {
  direction: SortDirection;
  field: CatalogBrandSortField;
};

export enum CatalogBrandSortField {
  Created = 'created',
  Id = 'id',
  Name = 'name',
  Updated = 'updated'
}

export enum CatalogEnvironment {
  Dev = 'dev',
  Preview = 'preview',
  Prod = 'prod',
  Qa = 'qa'
}

export type CatalogSnapshot = {
  __typename: 'CatalogSnapshot';
  id: Scalars['String']['output'];
  lastModified: Scalars['DateTime']['output'];
  size: Scalars['Int']['output'];
};

export type CatalogSnapshotResult = {
  __typename: 'CatalogSnapshotResult';
  items: Array<CatalogSnapshot>;
  total: Scalars['Int']['output'];
};

export type CategoriesResultSet = {
  __typename: 'CategoriesResultSet';
  items: Array<Category>;
};

export type Category = {
  __typename: 'Category';
  /** Filters defined for this category */
  filters: Array<Maybe<CategoryFilter>>;
  /** Tells if motorcycle filter is valid for the category */
  hasMotorcycleFilter: Scalars['Boolean']['output'];
  /** Tells if category is hidden during navigation */
  hiddenInTree: Scalars['Boolean']['output'];
  /** Category ID */
  id: Scalars['ID']['output'];
  /** Cache key */
  key: Scalars['String']['output'];
  /** Category name in selected language */
  name: Scalars['String']['output'];
  /** ID of the parent category */
  parentId?: Maybe<Scalars['String']['output']>;
  /** List of ancestors from the root category all the way to inclusive this one */
  path: Array<Category>;
};

export type CategoryFilter = {
  __typename: 'CategoryFilter';
  /** Technical field name that case be used to filter. */
  field: Scalars['String']['output'];
  /** Unique identifier of the filter */
  id: Scalars['ID']['output'];
  /** Localized name of the filter */
  name: Scalars['String']['output'];
  /** Possible values labels for this attribute if applicable */
  valueLabels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Possible values for this attribute */
  values: Array<Maybe<Scalars['String']['output']>>;
};

export type CategorySummary = {
  __typename: 'CategorySummary';
  id: Scalars['Int']['output'];
  name: LocalizedField;
  parentId?: Maybe<Scalars['Int']['output']>;
  path: Array<CategorySummary>;
};

export type ChangeEmailAddressOutcome = {
  __typename: 'ChangeEmailAddressOutcome';
  success: Scalars['Boolean']['output'];
  usernameChanged: Scalars['Boolean']['output'];
};

export type CheckoutFields = {
  __typename: 'CheckoutFields';
  applicableDeliveryDates?: Maybe<Array<ApplicableDeliveryDateRange>>;
  branches?: Maybe<Array<Branch>>;
  checkoutPriceSummary?: Maybe<CheckoutPriceSummary>;
  /** @deprecated Use checkoutPriceSummary.deliveryCharge instead */
  deliveryCharge?: Maybe<DeliveryCharge>;
  errorCodes: Array<Scalars['String']['output']>;
  isDropShipmentEnabled?: Maybe<Scalars['Boolean']['output']>;
  requiresPrepay?: Maybe<Scalars['Boolean']['output']>;
  shippingMethods: Array<ShippingMethod>;
};

export type CheckoutOptions = {
  /** Order Comment - customer input text, max 255 characters */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** Custom delivery address */
  deliveryAddress?: InputMaybe<DeliveryAddressInput>;
  /** Customer Delivery Address ID -- cartGetDeliveryAddress's query */
  deliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
  /** Order Reference - customer input text, max 25 characters */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Shipping method ID -- cartGetDefaultShipping query */
  shippingMethod?: InputMaybe<Scalars['ID']['input']>;
  /** Branch Location ID -- me.branches query */
  stockLocation: Scalars['String']['input'];
};

export type CheckoutPriceSummary = {
  __typename: 'CheckoutPriceSummary';
  /** Delivery charge for this order */
  deliveryCharge?: Maybe<DeliveryCharge>;
  /** Line items that make up the final price */
  lineItems: Array<OrderLineItem>;
  /** Discount breakdown */
  purchaseBreakdown: PurchaseBreakdown;
  /** Rounded total price */
  roundedTotalPrice: Scalars['String']['output'];
  smallOrderSurcharge?: Maybe<SmallOrderSurcharge>;
  /** Sum of all net additional charges, VAT is not included. */
  totalNetAdditionalCharges: Scalars['String']['output'];
  /** Total price that includes all additional charges, shipping charge and VATs */
  totalPrice: Scalars['String']['output'];
  /** Rounded difference on total */
  totalPriceRounding: Scalars['String']['output'];
  /** VAT for the totalPrice */
  totalVat?: Maybe<Scalars['String']['output']>;
};

export type Company = {
  __typename: 'Company';
  branches: Array<CompanyBranch>;
  code: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  details?: Maybe<CompanyDetails>;
  finalPriceForCartBrandTotals: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  manualPreorderReservation: Scalars['Boolean']['output'];
  manualPrepayCustomerOrderReservation: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  semiFinalPriceForCartBrandTotals: Scalars['Boolean']['output'];
  smallOrderSurchargeThreshold?: Maybe<Scalars['Decimal']['output']>;
  sourceName: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type CompanyBranch = {
  __typename: 'CompanyBranch';
  id: Scalars['String']['output'];
  isMainStock?: Maybe<Scalars['Boolean']['output']>;
  nameDE: Scalars['String']['output'];
  nameEN: Scalars['String']['output'];
  nameFR: Scalars['String']['output'];
  nameIT: Scalars['String']['output'];
};

export type CompanyBranchInput = {
  id: Scalars['String']['input'];
  isMainStock?: InputMaybe<Scalars['Boolean']['input']>;
  nameDE: Scalars['String']['input'];
  nameEN: Scalars['String']['input'];
  nameFR: Scalars['String']['input'];
  nameIT: Scalars['String']['input'];
};

export type CompanyDetails = {
  __typename: 'CompanyDetails';
  address1: Scalars['String']['output'];
  address2: Scalars['String']['output'];
  address3: Scalars['String']['output'];
  copyright: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type CompanyDetailsInput = {
  address1: Scalars['String']['input'];
  address2: Scalars['String']['input'];
  address3: Scalars['String']['input'];
  copyright: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type CompanyInfo = {
  __typename: 'CompanyInfo';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  copyright?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type CompanyListResult = {
  __typename: 'CompanyListResult';
  /** List of product items in the requested page. */
  items: Array<Company>;
  /** Total number of product items in the DB that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type CompanyShippingMethod = {
  __typename: 'CompanyShippingMethod';
  companyId: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  dropshipment: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  nameDe: Scalars['String']['output'];
  nameEn: Scalars['String']['output'];
  nameFr: Scalars['String']['output'];
  nameIt: Scalars['String']['output'];
  shipmentCode: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type CompanyShippingMethodModelSort = {
  direction: SortDirection;
  field: CompanyShippingMethodSortField;
};

export enum CompanyShippingMethodSortField {
  CompanyId = 'companyId',
  Created = 'created',
  Dropshipment = 'dropshipment',
  Id = 'id',
  NameDe = 'nameDe',
  NameEn = 'nameEn',
  NameFr = 'nameFr',
  NameIt = 'nameIt',
  ShipmentCode = 'shipmentCode',
  Updated = 'updated'
}

export type ConsoleDataQualityIssue = {
  __typename: 'ConsoleDataQualityIssue';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type ConsoleDataQualityReport = {
  __typename: 'ConsoleDataQualityReport';
  categoriesWithProductParents: Array<DataQualityNodeEntry>;
  issues: Array<ConsoleDataQualityIssue>;
  missingProductPrices: Array<ConsoleProductWithIssue>;
  mixedSiblingNodes: Array<DataQualityNodeEntry>;
  productsWithMismatchingStoreAndVisibility: Array<ProductWithStoresMismatch>;
  variantProductsWithoutChildren: Array<DataQualityNodeEntry>;
  zeroProductPrices: Array<ConsoleZeroPriceIssue>;
};

export type ConsoleProductWithIssue = {
  __typename: 'ConsoleProductWithIssue';
  id: Scalars['String']['output'];
  nameDe: Scalars['String']['output'];
  sku: Scalars['String']['output'];
  stores: Array<Scalars['String']['output']>;
};

export type ConsoleSearchResult = {
  __typename: 'ConsoleSearchResult';
  brands: Array<SearchBrandResult>;
  categories: Array<SearchCategoryResult>;
  customers: Array<SearchCustomerResult>;
  nodes: Array<SearchNodeResult>;
  orders: Array<SearchOrderResult>;
  preorders: Array<SearchPreorderResult>;
  products: Array<SearchProductResult>;
  users: Array<SearchUserResult>;
};

export type ConsoleZeroPriceIssue = {
  __typename: 'ConsoleZeroPriceIssue';
  companyCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
};

export type CrawlData = {
  __typename: 'CrawlData';
  /** Created timestamp */
  created?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** Updated timestamp */
  updated?: Maybe<Scalars['String']['output']>;
};

export type CreateSupportTicketResult = {
  __typename: 'CreateSupportTicketResult';
  id: Scalars['Int']['output'];
};

export type CreateTestUserResult = {
  __typename: 'CreateTestUserResult';
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type CreateTestUserResultNew = {
  __typename: 'CreateTestUserResultNew';
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export enum Currency {
  Chf = 'CHF',
  Eur = 'EUR'
}

export type CurrentUser = {
  __typename: 'CurrentUser';
  /** @deprecated Use capability queries instead */
  canImpersonate: Scalars['Boolean']['output'];
  /** @deprecated Use currentCustomer Query instead */
  customer?: Maybe<CustomerInformation>;
  customerNumber?: Maybe<Scalars['Int']['output']>;
  dealerPricesEnabled: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  hasPermissions: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  impersonating: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  orderNotificationEmails: Array<Scalars['String']['output']>;
  orderNotificationEnabled: Scalars['Boolean']['output'];
  stores: Array<CurrentUserStore>;
  username: Scalars['String']['output'];
};


export type CurrentUserHasPermissionsArgs = {
  permission: HasPermissionInput;
};

export type CurrentUserStore = {
  __typename: 'CurrentUserStore';
  code: Scalars['String']['output'];
  /** @deprecated Use `CurrentUserStore.code` instead */
  id: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  languages: Array<StoreLanguage>;
  url: Scalars['String']['output'];
};

export type CustomBrandDivision = {
  __typename: 'CustomBrandDivision';
  brands: Array<Scalars['String']['output']>;
  description: LocalizedField;
  id: Scalars['String']['output'];
  name: LocalizedField;
};

export type CustomBrandDivisionInput = {
  brands: Array<Scalars['String']['input']>;
  description: LocalizedFieldInput;
  id: Scalars['String']['input'];
  name: LocalizedFieldInput;
};

export type CustomBrandDivisions = {
  __typename: 'CustomBrandDivisions';
  divisions: Array<CustomBrandDivision>;
  enabled: Scalars['Boolean']['output'];
};

export type CustomBrandDivisionsInput = {
  divisions: Array<CustomBrandDivisionInput>;
  enabled: Scalars['Boolean']['input'];
};

export type CustomBrandLogo = {
  __typename: 'CustomBrandLogo';
  brand: Scalars['String']['output'];
  logoUrl: Scalars['String']['output'];
};

export type CustomBrandLogoInput = {
  brand: Scalars['String']['input'];
  logoUrl: Scalars['String']['input'];
};

export type CustomBrandLogos = {
  __typename: 'CustomBrandLogos';
  brands: Array<CustomBrandLogo>;
  enabled: Scalars['Boolean']['output'];
};

export type CustomBrandLogosInput = {
  brands: Array<CustomBrandLogoInput>;
  enabled: Scalars['Boolean']['input'];
};

export type CustomCatalog = {
  __typename: 'CustomCatalog';
  availableCatalogs: Array<AvailableCatalog>;
  enabled: Scalars['Boolean']['output'];
};

export enum CustomCatalogFormat {
  /** Additionally supports accessoryPages field in catalog params. */
  Pdf = 'pdf',
  Xls = 'xls'
}

export type CustomCatalogInput = {
  availableCatalogs: Array<AvailableCatalogInput>;
  enabled: Scalars['Boolean']['input'];
};

export enum CustomCatalogLanguage {
  French = 'french',
  German = 'german'
}

export enum CustomCatalogSpecialPriceCalculationBase {
  /**
   * Calculate using retail price as the base and subtracting certain amount.
   *
   * Amount to be subtracted can be specified using specialPriceCalculationType and specialPriceCalculationValue.
   */
  Uvp = 'UVP',
  /**
   * Calculate using final price as the base and adding certain amount.
   *
   * Amount to be added can be specified using specialPriceCalculationType and specialPriceCalculationValue.
   */
  MyHek = 'myHEK'
}

export enum CustomCatalogSpecialPriceCalculationType {
  /** Use price amount to be subtracted or added to calculation base specified in specialPriceCalculationBase. */
  Amount = 'amount',
  /** Keep value as is without modifying. This is only applicable when specialPriceCalculationBase is UVP */
  NoChange = 'noChange',
  /** Use percentage amount to be subtracted or added to calculation base specified in specialPriceCalculationBase. */
  Percentage = 'percentage'
}

export type CustomDiscount = {
  __typename: 'CustomDiscount';
  discount?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  productGroupId?: Maybe<Scalars['String']['output']>;
  rebateCode?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  variantGroupId?: Maybe<Scalars['String']['output']>;
};

export type CustomDiscountInput = {
  discount?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  productGroupId?: InputMaybe<Scalars['String']['input']>;
  rebateCode?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  variantGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type CustomFalseDataReportNotificationRecipient = {
  __typename: 'CustomFalseDataReportNotificationRecipient';
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
};

export type CustomFalseDataReportNotificationRecipientInput = {
  email: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type Customer = {
  __typename: 'Customer';
  addresses: Array<CustomerAddress>;
  /** Branches that this customer can order from */
  branches: Array<Branch>;
  currency: Scalars['String']['output'];
  defaultPaymentTermId?: Maybe<Scalars['String']['output']>;
  defaultShippingMethodId?: Maybe<Scalars['String']['output']>;
  /** Customer number */
  id: Scalars['Int']['output'];
  /** Last time the order history was synchronized. */
  lastOrderHistorySyncTime: Scalars['String']['output'];
  /** Last used address id for this customer. Only use this field when required. */
  lastUsedAddressId?: Maybe<Scalars['String']['output']>;
  /** Last used preorder address id for this customer. Only use this field when required. */
  lastUsedPreorderAddressId?: Maybe<Scalars['String']['output']>;
  /** Locale code */
  locale: Scalars['String']['output'];
  /** Preferred branch for customer */
  locationCode?: Maybe<Scalars['String']['output']>;
  /** Customer name */
  name?: Maybe<Scalars['String']['output']>;
  /** Tells if the customer is in the middle of an order history synchronization. */
  orderHistorySyncInProgress: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  requiresPrepay: Scalars['Boolean']['output'];
  /** Customer's website */
  url?: Maybe<Scalars['String']['output']>;
  /** Tells if customer can access the Yamaha portal */
  yamahaPortalEnabled: Scalars['Boolean']['output'];
};

export type CustomerAddress = {
  __typename: 'CustomerAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isBillingAddress: Scalars['Boolean']['output'];
  isBusinessAddress: Scalars['Boolean']['output'];
  isDefaultBillingAddress: Scalars['Boolean']['output'];
  isDefaultBusinessAddress: Scalars['Boolean']['output'];
  isDefaultShippingAddress: Scalars['Boolean']['output'];
  isShippingAddress: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type CustomerAddressItem = {
  __typename: 'CustomerAddressItem';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isBillingAddress: Scalars['Boolean']['output'];
  isBusinessAddress: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  isDefaultBillingAddress: Scalars['Boolean']['output'];
  isDefaultBusinessAddress: Scalars['Boolean']['output'];
  isDefaultShippingAddress: Scalars['Boolean']['output'];
  isShippingAddress: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
  shippingMethods: Array<AddressShippingMethod>;
};

export type CustomerCart = {
  __typename: 'CustomerCart';
  active: Scalars['Boolean']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  customerNumber: Scalars['Int']['output'];
  default: Scalars['Boolean']['output'];
  defaultDeliveryDate?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<CustomerCartDeliveryAddress>;
  deliveryAddressId?: Maybe<Scalars['String']['output']>;
  deliveryDates: Array<CustomerCartDeliveryDate>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  internalNote?: Maybe<Scalars['String']['output']>;
  items: Array<CustomerCartItem>;
  name?: Maybe<Scalars['String']['output']>;
  paymentDueDate?: Maybe<Scalars['String']['output']>;
  paymentTermId?: Maybe<Scalars['String']['output']>;
  preorderSeasonId?: Maybe<Scalars['Int']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  shippingMethod?: Maybe<Scalars['String']['output']>;
  stockLocation?: Maybe<Scalars['String']['output']>;
  store: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  userId?: Maybe<Scalars['Int']['output']>;
};

export type CustomerCartDeliveryAddress = {
  __typename: 'CustomerCartDeliveryAddress';
  address: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type CustomerCartDeliveryDate = {
  __typename: 'CustomerCartDeliveryDate';
  brandName?: Maybe<Scalars['String']['output']>;
  deliveryDate: Scalars['String']['output'];
  productGroupId?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  sortIndex?: Maybe<Scalars['Int']['output']>;
  variantGroupId?: Maybe<Scalars['String']['output']>;
};

export type CustomerCartItem = {
  __typename: 'CustomerCartItem';
  comment?: Maybe<Scalars['String']['output']>;
  price: Scalars['Float']['output'];
  product: CustomerCartItemProduct;
  quantity: Scalars['Int']['output'];
};

export type CustomerCartItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
  product: CustomerCartItemProductInput;
  quantity: Scalars['Int']['input'];
};

export type CustomerCartItemProduct = {
  __typename: 'CustomerCartItemProduct';
  sku: Scalars['String']['output'];
};

export type CustomerCartItemProductInput = {
  sku: Scalars['String']['input'];
};

export type CustomerCartModelSort = {
  direction: SortDirection;
  field: CustomerCartSortField;
};

export type CustomerCartPricing = {
  __typename: 'CustomerCartPricing';
  cartId: Scalars['Int']['output'];
  discounts: Array<CustomDiscount>;
};

export enum CustomerCartSortField {
  Active = 'active',
  Created = 'created',
  CustomerNumber = 'customerNumber',
  Id = 'id',
  PreorderSeasonId = 'preorderSeasonId',
  Store = 'store',
  Type = 'type',
  Updated = 'updated',
  UserId = 'userId'
}

export type CustomerDiscountRow = {
  __typename: 'CustomerDiscountRow';
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  percentage: Scalars['Float']['output'];
  startDate?: Maybe<Scalars['String']['output']>;
};

export type CustomerEntity = {
  __typename: 'CustomerEntity';
  addresses?: Maybe<Array<CustomerAddressItem>>;
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  customerInvoiceNumber?: Maybe<Scalars['String']['output']>;
  defaultPaymentTermId?: Maybe<Scalars['String']['output']>;
  defaultShippingMethodId?: Maybe<Scalars['String']['output']>;
  dropshipmentAllowed: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  keyDealerForBrands: Array<Scalars['Int']['output']>;
  keyDealerState: Scalars['JSON']['output'];
  keyDealers?: Maybe<Array<Scalars['String']['output']>>;
  lastAddressSyncTime: Scalars['DateTime']['output'];
  lastOrderHistorySyncTime: Scalars['DateTime']['output'];
  latitude?: Maybe<Scalars['Decimal']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  locationCode?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Decimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameSearch?: Maybe<Scalars['String']['output']>;
  netAmounts: Scalars['JSON']['output'];
  orderHistorySyncInProgress: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  priceCacheKey?: Maybe<Scalars['String']['output']>;
  priceGroupId?: Maybe<Scalars['String']['output']>;
  rebates: Array<CustomerPricingRebate>;
  representedBrands?: Maybe<Array<Scalars['String']['output']>>;
  requiresOnlinePayment: Scalars['Boolean']['output'];
  requiresPrepay: Scalars['Boolean']['output'];
  responsibleEmployeeIds: Array<Scalars['String']['output']>;
  segment?: Maybe<Scalars['String']['output']>;
  showInDealerSearch: Scalars['Boolean']['output'];
  source: Scalars['String']['output'];
  specialPrices: Array<CustomerPricingSpecialPrice>;
  taxGroupId?: Maybe<Scalars['String']['output']>;
  testCustomer: Scalars['Boolean']['output'];
  updated: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  yamahaPortalEnabled: Scalars['Boolean']['output'];
};

export type CustomerEntityModelSort = {
  direction: SortDirection;
  field: CustomerEntitySortField;
};

export enum CustomerEntitySortField {
  CompanyCode = 'companyCode',
  Created = 'created',
  DefaultPaymentTermId = 'defaultPaymentTermId',
  Email = 'email',
  Id = 'id',
  LocationCode = 'locationCode',
  Name = 'name',
  PriceGroupId = 'priceGroupId',
  Segment = 'segment',
  Source = 'source',
  TaxGroupId = 'taxGroupId',
  Updated = 'updated'
}

export type CustomerInfo = {
  __typename: 'CustomerInfo';
  address?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  customerNumber: Scalars['Int']['output'];
  faxNumber?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  postcode?: Maybe<Scalars['String']['output']>;
};

export type CustomerInformation = {
  __typename: 'CustomerInformation';
  addresses?: Maybe<Array<CustomerAddressItem>>;
  branches: Array<StockBranch>;
  companyCode: Scalars['String']['output'];
  currency?: Maybe<Scalars['String']['output']>;
  defaultPaymentTermId?: Maybe<Scalars['String']['output']>;
  defaultShippingMethodId?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastOrderHistorySyncTime: Scalars['DateTime']['output'];
  lastUsedAddressId?: Maybe<Scalars['String']['output']>;
  lastUsedPreorderAddressId?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  locationCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderHistorySyncInProgress: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  requiresPrepay: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
  yamahaPortalEnabled: Scalars['Boolean']['output'];
};

export type CustomerOnlineDealer = {
  __typename: 'CustomerOnlineDealer';
  brandUrls: Scalars['JSON']['output'];
  brands: Array<Scalars['String']['output']>;
  companyId: Scalars['Int']['output'];
  created: Scalars['DateTime']['output'];
  customerNumber: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
};

export type CustomerOnlineDealerModelSort = {
  direction: SortDirection;
  field: CustomerOnlineDealerSortField;
};

export enum CustomerOnlineDealerSortField {
  Created = 'created',
  CustomerNumber = 'customerNumber',
  Id = 'id',
  Updated = 'updated'
}

export type CustomerOrder = {
  __typename: 'CustomerOrder';
  branch?: Maybe<Scalars['JSON']['output']>;
  cartId?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  creditNoteId?: Maybe<Scalars['String']['output']>;
  customerNumber: Scalars['String']['output'];
  defaultDeliveryDate?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<CustomerOrderDeliveryAddress>;
  deliveryDates: Array<CustomerCartDeliveryDate>;
  deliveryNoteId?: Maybe<Scalars['String']['output']>;
  errorCode?: Maybe<Scalars['String']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  isDropshipment: Scalars['Boolean']['output'];
  items: Array<CustomerOrderItem>;
  language?: Maybe<Scalars['String']['output']>;
  orderReference: Scalars['String']['output'];
  orderType: OrderOrdertypeEnum;
  origin: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  /**
   * Relevant task run for this order.
   *
   * This is useful for re-submitting orders or clearing out orders that should not be re-submitted.
   *
   * This will be null, if the order has a "terminal" state like "confirmed", "delivered", "invoiced"
   * or "canceled", because in this case the task run is just not relevant.
   */
  relevantTaskRun?: Maybe<TaskRun>;
  salesRepresentativeId?: Maybe<Scalars['Int']['output']>;
  shippingMethod: Scalars['String']['output'];
  shippingMethodDetails?: Maybe<Scalars['JSON']['output']>;
  status: Scalars['String']['output'];
  stockLocation: Scalars['String']['output'];
  store: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamOrderId: Scalars['String']['output'];
  upstreamOrderNumber: Scalars['String']['output'];
  upstreamOrigin: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CustomerOrderDeliveryAddress = {
  __typename: 'CustomerOrderDeliveryAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type CustomerOrderItem = {
  __typename: 'CustomerOrderItem';
  backorderInfoDe?: Maybe<Scalars['String']['output']>;
  backorderInfoEn?: Maybe<Scalars['String']['output']>;
  backorderInfoFr?: Maybe<Scalars['String']['output']>;
  backorderInfoIt?: Maybe<Scalars['String']['output']>;
  brand: Scalars['String']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  /** This is the actual "quantity", the confirmation doesn't mean anything. */
  confirmedQuantity: Scalars['Decimal']['output'];
  created: Scalars['DateTime']['output'];
  creditNoteId?: Maybe<Scalars['String']['output']>;
  creditNoteRecId?: Maybe<Scalars['Int']['output']>;
  decreaseIssues: Array<SalesLineBackorderDecreaseIssues>;
  deliveredQuantity: Scalars['Decimal']['output'];
  deliveryNoteId?: Maybe<Scalars['String']['output']>;
  deliveryNoteRecId?: Maybe<Scalars['Int']['output']>;
  deliveryOnRequest: Scalars['Boolean']['output'];
  groupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  invoiceRecId?: Maybe<Scalars['Int']['output']>;
  orderId: Scalars['Int']['output'];
  orderType: OrderItemOrdertypeEnum;
  parentId?: Maybe<Scalars['Int']['output']>;
  pendingQuantity: Scalars['Decimal']['output'];
  positionId: Scalars['Int']['output'];
  price: Scalars['Decimal']['output'];
  prices?: Maybe<CustomerPrice>;
  /** This field is completely useless, it should be disregarded in every calculation. */
  quantity: Scalars['Decimal']['output'];
  requestedShippingDate?: Maybe<Scalars['NaiveDate']['output']>;
  searchCacheDe?: Maybe<Scalars['String']['output']>;
  searchCacheEn?: Maybe<Scalars['String']['output']>;
  searchCacheFr?: Maybe<Scalars['String']['output']>;
  searchCacheIt?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  stale: Scalars['Boolean']['output'];
  stockAvailableDelay: Scalars['Int']['output'];
  stockAvailableLater: Scalars['Int']['output'];
  stockAvailableNow: Scalars['Int']['output'];
  stockAvailableSoon: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamRecId?: Maybe<Scalars['String']['output']>;
};

export type CustomerOrderModelSort = {
  direction: SortDirection;
  field: CustomerOrderSortField;
};

export enum CustomerOrderSortField {
  Created = 'created',
  CustomerNumber = 'customerNumber',
  Id = 'id',
  Origin = 'origin',
  Status = 'status',
  StockLocation = 'stockLocation',
  Store = 'store',
  Updated = 'updated',
  UpstreamOrderId = 'upstreamOrderId',
  UpstreamOrderNumber = 'upstreamOrderNumber',
  UpstreamOrigin = 'upstreamOrigin',
  UserId = 'userId'
}

export type CustomerPreorder = {
  __typename: 'CustomerPreorder';
  cartId?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  customerNumber: Scalars['String']['output'];
  defaultDeliveryDate: Scalars['String']['output'];
  deliveryAddress?: Maybe<CustomerPreorderDeliveryAddress>;
  deliveryDates: Array<CustomerCartDeliveryDate>;
  discountLevel: Scalars['String']['output'];
  generalDealerPriceTotal: Scalars['Decimal']['output'];
  id: Scalars['Int']['output'];
  internalNote?: Maybe<Scalars['String']['output']>;
  itemDeliveryDates: Scalars['JSONObject']['output'];
  items: Array<CustomerPreorderItem>;
  language: Scalars['String']['output'];
  paymentTermId?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  /**
   * Relevant task run for this order.
   *
   * This is useful for re-submitting orders or clearing out orders that should not be re-submitted.
   *
   * This will be null, if the order has a "terminal" state like "confirmed", "delivered", "invoiced"
   * or "canceled", because in this case the task run is just not relevant.
   */
  relevantTaskRun?: Maybe<TaskRun>;
  salesRepresentativeId?: Maybe<Scalars['Int']['output']>;
  seasonId: Scalars['Int']['output'];
  shippingMethodId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  stockLocation?: Maybe<Scalars['String']['output']>;
  store: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamOrderNumber: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type CustomerPreorderDeliveryAddress = {
  __typename: 'CustomerPreorderDeliveryAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type CustomerPreorderItem = {
  __typename: 'CustomerPreorderItem';
  brandName: Scalars['String']['output'];
  prices: CustomerPrice;
  product?: Maybe<PreorderItemProduct>;
  quantity: Scalars['Int']['output'];
  rebateCode?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
};

export type CustomerPreorderModelSort = {
  direction: SortDirection;
  field: CustomerPreorderSortField;
};

export type CustomerPreorderPricing = {
  __typename: 'CustomerPreorderPricing';
  /** Discount tier override */
  discountTierOverride?: Maybe<Scalars['String']['output']>;
  /** Additional discounts */
  preorderDiscounts: Array<CustomDiscount>;
  /** Rebate override */
  rebates: Array<CustomerRebate>;
  /** Special price override */
  specialPrices: Array<CustomerSpecialPrice>;
};

export type CustomerPreorderPricingValuesInput = {
  /** Discount tier override */
  discountTierOverride?: InputMaybe<Scalars['String']['input']>;
  /** Additional discounts */
  preorderDiscounts?: InputMaybe<Array<CustomDiscountInput>>;
  /** Rebate override */
  rebates?: InputMaybe<Array<CustomerRebateInput>>;
  /** Special price override */
  specialPrices?: InputMaybe<Array<CustomerSpecialPriceInput>>;
};

export enum CustomerPreorderSortField {
  Created = 'created',
  CustomerNumber = 'customerNumber',
  GeneralDealerPriceTotal = 'generalDealerPriceTotal',
  Id = 'id',
  SeasonId = 'seasonId',
  Status = 'status',
  Updated = 'updated',
  UpstreamOrderNumber = 'upstreamOrderNumber'
}

export type CustomerPreorderSummary = {
  __typename: 'CustomerPreorderSummary';
  discountTier?: Maybe<Scalars['String']['output']>;
  isDiscountTierOverridden: Scalars['Boolean']['output'];
  totalValue: Scalars['Float']['output'];
};

export type CustomerPrice = {
  __typename: 'CustomerPrice';
  additionalDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  appliedDiscountRule?: Maybe<AppliedDiscountRule>;
  cartType?: Maybe<CartType>;
  currency: Currency;
  dealerDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  finalPrice: Scalars['Float']['output'];
  generalDealerPrice: Scalars['Float']['output'];
  isCustomPrice?: Maybe<Scalars['Boolean']['output']>;
  netRetailPrice?: Maybe<Scalars['Float']['output']>;
  retailPrice: Scalars['Float']['output'];
  semiFinalPrice?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Scalars['String']['output']>;
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export type CustomerPriceListItem = {
  __typename: 'CustomerPriceListItem';
  barcode?: Maybe<Scalars['String']['output']>;
  finalPrice: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rebateCode: Scalars['String']['output'];
  rrpIncl: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
};

export type CustomerPriceSummary = {
  __typename: 'CustomerPriceSummary';
  /** Key that can be used for caching */
  cacheKey: Scalars['String']['output'];
  /** Price Currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Percentage of the user's dealer discount */
  dealerDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Privileged field, explains the price calculation */
  explanation?: Maybe<PriceCalculationExplanation>;
  /** Final price for the user */
  finalPrice?: Maybe<Scalars['Float']['output']>;
  /** General dealer price */
  generalDealerPrice?: Maybe<Scalars['Float']['output']>;
  /** Recommended retail / consumer price */
  retailPrice: Scalars['Float']['output'];
  /** If the item is on sale, the final sales percentage */
  salesDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Store which the product with teh price belongs to */
  store: Scalars['ID']['output'];
};

export type CustomerPricingRebate = {
  __typename: 'CustomerPricingRebate';
  code: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  description: Scalars['String']['output'];
  endDate?: Maybe<Scalars['NaiveDate']['output']>;
  price: Scalars['Float']['output'];
  startDate?: Maybe<Scalars['NaiveDate']['output']>;
};

export type CustomerPricingRebateInput = {
  code: Scalars['String']['input'];
  companyCode: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  price: Scalars['Float']['input'];
  startDate?: InputMaybe<Scalars['NaiveDate']['input']>;
};

export type CustomerPricingSpecialPrice = {
  __typename: 'CustomerPricingSpecialPrice';
  companyCode: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  rebate: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
};

export type CustomerPricingSpecialPriceInput = {
  companyCode: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  rebate: Scalars['Float']['input'];
  sku: Scalars['String']['input'];
};

export type CustomerProductOrderRestriction = {
  __typename: 'CustomerProductOrderRestriction';
  brands: Array<Scalars['String']['output']>;
  companyId: Scalars['Int']['output'];
  conditions?: Maybe<Scalars['ProductOrderRestrictionConditions']['output']>;
  created: Scalars['DateTime']['output'];
  customerSegments: Array<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  messageDe: Scalars['String']['output'];
  messageEn: Scalars['String']['output'];
  messageFr: Scalars['String']['output'];
  messageIt: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type CustomerProductOrderRestrictionModelSort = {
  direction: SortDirection;
  field: CustomerProductOrderRestrictionSortField;
};

export enum CustomerProductOrderRestrictionSortField {
  Created = 'created',
  Id = 'id',
  Updated = 'updated'
}

export type CustomerRebate = {
  __typename: 'CustomerRebate';
  code: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['NaiveDate']['output']>;
  price: Scalars['Float']['output'];
  startDate?: Maybe<Scalars['NaiveDate']['output']>;
};

export type CustomerRebateInput = {
  code: Scalars['String']['input'];
  companyCode: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  price: Scalars['Float']['input'];
};

export type CustomerResult = {
  __typename: 'CustomerResult';
  items: Array<CustomerEntity>;
  total: Scalars['Int']['output'];
};

export type CustomerSpecialPrice = {
  __typename: 'CustomerSpecialPrice';
  companyCode: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  rebate: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
};

export type CustomerSpecialPriceInput = {
  companyCode: Scalars['String']['input'];
  price: Scalars['Float']['input'];
  rebate: Scalars['Float']['input'];
  sku: Scalars['String']['input'];
};

export type CustomerSpecialPriceRow = {
  __typename: 'CustomerSpecialPriceRow';
  discountPercentage: Scalars['Float']['output'];
  price: Scalars['Float']['output'];
  sku: Scalars['ID']['output'];
};

export type DataQualityIssue = {
  __typename: 'DataQualityIssue';
  count: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  explanation: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type DataQualityNodeEntry = {
  __typename: 'DataQualityNodeEntry';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
};

export type DataQualityReport = {
  __typename: 'DataQualityReport';
  categoriesWithProductParents: Array<MixedSiblingNodeEntry>;
  issues: Array<DataQualityIssue>;
  missingProductPrices: Array<ProductWithIssue>;
  mixedSiblingNodes: Array<MixedSiblingNodeEntry>;
  variantProductsWithoutChildren: Array<MixedSiblingNodeEntry>;
  zeroProductPrices: Array<ZeroPriceIssue>;
};

export type DatatransOnlinePayment = {
  __typename: 'DatatransOnlinePayment';
  apiUrl: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  merchantId: Scalars['String']['output'];
  password: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type DatatransOnlinePaymentInput = {
  apiUrl: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  merchantId: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

/** A date range descriptor. Supports open-ended ranges when only one of the values is provided. */
export type DateRangeInput = {
  /** End date of the date range. */
  end?: InputMaybe<Scalars['DateTime']['input']>;
  /** Start date of the date range. */
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Dealer = {
  __typename: 'Dealer';
  /** @deprecated Use addresses instead */
  address: CustomerAddress;
  addresses: Array<DealerAddress>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  keyDealerForBrands: Array<Brand>;
  /** @deprecated Use latitude in addresses instead */
  latitude: Scalars['String']['output'];
  /** @deprecated Use longitude in addresses instead */
  longitude: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type DealerAddress = {
  __typename: 'DealerAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latitude: Scalars['String']['output'];
  longitude: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type DeletedSupportTicketEventAttachmentItem = {
  __typename: 'DeletedSupportTicketEventAttachmentItem';
  attachmentType: SupportAttachmentType;
  deleted: Scalars['DateTime']['output'];
  deletedBy: Scalars['String']['output'];
  fileName: Scalars['String']['output'];
  index: Scalars['Int']['output'];
};

export type DeliveredQuantitySourceResult = {
  __typename: 'DeliveredQuantitySourceResult';
  items: Array<SourceDeliveredQuantity>;
  total: Scalars['Int']['output'];
};

export type DeliveriesResult = {
  __typename: 'DeliveriesResult';
  items: Array<Delivery>;
  total: Scalars['Int']['output'];
};

export type Delivery = {
  __typename: 'Delivery';
  address: Scalars['String']['output'];
  carrier: Scalars['String']['output'];
  city: Scalars['String']['output'];
  created: Scalars['String']['output'];
  deliveryDate: Scalars['String']['output'];
  deliveryNoteId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  packingSlipId: Scalars['String']['output'];
  postbox?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  shipment: Scalars['String']['output'];
  shippingMethodName?: Maybe<Scalars['String']['output']>;
  trackingNumber: Scalars['String']['output'];
};


export type DeliveryShippingMethodNameArgs = {
  language?: InputMaybe<Language>;
};

export type DeliveryAddress = {
  __typename: 'DeliveryAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type DeliveryAddressInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type DeliveryCharge = {
  __typename: 'DeliveryCharge';
  /** Price of shipping */
  price?: Maybe<Scalars['Float']['output']>;
  /** VAT For the delivery charge */
  vatAmount?: Maybe<Scalars['Float']['output']>;
};

export type DeliverySourceResult = {
  __typename: 'DeliverySourceResult';
  items: Array<SourceDelivery>;
  total: Scalars['Int']['output'];
};

export type DesiredDeliveryCharges = {
  codFees: Scalars['Float']['input'];
  deliveryCharge: Scalars['Float']['input'];
  smallOrderSurcharge: Scalars['Float']['input'];
};

export type DiscountTier = {
  __typename: 'DiscountTier';
  nameDE: Scalars['String']['output'];
  nameEN: Scalars['String']['output'];
  nameFR: Scalars['String']['output'];
  nameIT: Scalars['String']['output'];
  threshold: Scalars['String']['output'];
  tier: Scalars['String']['output'];
};

export type DownloadRequest = {
  __typename: 'DownloadRequest';
  id: Scalars['ID']['output'];
  status: DownloadRequestStatus;
  url?: Maybe<Scalars['String']['output']>;
};

/** Current status information of a download request */
export type DownloadRequestInfo = {
  __typename: 'DownloadRequestInfo';
  /** Download request id */
  id: Scalars['String']['output'];
  /** Current status of the request. */
  status: DownloadRequestStatus;
  /** URL of the generated file, only available when status is [`DownloadRequestStatus::Ready`] */
  url?: Maybe<Scalars['String']['output']>;
};

export enum DownloadRequestStatus {
  /** Indicates that the download request has failed */
  Failed = 'failed',
  /** Indicates that the download request is being processed */
  Pending = 'pending',
  /** Indicates that the download request is complete and the file is ready for download */
  Ready = 'ready'
}

export enum DownloadableFileFormat {
  Csv = 'csv',
  Txt = 'txt',
  Xls = 'xls'
}

export enum DownloadablePurchaseListFileFormat {
  Csv = 'csv',
  /** @deprecated Use xlsx format instead */
  Xls = 'xls',
  Xlsx = 'xlsx'
}

/**
 * Possible values:
 *  - null, the item has no dropshipping status
 *  - "eligible", which means the item is dropshipable
 *  - "ineligible", the item cannot be dropshipped for an unspecified reason
 *  - "bulky", the item cannot be dropshipped because it's bulky
 *  - "dangerous", the item cannot be dropshipped because it's dangerous
 *  - "unavailable", not available immediately in the main stock
 */
export enum DropshippingStatus {
  Bulky = 'bulky',
  Dangerous = 'dangerous',
  Eligible = 'eligible',
  Ineligible = 'ineligible',
  Unavailable = 'unavailable'
}

export enum ExportFileFormat {
  Csv = 'csv',
  Xlsx = 'xlsx'
}

export type FeatureCategories = {
  __typename: 'FeatureCategories';
  categories: Array<FeaturedCategory>;
  enabled: Scalars['Boolean']['output'];
};

export type FeatureCategoriesInput = {
  categories: Array<FeaturedCategoryInput>;
  enabled: Scalars['Boolean']['input'];
};

export type FeaturedCategory = {
  __typename: 'FeaturedCategory';
  displayName: LocalizedField;
  iconUrl: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type FeaturedCategoryInput = {
  displayName: LocalizedFieldInput;
  iconUrl: Scalars['String']['input'];
  id: Scalars['Int']['input'];
};

export type FileMetadata = {
  __typename: 'FileMetadata';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type FileMetadataInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type FormError = {
  __typename: 'FormError';
  /** Localized error message */
  description?: Maybe<Scalars['String']['output']>;
  /** Unique ID for the type of error */
  type: Scalars['ID']['output'];
};

export type FutureDelivery = {
  __typename: 'FutureDelivery';
  amount: Scalars['Int']['output'];
  calendarWeek: Scalars['Int']['output'];
  year: Scalars['Int']['output'];
};

export type FutureStockQuantity = {
  __typename: 'FutureStockQuantity';
  /** date contains the ISO timestamp string. */
  date: Scalars['String']['output'];
  location: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  sku: Scalars['String']['output'];
  store: Scalars['ID']['output'];
};

export type FutureStockQuantityItem = {
  __typename: 'FutureStockQuantityItem';
  /** date contains the ISO timestamp string. */
  date: Scalars['String']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type FutureStockQuantityResult = {
  __typename: 'FutureStockQuantityResult';
  location: Scalars['String']['output'];
  quantities: Array<FutureStockQuantityItem>;
  sku: Scalars['String']['output'];
  store: Scalars['ID']['output'];
};

export enum GeneralNextAvailabilityStatus {
  NoLongerAvailable = 'noLongerAvailable',
  OnDemand = 'onDemand',
  Unknown = 'unknown'
}

export type HasPermissionInput = {
  action: Scalars['String']['input'];
  resource: Scalars['String']['input'];
};

export type IconLibraryItem = {
  __typename: 'IconLibraryItem';
  s3Path: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type IconLibraryItemInput = {
  s3Path: Scalars['String']['input'];
};

export type InitiatePaymentOutcome = {
  __typename: 'InitiatePaymentOutcome';
  errorCode?: Maybe<Scalars['String']['output']>;
  paymentUrl: Scalars['String']['output'];
};

export type IntercycleHelpDeskQueryOutcome = {
  __typename: 'IntercycleHelpDeskQueryOutcome';
  helpDeskUrl: Scalars['String']['output'];
};

export type InviteUserInput = {
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  email: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  permissions: Array<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export enum Language {
  De = 'de',
  En = 'en',
  Fr = 'fr',
  It = 'it'
}

/** Represents a linked product entry */
export type LinkedProducts = {
  __typename: 'LinkedProducts';
  comment?: Maybe<OptionalLocalizedField>;
  sku: Scalars['String']['output'];
};

export type LocalizedField = {
  __typename: 'LocalizedField';
  de: Scalars['String']['output'];
  en: Scalars['String']['output'];
  fr: Scalars['String']['output'];
  it: Scalars['String']['output'];
};

export type LocalizedFieldInput = {
  de: Scalars['String']['input'];
  en: Scalars['String']['input'];
  fr: Scalars['String']['input'];
  it: Scalars['String']['input'];
};

export type LocalizedListField = {
  __typename: 'LocalizedListField';
  de: Array<Scalars['String']['output']>;
  en: Array<Scalars['String']['output']>;
  fr: Array<Scalars['String']['output']>;
  it: Array<Scalars['String']['output']>;
};

export type LocalizedProductDocumentCollection = {
  __typename: 'LocalizedProductDocumentCollection';
  de: Array<ProductDocumentItem>;
  en: Array<ProductDocumentItem>;
  fr: Array<ProductDocumentItem>;
  it: Array<ProductDocumentItem>;
};

export type LocalizedProductVideoCollection = {
  __typename: 'LocalizedProductVideoCollection';
  de: Array<ProductVideoItem>;
  en: Array<ProductVideoItem>;
  fr: Array<ProductVideoItem>;
  it: Array<ProductVideoItem>;
};

export type ManagedCompanyCodes = {
  __typename: 'ManagedCompanyCodes';
  canManageAll: Scalars['Boolean']['output'];
  companyCodes: Array<Scalars['String']['output']>;
};

export type MetaProduct = {
  __typename: 'MetaProduct';
  category: Scalars['String']['output'];
  /** Id of the category that can be filtered. */
  categoryValue: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  images: Array<MetaProductImage>;
  manufacturerName: Scalars['String']['output'];
  manufacturingEndYear?: Maybe<Scalars['Int']['output']>;
  manufacturingStartYear?: Maybe<Scalars['Int']['output']>;
  model: Scalars['String']['output'];
  skuList: Array<Scalars['String']['output']>;
  /** Spare part document PDF */
  sparePartDocument?: Maybe<Scalars['String']['output']>;
  /** SKU prefix for spare part document */
  sparePartSkuPrefix?: Maybe<Scalars['String']['output']>;
  /** Name and value pairs that define the product's specifications */
  specificationAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  type: Scalars['String']['output'];
  /** Id of the type that can be filtered. */
  typeValue: Scalars['String']['output'];
};

export type MetaProductFilters = {
  __typename: 'MetaProductFilters';
  categories: Array<Scalars['String']['output']>;
  endYear: Scalars['Int']['output'];
  manufacturers: Array<Scalars['String']['output']>;
  models: Array<Scalars['String']['output']>;
  productTypes: Array<Scalars['String']['output']>;
  startYear: Scalars['Int']['output'];
};

export type MetaProductImage = {
  __typename: 'MetaProductImage';
  /** 176px rendition */
  rendition176pxUrl: Scalars['String']['output'];
  /** 352px rendition */
  rendition352pxUrl: Scalars['String']['output'];
  /** 704px rendition */
  rendition704pxUrl: Scalars['String']['output'];
  /** 1080p rendition */
  rendition1080pUrl: Scalars['String']['output'];
};

export type MetaProductModelSort = {
  direction: SortDirection;
  field: MetaProductSortField;
};

export type MetaProductResult = {
  __typename: 'MetaProductResult';
  /** Opaque cursor that can be passed to subsequent queries to ensure result consistency. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** List of meta products in the requested page. */
  items: Array<PartialMetaProduct>;
  /** Total number of vehicles in the DB that match the given filter parameters (excluding pagination parameters). */
  total?: Maybe<Scalars['Int']['output']>;
};

export enum MetaProductSortField {
  Created = 'created',
  FilesOutdated = 'filesOutdated',
  Id = 'id',
  ManufacturerName = 'manufacturerName',
  ManufacturingEndYear = 'manufacturingEndYear',
  ManufacturingStartYear = 'manufacturingStartYear',
  Model = 'model',
  TypeDe = 'typeDe',
  TypeEn = 'typeEn',
  TypeFr = 'typeFr',
  TypeIt = 'typeIt',
  Updated = 'updated'
}

export type MixedSiblingNodeEntry = {
  __typename: 'MixedSiblingNodeEntry';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
};

export type Model = {
  __typename: 'Model';
  created: Scalars['DateTime']['output'];
  emptyCh: Scalars['Boolean']['output'];
  emptyEu: Scalars['Boolean']['output'];
  emptyIc: Scalars['Boolean']['output'];
  emptyStores: Array<Scalars['String']['output']>;
  excludedFromProductSearch: Scalars['Boolean']['output'];
  filters: Array<Scalars['String']['output']>;
  hasMotorcycleFilter: Scalars['Boolean']['output'];
  hiddenInTree: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  position: Scalars['Int']['output'];
  updated: Scalars['DateTime']['output'];
  visibility: Array<Scalars['String']['output']>;
};

export type Motorcycle = {
  __typename: 'Motorcycle';
  /** @deprecated Removed */
  active?: Maybe<Scalars['Boolean']['output']>;
  ccm: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  manufacturerName: Scalars['String']['output'];
  manufacturingEndYear?: Maybe<Scalars['Int']['output']>;
  manufacturingStartYear: Scalars['Int']['output'];
  model: Scalars['String']['output'];
  /** @deprecated Removed */
  modelAddition?: Maybe<Scalars['String']['output']>;
  ratedPower: Scalars['String']['output'];
  tireApplicationRanges?: Maybe<Array<Scalars['String']['output']>>;
  tireCodes: Array<Scalars['String']['output']>;
  typeAcceptance?: Maybe<Scalars['String']['output']>;
  typeAcceptanceList: Array<Scalars['String']['output']>;
  /** @deprecated Removed */
  typeName?: Maybe<Scalars['String']['output']>;
  typeNumber?: Maybe<Scalars['String']['output']>;
  typeNumbers: Array<Scalars['String']['output']>;
};

export type MotorcycleLicensePlateLookupResult = {
  __typename: 'MotorcycleLicensePlateLookupResult';
  manufacturerName: Scalars['String']['output'];
  manufacturingEndYear?: Maybe<Scalars['Int']['output']>;
  manufacturingStartYear?: Maybe<Scalars['Int']['output']>;
  model: Scalars['String']['output'];
  registrationDate: Scalars['String']['output'];
  typeAcceptance: Scalars['String']['output'];
};

export type MotorcyclesResult = {
  __typename: 'MotorcyclesResult';
  items: Array<Motorcycle>;
  total: Scalars['Int']['output'];
};

export type Mutation = {
  __typename: 'Mutation';
  addIconsToLibrary: Scalars['Boolean']['output'];
  changeEmailAddress: ChangeEmailAddressOutcome;
  /** @deprecated use `orderNotificationEnabled` field on `changeEmailAddress` mutation */
  changeOrderNotificationEmailSetting?: Maybe<MutationOutcome>;
  changeUserLanguage: MutationOutcome;
  changeUserPassword: MutationOutcome;
  clearCart: MutationOutcome;
  /**
   * Login to the API using `username` and `password`
   *
   * # Panics
   *
   * Panics if the resolver fails to fetch [AppContext](crate::AppContext) instance from resolver context.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The user doesn't exist
   * - The password verification fails
   * - Encoding authenticated user data into JWT token fails
   * @deprecated Use `login` mutation instead
   */
  consoleLogin: UserLogin;
  contactBackorderSupport: Scalars['Boolean']['output'];
  copyDeliveriesForTestUserFromCustomer: Scalars['Boolean']['output'];
  createCart: Scalars['Int']['output'];
  createCatalogSnapshot: Scalars['Boolean']['output'];
  /**
   * Create a new company. This action can only be performed by system managers.
   *
   * * `company`: Details of the new company to be created.
   *
   * # Minimum Permissions
   *
   * This query requires minimum [`SYSTEM_MANAGER`] permission.
   */
  createCompany: Scalars['Boolean']['output'];
  createCustomerOnlineDealer: Scalars['Boolean']['output'];
  createCustomerProductOrderRestriction: Scalars['Boolean']['output'];
  createRootNode?: Maybe<Scalars['Boolean']['output']>;
  createS3UploadUrl: UploadUrl;
  createStore: Scalars['Boolean']['output'];
  createStorePreorderSeason: Scalars['Boolean']['output'];
  /**
   * Create a new support ticket
   *
   * # Arguments
   *
   * * `record`: New ticket details
   * * `items`: Items to be added to the new ticket
   */
  createSupportTicket: CreateSupportTicketResult;
  /**
   * Create a new document event for ticket
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to add event to
   * * `document_type`: Type of document contained in this event
   * * `attachments`: Optional files for this event to be attached
   */
  createSupportTicketDocumentEvent: Scalars['Boolean']['output'];
  /**
   * Create a new internal note event for ticket.
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to add event to
   * * `comment`: Optional comment (chat message) for this event
   * * `attachments`: Optional files for this event to be attached
   */
  createSupportTicketInternalNoteEvent: Scalars['Boolean']['output'];
  /**
   * Create a new message event for ticket.
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to add event to
   * * `comment`: Optional comment (chat message) for this event
   * * `attachments`: Optional files for this event to be attached
   */
  createSupportTicketMessageEvent: Scalars['Boolean']['output'];
  createSupportTicketMessageTemplate: SupportTicketMessageTemplate;
  /**
   * Create a new system maintenance event.
   *
   * # Arguments
   *
   * * `record`: Details of the new event
   *
   * # Minimum Permission
   *
   * This query requires at least [CompanyManager](Permission::Manage) permission with [Company](ManagedResource::Company) resource.
   */
  createSystemMaintenance: Scalars['Boolean']['output'];
  createTestUser?: Maybe<CreateTestUserResult>;
  createTestUserNew: CreateTestUserResultNew;
  createUser: Scalars['Boolean']['output'];
  deleteCustomerOnlineDealer: Scalars['Boolean']['output'];
  deleteCustomerProductOrderRestriction: Scalars['Boolean']['output'];
  /**
   * Delete order by ID.
   *
   * # Arguments
   *
   * - `id`: ID of the order to be deleted.
   *
   * # Minimum Permission
   *
   * This query requires at least [SystemManager](SYSTEM_MANAGER) permission.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The user doesn't have sufficient permissions.
   * - The order doesn't exist.
   * - Deleting related entities fail.
   */
  deleteOrder: Scalars['Boolean']['output'];
  deleteShoppingList: Scalars['Boolean']['output'];
  deleteSupportTicketMessageTemplate: Scalars['Boolean']['output'];
  deleteTestUser?: Maybe<Scalars['Boolean']['output']>;
  deleteTestUserNew: Scalars['Boolean']['output'];
  deleteTicketEventAttachment: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  deployCatalogSnapshot: Scalars['Boolean']['output'];
  /**
   * Discard a ticket that's presently in draft.
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to be discarded
   */
  discardTicket: Scalars['Boolean']['output'];
  executeNodeIdListUploadChanges?: Maybe<MutationOutcome>;
  /** Request a new cart export to be generated and available for download. */
  exportCarts: DownloadRequestInfo;
  /**
   * Generate a new export and download request for the tickets based on provided filters. This
   * mutation will generate an export that contains all tickets matching provided filters without
   * limit in terms of page size.
   *
   * # Arguments
   *
   * * `search`: Search tickets by ticket number or reference
   * * `customer_number`: Filter tickets for a particular customer by customer number
   * * `r#type`: Filter tickets by type
   * * `product_class_id`: Filter tickets by returns category. Only available when type 'return'
   * is supplied. Providing this parameter otherwise will result in an error
   * * `status`: Filter tickets by current status
   * * `submission_date`: Only return tickets that were submitted within the specified date range.
   * The date range can be open-ended on either start or end.
   * * `last_updated`: Only return tickets that were last updated within the specified date range.
   * The date range can be open-ended on either start or end.
   * * `assignees`: Filter tickets by username of the person who is assigned to the ticket
   * * `format`: Format in which to generate the file. Supports `csv` and `xlsx`
   */
  generateTicketsExport: DownloadRequestInfo;
  impersonate: UserLogin;
  importCustomerSalesList: Scalars['Boolean']['output'];
  initiatePayment: InitiatePaymentOutcome;
  intercycleHelpDesk?: Maybe<IntercycleHelpDeskQueryOutcome>;
  inviteUser: Scalars['Boolean']['output'];
  login: UserLogin;
  makeCartInactive?: Maybe<Scalars['Boolean']['output']>;
  makeCartVisible?: Maybe<Scalars['Boolean']['output']>;
  markAllNotificationsAsRead: Scalars['Boolean']['output'];
  markNotificationAsRead: Scalars['Boolean']['output'];
  markNotificationAsUnread: Scalars['Boolean']['output'];
  notifySalesRepresentativeTeamOfPreorder: Scalars['Boolean']['output'];
  /**
   * Re-submit an order by ID.
   *
   * # Arguments
   *
   * - `id`: ID of the order to be deleted.
   *
   * # Minimum Permission
   *
   * This query requires at least [CustomerManager] permission.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The user doesn't have sufficient permissions.
   * - The order doesn't exist.
   */
  reSubmitOrder: Scalars['Boolean']['output'];
  refreshToken: UserLogin;
  refreshUpstreamCache?: Maybe<Scalars['Boolean']['output']>;
  regeneratePassword: Scalars['Boolean']['output'];
  rejectSupportTicketItems: Scalars['Boolean']['output'];
  reloadSystem: Scalars['Boolean']['output'];
  removeIconFromLibrary: Scalars['Boolean']['output'];
  removeTestDeliveriesForTestUserFromCustomer: Scalars['Boolean']['output'];
  renameCart?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Reopen a ticket that has already been closed
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to be reopened
   */
  reopenTicket: Scalars['Boolean']['output'];
  reportProduct?: Maybe<Scalars['Boolean']['output']>;
  requestArticleCatalog: DownloadRequest;
  requestBackordersCsv: DownloadRequest;
  requestCustomCatalog: DownloadRequest;
  requestCustomerPriceList: DownloadRequest;
  requestCustomerPurchaseList: DownloadRequest;
  requestDelivery: Scalars['Boolean']['output'];
  requestOrderDocument: DownloadRequest;
  requestPasswordReset: MutationOutcome;
  requestPreorderList: DownloadRequest;
  requestProductsList: DownloadRequest;
  requestQuantityChange: Scalars['Boolean']['output'];
  resetInvalidDeliveryDates?: Maybe<Scalars['Boolean']['output']>;
  resetPassword: MutationOutcome;
  runTask: RunTaskResult;
  saveCartDiscounts: Scalars['Boolean']['output'];
  saveCartFields?: Maybe<Scalars['Boolean']['output']>;
  saveCustomerPreorderPricing?: Maybe<Scalars['Boolean']['output']>;
  saveShoppingList?: Maybe<ShoppingList>;
  sendContactMessage?: Maybe<MutationOutcome>;
  sendContactMessageToDealer: Scalars['Boolean']['output'];
  sendShoppingListCode?: Maybe<MutationOutcome>;
  setPreorderBrandDiscount?: Maybe<Scalars['Boolean']['output']>;
  stopImpersonating: UserLogin;
  /** Send order using cart */
  submitOrder: SendOrderOutcome;
  /**
   * Submit a ticket that's presently in draft.
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to be submitted
   */
  submitTicket: Scalars['Boolean']['output'];
  updateCartItemComment: MutationOutcome;
  updateCartItemQuantities: MutationOutcome;
  updateCartItemQuantity: MutationOutcome;
  updateCatalogBrand: Scalars['Boolean']['output'];
  /**
   * Update information for a company.
   *
   * * `updates`: Updates to be applied to the company defined by `id` in the updates object.
   *
   * # Minimum Permissions
   *
   * This query requires minimum [`Permission::Manage(ManagedResource::Company(_))`] permission.
   */
  updateCompany: Scalars['Boolean']['output'];
  updateCustomerCart: Scalars['Boolean']['output'];
  updateCustomerOnlineDealer: Scalars['Boolean']['output'];
  updateCustomerProductOrderRestriction: Scalars['Boolean']['output'];
  updateCustomerSalesListFromFile: Scalars['Boolean']['output'];
  /**
   * Update permissions for a locally created user.
   *
   * # Arguments
   *
   * * `id`: Id of the user to update
   * * `permissions`: New permissions that should replace the existing ones. This field always
   * replaces all of current permissions.
   */
  updateLocalUserPermissions: Scalars['Boolean']['output'];
  updateMetaProduct: Scalars['Boolean']['output'];
  updateNode: Scalars['Boolean']['output'];
  updateNodeIdsFromFile: Scalars['Boolean']['output'];
  updateOnlineDealerLogo: Scalars['Boolean']['output'];
  /**
   * Update order notification emails for a user.
   *
   * # Arguments
   *
   * * `id`: Id of the user to update
   * * `emails`: List of emails that should be saved as notification emails on user
   *
   * # Minimum Permissions
   *
   * This query requires minimum [`Permission::Manage(ManagedResource::Company(ResourceAccess::One(<company_code>)))`] permission
   * where `<company_code>` refers to the company that the customer associated with target user
   * belongs to.
   */
  updateOrderEmailRecipients: Scalars['Boolean']['output'];
  updateOrderNotificationEmails?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Update permissions for a given remote user by username.
   *
   * # Arguments
   *
   * * `username`: Username of the target user.
   * * `permissions`: Permissions to apply to the user.
   *
   * # Errors
   *
   * This mutation results in an error if:
   * - Invalid permissions are provided.
   * - Logged in user cannot update permissions for target user
   */
  updatePermissionsForRemoteUser: Scalars['Boolean']['output'];
  /** @deprecated Use logo field in updatePreorderSeason mutation */
  updatePreorderSeasonLogo: Scalars['Boolean']['output'];
  updateShippingMethodDropshipmentFlag: Scalars['Boolean']['output'];
  /**
   * Mutation to update a specific store either partially or fully. Any field that is missing in the `updates` input argument will be left unchanged.
   * To set a field to `Null`, the client needs to explicitly send `null` as the value for that field.
   *
   * # Minimum Permission
   *
   * This mutation requires at least [CompanyManager](Permission::Manage) permission with [Company](ManagedResource::Company) resource.
   *
   * # Permissions
   *
   * - [`SYSTEM_MANAGER`] or [`ALL_COMPANY_MANAGER`] can update any store.
   * - [CompanyManagers](Permission::Manage) can only update stores that are associated to the company they manage.
   *
   * # Errors
   *
   * This function will return an error if:
   * - User is not logged in
   * - User lacks sufficient permissions. See [Minimum Permission](#minimum-permission) for permission requirements
   * - Store to update was not found
   * - Provided value for any field cannot be properly translated to the corresponding type in Database
   */
  updateStore: Scalars['Boolean']['output'];
  /** Update a single store feature */
  updateStoreFeature: Scalars['Boolean']['output'];
  /**
   * Update logo for a store.
   *
   * This function takes an SVG file and a store identifier, generates all of the necessary
   * renditions such as favicons and browser icons, and puts them in a fixed path in S3 that
   * can be used reliable by the client for fetching logo.
   *
   * # Minimum Permission
   *
   * This mutation requires at least [CompanyManager](Permission::Manage) permission with [Company](ManagedResource::Company) resource.
   *
   * # Permissions
   *
   * - [`SYSTEM_MANAGER`] or [`ALL_COMPANY_MANAGER`] can update logo for any store.
   * - [CompanyManagers](Permission::Manage) can only update logo for stores that are associated to the company they manage.
   *
   * # Arguments
   *
   * * `store`: Store code that the logo belongs to.
   * * `file`: An SVG file containing the new logo.
   *
   * # Errors
   *
   * This function returns an error if
   * - An invalid image file is provided. This includes all non-SVG files as well as corrupted
   * SVG files.
   * - User doesn't have sufficient permissions.
   * - AWS is not configured.
   * - Upload request to S3 fails for any rendition.
   * - Generating any rendition fails.
   */
  updateStoreLogo: Scalars['Boolean']['output'];
  updateStorePreorderSeason: Scalars['Boolean']['output'];
  /**
   * Update ticket details or resolution for ticket item(s)
   *
   * # Arguments
   *
   * * `updates`: Input object containing ticket details to be updated. Any missing field is left
   * untouched in the updated ticket.
   * * `items`: Items to be set in the updated ticket. Overwrites any previous ticket items data.
   */
  updateSupportTicket: Scalars['Boolean']['output'];
  updateSupportTicketItems: Scalars['Boolean']['output'];
  updateSupportTicketMessageTemplate: Scalars['Boolean']['output'];
  /**
   * Update details of an existing system maintenance event.
   *
   * # Arguments
   *
   * * `updates`: Partial updates to be applied to the entry.
   *
   * # Minimum Permission
   *
   * This query requires at least [CompanyManager](Permission::Manage) permission with [Company](ManagedResource::Company) resource.
   */
  updateSystemMaintenance: Scalars['Boolean']['output'];
  updateUser: Scalars['Boolean']['output'];
  upgradeUser: Scalars['Boolean']['output'];
};


export type MutationAddIconsToLibraryArgs = {
  icons: Array<Scalars['String']['input']>;
  storeId: Scalars['Int']['input'];
};


export type MutationChangeEmailAddressArgs = {
  email: Scalars['String']['input'];
  orderNotificationsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationChangeOrderNotificationEmailSettingArgs = {
  isOrderNotificationEmailEnabled: Scalars['Boolean']['input'];
};


export type MutationChangeUserLanguageArgs = {
  language: Language;
};


export type MutationChangeUserPasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationClearCartArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  store: Scalars['ID']['input'];
};


export type MutationConsoleLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationContactBackorderSupportArgs = {
  id: Scalars['Int']['input'];
  language: Language;
  message: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type MutationCopyDeliveriesForTestUserFromCustomerArgs = {
  customerNumber: Scalars['String']['input'];
};


export type MutationCreateCartArgs = {
  cartType: CartType;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationCreateCatalogSnapshotArgs = {
  environment: CatalogEnvironment;
};


export type MutationCreateCompanyArgs = {
  record: NewCompanyInput;
};


export type MutationCreateCustomerOnlineDealerArgs = {
  record: NewCustomerOnlineDealerInput;
};


export type MutationCreateCustomerProductOrderRestrictionArgs = {
  record: NewCustomerProductOrderRestrictionInput;
};


export type MutationCreateRootNodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateS3UploadUrlArgs = {
  filename: Scalars['String']['input'];
  metadata: Array<FileMetadataInput>;
  type: Scalars['String']['input'];
};


export type MutationCreateStoreArgs = {
  record: NewStoreInput;
};


export type MutationCreateStorePreorderSeasonArgs = {
  record: NewStorePreorderSeasonInput;
};


export type MutationCreateSupportTicketArgs = {
  items: Array<SupportTicketItemUpdateInput>;
  record: NewSupportTicketInput;
};


export type MutationCreateSupportTicketDocumentEventArgs = {
  attachments: Array<Scalars['String']['input']>;
  documentType: SupportTicketEventDocumentTypes;
  id: Scalars['Int']['input'];
};


export type MutationCreateSupportTicketInternalNoteEventArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type MutationCreateSupportTicketMessageEventArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type MutationCreateSupportTicketMessageTemplateArgs = {
  record: NewSupportTicketMessageTemplateInput;
};


export type MutationCreateSystemMaintenanceArgs = {
  record: NewSystemMaintenanceInput;
};


export type MutationCreateTestUserArgs = {
  addresses?: InputMaybe<Array<TestUserAddressInput>>;
  cloneCustomerOrders?: InputMaybe<Scalars['Boolean']['input']>;
  companyCode: Scalars['String']['input'];
  customerName?: InputMaybe<Scalars['String']['input']>;
  customerNameToClone?: InputMaybe<Scalars['String']['input']>;
  customerToClone?: InputMaybe<Scalars['String']['input']>;
  dealerPricesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dropshipmentAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  failDeliveryChargeRequest?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  mockedDeliveryCharges?: InputMaybe<DesiredDeliveryCharges>;
  permissions?: InputMaybe<Array<InputMaybe<PermissionInput>>>;
  preferredStockLocation: Scalars['String']['input'];
  requiresOnlinePayment?: InputMaybe<Scalars['Boolean']['input']>;
  requiresPasswordChange?: InputMaybe<Scalars['Boolean']['input']>;
  requiresPrepay?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleEmployeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Scalars['String']['input']>;
  skipEmails?: InputMaybe<Scalars['Boolean']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationCreateTestUserNewArgs = {
  addresses?: InputMaybe<Array<TestUserAddressInput>>;
  cloneCustomerOrders?: InputMaybe<Scalars['Boolean']['input']>;
  companyCode: Scalars['String']['input'];
  customerName?: InputMaybe<Scalars['String']['input']>;
  customerNameToClone?: InputMaybe<Scalars['String']['input']>;
  customerToClone?: InputMaybe<Scalars['String']['input']>;
  dealerPricesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dropshipmentAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  failDeliveryChargeRequest?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  mockedDeliveryCharges?: InputMaybe<DesiredDeliveryCharges>;
  permissions?: InputMaybe<Array<PermissionInput>>;
  preferredStockLocation: Scalars['String']['input'];
  requiresOnlinePayment?: InputMaybe<Scalars['Boolean']['input']>;
  requiresPasswordChange?: InputMaybe<Scalars['Boolean']['input']>;
  requiresPrepay?: InputMaybe<Scalars['Boolean']['input']>;
  responsibleEmployeeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  segment?: InputMaybe<Scalars['String']['input']>;
  skipEmails?: InputMaybe<Scalars['Boolean']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationCreateUserArgs = {
  record: NewUserInput;
};


export type MutationDeleteCustomerOnlineDealerArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteCustomerProductOrderRestrictionArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteShoppingListArgs = {
  code: Scalars['String']['input'];
};


export type MutationDeleteSupportTicketMessageTemplateArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteTestUserArgs = {
  username: Scalars['String']['input'];
};


export type MutationDeleteTestUserNewArgs = {
  username: Scalars['String']['input'];
};


export type MutationDeleteTicketEventAttachmentArgs = {
  attachmentId: Scalars['Int']['input'];
  eventId: Scalars['String']['input'];
  ticketId: Scalars['Int']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeployCatalogSnapshotArgs = {
  environment: CatalogEnvironment;
  snapshot: Scalars['String']['input'];
};


export type MutationDiscardTicketArgs = {
  id: Scalars['Int']['input'];
};


export type MutationExecuteNodeIdListUploadChangesArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExportCartsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  customerNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  format: ExportFileFormat;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  notEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  paymentDueDate?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentTermId?: InputMaybe<Array<Scalars['String']['input']>>;
  preorderSeasonId?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shippingMethod?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<CustomerCartModelSort>;
  stockLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  store?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type MutationGenerateTicketsExportArgs = {
  assignees?: InputMaybe<Array<Scalars['String']['input']>>;
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  customerNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  format: SupportTicketExportFileFormat;
  lastUpdated?: InputMaybe<DateRangeInput>;
  productClassId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SupportTicketModelSort>;
  sortBy?: InputMaybe<SupportTicketSort>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  submissionDate?: InputMaybe<DateRangeInput>;
  type?: InputMaybe<SupportTicketType>;
};


export type MutationImpersonateArgs = {
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationImportCustomerSalesListArgs = {
  filePath: Scalars['String']['input'];
};


export type MutationInitiatePaymentArgs = {
  language: Language;
  orderId: Scalars['String']['input'];
  redirectUrls?: InputMaybe<RedirectUrlsInput>;
  store: Scalars['ID']['input'];
};


export type MutationInviteUserArgs = {
  allowCartPriceEditing?: InputMaybe<Scalars['Boolean']['input']>;
  allowPreorderPriceEditing?: InputMaybe<Scalars['Boolean']['input']>;
  user: InviteUserInput;
};


export type MutationLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationMakeCartInactiveArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMakeCartVisibleArgs = {
  id: Scalars['Int']['input'];
};


export type MutationMarkNotificationAsReadArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type MutationMarkNotificationAsUnreadArgs = {
  ids: Array<Scalars['Int']['input']>;
};


export type MutationNotifySalesRepresentativeTeamOfPreorderArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationReSubmitOrderArgs = {
  id: Scalars['String']['input'];
};


export type MutationRefreshUpstreamCacheArgs = {
  name: Scalars['String']['input'];
};


export type MutationRegeneratePasswordArgs = {
  id: Scalars['Int']['input'];
};


export type MutationRejectSupportTicketItemsArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
  ticketItems: Array<SupportTicketItemUpdateInput>;
};


export type MutationRemoveIconFromLibraryArgs = {
  icon: IconLibraryItemInput;
  storeId: Scalars['Int']['input'];
};


export type MutationRenameCartArgs = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};


export type MutationReopenTicketArgs = {
  id: Scalars['Int']['input'];
};


export type MutationReportProductArgs = {
  customerNumber: Scalars['String']['input'];
  emailAddress: Scalars['String']['input'];
  language: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  productName: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store?: InputMaybe<Scalars['ID']['input']>;
  url: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationRequestArticleCatalogArgs = {
  catalogFormat: ArticleCatalogDownloadFileFormat;
  catalogType: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestBackordersCsvArgs = {
  language: Language;
  orderEnd?: InputMaybe<Scalars['String']['input']>;
  orderStart?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<OrderOrigin>;
  pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderStatus>>;
  store: Scalars['ID']['input'];
};


export type MutationRequestCustomCatalogArgs = {
  accessoryPages: Array<Scalars['String']['input']>;
  brands: Array<Scalars['String']['input']>;
  format: CustomCatalogFormat;
  language: CustomCatalogLanguage;
  showCalculatedPriceExcludingVat: Scalars['Boolean']['input'];
  showCalculatedPriceIncludingVat: Scalars['Boolean']['input'];
  showDealerPrice: Scalars['Boolean']['input'];
  showRetailPriceExcludingVat: Scalars['Boolean']['input'];
  showRetailPriceIncludingVat: Scalars['Boolean']['input'];
  specialPriceCalculationBase?: InputMaybe<CustomCatalogSpecialPriceCalculationBase>;
  specialPriceCalculationType?: InputMaybe<CustomCatalogSpecialPriceCalculationType>;
  specialPriceCalculationValue?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationRequestCustomerPriceListArgs = {
  language: Language;
  store: Scalars['ID']['input'];
  type: DownloadableFileFormat;
};


export type MutationRequestCustomerPurchaseListArgs = {
  brands: Array<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  language: Language;
  type: DownloadablePurchaseListFileFormat;
  years: Array<Scalars['String']['input']>;
};


export type MutationRequestDeliveryArgs = {
  id: Scalars['Int']['input'];
  language: Language;
  quantity: Scalars['Int']['input'];
  requestedShippingDate?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationRequestOrderDocumentArgs = {
  documentId?: InputMaybe<Scalars['String']['input']>;
  documentRecId?: InputMaybe<Scalars['String']['input']>;
  documentType: OrderDocumentType;
  orderId?: InputMaybe<Scalars['String']['input']>;
  orderItemId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestPasswordResetArgs = {
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  username: Scalars['String']['input'];
};


export type MutationRequestProductsListArgs = {
  attributeFilters?: InputMaybe<Scalars['JSON']['input']>;
  bestAvailability?: InputMaybe<ProductBestAvailabilityFilter>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductListFilter>>>;
  hideDealerPrices?: InputMaybe<Scalars['Boolean']['input']>;
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  location: Scalars['String']['input'];
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  onSale?: InputMaybe<Scalars['Boolean']['input']>;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<ProductSortField>;
  sparePartParentId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<Array<InputMaybe<ProductState>>>;
  store: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestQuantityChangeArgs = {
  id: Scalars['Int']['input'];
  quantity: Scalars['Int']['input'];
  store: Scalars['ID']['input'];
};


export type MutationResetInvalidDeliveryDatesArgs = {
  cartId: Scalars['Int']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRunTaskArgs = {
  args?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};


export type MutationSaveCartDiscountsArgs = {
  discounts: Array<CustomDiscountInput>;
  id: Scalars['Int']['input'];
};


export type MutationSaveCartFieldsArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  fields: CartFieldsInput;
  store: Scalars['ID']['input'];
};


export type MutationSaveCustomerPreorderPricingArgs = {
  customerNumber: Scalars['ID']['input'];
  preorderSeasonId: Scalars['ID']['input'];
  values: CustomerPreorderPricingValuesInput;
};


export type MutationSaveShoppingListArgs = {
  creatorName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  items: Array<ShoppingListDataItemInput>;
  language: Language;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationSendContactMessageArgs = {
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
  language: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  store: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  url: Scalars['String']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSendContactMessageToDealerArgs = {
  ccSender?: InputMaybe<Scalars['Boolean']['input']>;
  dealerId: Scalars['ID']['input'];
  email: Scalars['String']['input'];
  language: Language;
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
  subject: Scalars['String']['input'];
};


export type MutationSendShoppingListCodeArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  language: Language;
  message: Scalars['String']['input'];
  sendCopyToUser?: InputMaybe<Scalars['Boolean']['input']>;
  store: Scalars['ID']['input'];
};


export type MutationSetPreorderBrandDiscountArgs = {
  brandName: Scalars['String']['input'];
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationSubmitOrderArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  language?: InputMaybe<Language>;
  store: Scalars['ID']['input'];
};


export type MutationSubmitTicketArgs = {
  id: Scalars['Int']['input'];
};


export type MutationUpdateCartItemCommentArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  comment: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type MutationUpdateCartItemQuantitiesArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  items: Array<CartItemInput>;
};


export type MutationUpdateCartItemQuantityArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type MutationUpdateCatalogBrandArgs = {
  updates: UpdateCatalogBrandInput;
};


export type MutationUpdateCompanyArgs = {
  updates: UpdateCompanyInput;
};


export type MutationUpdateCustomerCartArgs = {
  updates: UpdateCustomerCartInput;
};


export type MutationUpdateCustomerOnlineDealerArgs = {
  updates: UpdateCustomerOnlineDealerInput;
};


export type MutationUpdateCustomerProductOrderRestrictionArgs = {
  updates: UpdateCustomerProductOrderRestrictionInput;
};


export type MutationUpdateCustomerSalesListFromFileArgs = {
  filePath: Scalars['String']['input'];
};


export type MutationUpdateLocalUserPermissionsArgs = {
  id: Scalars['Int']['input'];
  permissions: Array<PermissionInput>;
};


export type MutationUpdateMetaProductArgs = {
  updates: UpdateMetaProductInput;
};


export type MutationUpdateNodeArgs = {
  updates: UpdateNodeInput;
};


export type MutationUpdateNodeIdsFromFileArgs = {
  filePath: Scalars['String']['input'];
};


export type MutationUpdateOnlineDealerLogoArgs = {
  filePath?: InputMaybe<Scalars['String']['input']>;
  onlineDealerId: Scalars['Int']['input'];
};


export type MutationUpdateOrderEmailRecipientsArgs = {
  emails: Array<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
};


export type MutationUpdateOrderNotificationEmailsArgs = {
  orderNotificationEmails: Array<Scalars['String']['input']>;
};


export type MutationUpdatePermissionsForRemoteUserArgs = {
  id: Scalars['Int']['input'];
  permissions: Array<PermissionInput>;
};


export type MutationUpdatePreorderSeasonLogoArgs = {
  filePath?: InputMaybe<Scalars['String']['input']>;
  preorderSeasonId: Scalars['Int']['input'];
};


export type MutationUpdateShippingMethodDropshipmentFlagArgs = {
  allowDropshipment: Scalars['Boolean']['input'];
  id: Scalars['Int']['input'];
};


export type MutationUpdateStoreArgs = {
  updates: UpdateStoreInput;
};


export type MutationUpdateStoreFeatureArgs = {
  id: Scalars['Int']['input'];
  updates: StoreFeatureUpdateInput;
};


export type MutationUpdateStoreLogoArgs = {
  logoDark?: InputMaybe<Scalars['String']['input']>;
  logoIcon?: InputMaybe<Scalars['String']['input']>;
  logoMain?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['String']['input'];
};


export type MutationUpdateStorePreorderSeasonArgs = {
  updates: UpdateStorePreorderSeasonInput;
};


export type MutationUpdateSupportTicketArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  isInternalNote?: InputMaybe<Scalars['Boolean']['input']>;
  items?: InputMaybe<Array<SupportTicketItemUpdateInput>>;
  updates?: InputMaybe<UpdateSupportTicketInput>;
};


export type MutationUpdateSupportTicketItemsArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  ticketItemUpdates: Array<SupportTicketItemUpdateInput>;
};


export type MutationUpdateSupportTicketMessageTemplateArgs = {
  updates: UpdateSupportTicketMessageTemplateInput;
};


export type MutationUpdateSystemMaintenanceArgs = {
  updates: UpdateSystemMaintenanceInput;
};


export type MutationUpdateUserArgs = {
  updates: UpdateUserInput;
};


export type MutationUpgradeUserArgs = {
  allowCartPriceEditing?: InputMaybe<Scalars['Boolean']['input']>;
  allowPreorderPriceEditing?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<PermissionInput>>;
};

export type MutationOutcome = {
  __typename: 'MutationOutcome';
  errorCode?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type NewCompanyInput = {
  branches: Array<CompanyBranchInput>;
  code: Scalars['String']['input'];
  details?: InputMaybe<CompanyDetailsInput>;
  finalPriceForCartBrandTotals: Scalars['Boolean']['input'];
  manualPreorderReservation: Scalars['Boolean']['input'];
  manualPrepayCustomerOrderReservation: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  semiFinalPriceForCartBrandTotals: Scalars['Boolean']['input'];
  smallOrderSurchargeThreshold?: InputMaybe<Scalars['Decimal']['input']>;
  sourceName: Scalars['String']['input'];
};

export type NewCustomerOnlineDealerInput = {
  brandUrls: Scalars['JSON']['input'];
  companyId: Scalars['Int']['input'];
  customerNumber: Scalars['Int']['input'];
};

export type NewCustomerProductOrderRestrictionInput = {
  brands: Array<Scalars['String']['input']>;
  companyId: Scalars['Int']['input'];
  conditions?: InputMaybe<Scalars['ProductOrderRestrictionConditions']['input']>;
  customerSegments: Array<Scalars['String']['input']>;
  messageDe: Scalars['String']['input'];
  messageEn: Scalars['String']['input'];
  messageFr: Scalars['String']['input'];
  messageIt: Scalars['String']['input'];
};

export type NewStoreInput = {
  code: Scalars['String']['input'];
  companyCode: Scalars['String']['input'];
  country: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rootCategoryId: Scalars['Int']['input'];
  webFlagRequired: Scalars['Boolean']['input'];
};

export type NewStorePreorderSeasonInput = {
  cmsRootFolder?: InputMaybe<Scalars['String']['input']>;
  deliveryEnd: Scalars['DateTime']['input'];
  deliveryStart: Scalars['DateTime']['input'];
  disallowNonPreorderProducts: Scalars['Boolean']['input'];
  discountTiers: Array<PreorderSeasonDiscountTierInput>;
  distributionChannel?: InputMaybe<Scalars['String']['input']>;
  employeeNotificationTargets: Array<Scalars['Int']['input']>;
  end: Scalars['DateTime']['input'];
  futurePriceStartDate: Scalars['NaiveDate']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  nameDe: Scalars['String']['input'];
  nameEn: Scalars['String']['input'];
  nameFr: Scalars['String']['input'];
  nameIt: Scalars['String']['input'];
  notificationTargets: Array<Scalars['String']['input']>;
  preorderDiscounts: Array<PreorderSeasonPreorderDiscountInput>;
  rebates: Array<CustomerPricingRebateInput>;
  segments: Array<Scalars['String']['input']>;
  specialPrices: Array<CustomerPricingSpecialPriceInput>;
  start: Scalars['DateTime']['input'];
  storeId: Scalars['Int']['input'];
  suppressNonPreorderPricing: Scalars['Boolean']['input'];
  tieredDiscounts: Array<PreorderSeasonTieredDiscountInput>;
};

export type NewSupportTicketInput = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  customerContactEmail?: InputMaybe<Scalars['String']['input']>;
  customerContactName?: InputMaybe<Scalars['String']['input']>;
  customerContactPhone?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromAddress?: InputMaybe<TicketFromAddressInput>;
  informationRequested?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTime']['input']>;
  productClassId?: InputMaybe<Scalars['Int']['input']>;
  searchKeywords?: InputMaybe<Scalars['String']['input']>;
  shipmentRequested?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<SupportTicketStatus>;
  type?: InputMaybe<SupportTicketType>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type NewSupportTicketMessageTemplateInput = {
  companyCode: Scalars['String']['input'];
  content: Scalars['String']['input'];
  language: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NewSystemMaintenanceInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  messageDe: Scalars['String']['input'];
  messageEn: Scalars['String']['input'];
  messageFr: Scalars['String']['input'];
  messageIt: Scalars['String']['input'];
  start?: InputMaybe<Scalars['DateTime']['input']>;
  type: SystemMaintenanceTypeEnum;
};

export type NewUserInput = {
  email: Scalars['String']['input'];
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  permissions: Array<PermissionInput>;
  username: Scalars['String']['input'];
};

export type NodeIdListExecutionPreview = {
  __typename: 'NodeIdListExecutionPreview';
  id: Scalars['String']['output'];
  idsToAdd?: Maybe<Array<Scalars['Int']['output']>>;
  idsToRemove?: Maybe<Array<Scalars['Int']['output']>>;
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  size: Scalars['Int']['output'];
};

export type NodeIdListUploadItem = {
  __typename: 'NodeIdListUploadItem';
  id: Scalars['String']['output'];
  lastModified?: Maybe<Scalars['DateTime']['output']>;
  size: Scalars['Int']['output'];
};

export type NodeIdListUploadsResult = {
  __typename: 'NodeIdListUploadsResult';
  items: Array<NodeIdListUploadItem>;
  total: Scalars['Int']['output'];
};

export type NodeModelSort = {
  direction: SortDirection;
  field: NodeSortField;
};

export enum NodeSortField {
  Created = 'created',
  Id = 'id',
  LabelDe = 'labelDe',
  LabelEn = 'labelEn',
  LabelFr = 'labelFr',
  LabelIt = 'labelIt',
  Outdated = 'outdated',
  Unmapped = 'unmapped',
  Updated = 'updated'
}

export type NodesResult = {
  __typename: 'NodesResult';
  /** List of vehicles in the requested page. */
  items: Array<PartialNode>;
  /** Total number of vehicles in the DB that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type NotificationEvent = {
  __typename: 'NotificationEvent';
  content: NotificationEventContent;
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  read?: Maybe<Scalars['DateTime']['output']>;
  updated: Scalars['DateTime']['output'];
  userId: Scalars['Int']['output'];
};

export type NotificationEventContent = BackorderSalesLineCancellationUpdateNotification | SupportTicketAssignmentChangeNotification | SupportTicketItemResolutionChangeNotification | SupportTicketNewDocumentNotification | SupportTicketNewInternalNoteNotification | SupportTicketNewMessageNotification | SupportTicketStatusChangeNotification | SupportTicketTypeChangeNotification;

export type NotificationEventContentFilter = {
  backorder?: InputMaybe<BackorderSalesLineCancellationUpdateNotificationFilters>;
  supportTicket?: InputMaybe<SupportTicketNotificationFilters>;
};

export enum NotificationEventContentType {
  BackorderSalesLineCancellationUpdateNotification = 'BackorderSalesLineCancellationUpdateNotification',
  SupportTicketAssignmentChangeNotification = 'SupportTicketAssignmentChangeNotification',
  SupportTicketItemResolutionChangeNotification = 'SupportTicketItemResolutionChangeNotification',
  SupportTicketNewDocumentNotification = 'SupportTicketNewDocumentNotification',
  SupportTicketNewInternalNoteNotification = 'SupportTicketNewInternalNoteNotification',
  SupportTicketNewMessageNotification = 'SupportTicketNewMessageNotification',
  SupportTicketStatusChangeNotification = 'SupportTicketStatusChangeNotification',
  SupportTicketTypeChangeNotification = 'SupportTicketTypeChangeNotification'
}

export type NotificationsResult = {
  __typename: 'NotificationsResult';
  items: Array<NotificationEvent>;
  total: Scalars['Int']['output'];
};

export type OnlineDealer = {
  __typename: 'OnlineDealer';
  brandName: Scalars['String']['output'];
  brandUrl: Scalars['String']['output'];
  customerNumber: Scalars['Int']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type OnlineDealerListResult = {
  __typename: 'OnlineDealerListResult';
  items: Array<CustomerOnlineDealer>;
  total: Scalars['Int']['output'];
};

export type OptionalLocalizedField = {
  __typename: 'OptionalLocalizedField';
  de?: Maybe<Scalars['String']['output']>;
  en?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
  it?: Maybe<Scalars['String']['output']>;
};

export type OptionalStockQuantity = {
  __typename: 'OptionalStockQuantity';
  location: Scalars['String']['output'];
  nextAvailability?: Maybe<GeneralNextAvailabilityStatus>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sku: Scalars['String']['output'];
  store: Scalars['ID']['output'];
};

export type Order = {
  __typename: 'Order';
  branch?: Maybe<Branch>;
  /** Cart ID that is associated with this order */
  cartId?: Maybe<Scalars['Int']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  deliveries: Array<Delivery>;
  deliveryAddress?: Maybe<OrderDeliveryAddress>;
  id: Scalars['ID']['output'];
  isDropshipment: Scalars['Boolean']['output'];
  items: Array<Maybe<OrderItem>>;
  /** Upstream order reference id */
  orderReference: Scalars['String']['output'];
  orderType: OrderType;
  origin: OrderOrigin;
  /** User supplied reference */
  reference?: Maybe<Scalars['String']['output']>;
  shippingMethod: Scalars['String']['output'];
  shippingMethodDetails?: Maybe<OrderShippingMethodDetails>;
  status: OrderStatus;
  updated: Scalars['String']['output'];
  /** Order ID from the upstream system, should be used for display purposes */
  upstreamOrderId: Scalars['String']['output'];
  /** Order number from the upstream system, should be used for display purposes */
  upstreamOrderNumber: Scalars['String']['output'];
};

export type OrderDeliveryAddress = {
  __typename: 'OrderDeliveryAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  companyName?: Maybe<Scalars['String']['output']>;
  country: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type OrderDocumentSourceResult = {
  __typename: 'OrderDocumentSourceResult';
  items: Array<SourceOrderDocument>;
  total: Scalars['Int']['output'];
};

export enum OrderDocumentType {
  CreditNote = 'creditNote',
  DeliveryNote = 'deliveryNote',
  Invoice = 'invoice'
}

export type OrderHistoryDeletedRecord = {
  __typename: 'OrderHistoryDeletedRecord';
  axTableNr: AxTable;
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deletedRecId: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modifiedDate: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamUpdateDate: Scalars['DateTime']['output'];
};

export type OrderHistoryDeletedRecordResult = {
  __typename: 'OrderHistoryDeletedRecordResult';
  items: Array<OrderHistoryDeletedRecord>;
  total: Scalars['Int']['output'];
};

export type OrderItem = {
  __typename: 'OrderItem';
  backorderInfo?: Maybe<Scalars['String']['output']>;
  /** Backorder information field, this can be null if we don't know this information. */
  backorderMarginDays?: Maybe<Scalars['Int']['output']>;
  /** Backorder information field, indicates whether this sales line can be released or not */
  canBeReleased: Scalars['Boolean']['output'];
  canceledQuantity: Scalars['Int']['output'];
  comment?: Maybe<Scalars['String']['output']>;
  confirmedQuantity: Scalars['Int']['output'];
  created: Scalars['String']['output'];
  /** Credit note ID if available */
  creditNoteId?: Maybe<Scalars['ID']['output']>;
  /**
   * Backorder information field, indicates the reasons due to which sales line cannot be mutated.
   * Indicates that sales line can be mutated if this list is empty.
   */
  decreaseIssues: Array<Scalars['String']['output']>;
  deliveredQuantity: Scalars['Int']['output'];
  /** Delivery note ID if available */
  deliveryNoteId?: Maybe<Scalars['ID']['output']>;
  /** Backorder information field, indicates whether customer needs to manually request shipment */
  deliveryOnRequest: Scalars['Boolean']['output'];
  /** Backorder information field, this can be null if we don't know this information. */
  futureDeliveries: Array<FutureDelivery>;
  id: Scalars['ID']['output'];
  /** Invoice ID if available */
  invoiceId?: Maybe<Scalars['ID']['output']>;
  order: Order;
  orderType: OrderType;
  pendingQuantity: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  product?: Maybe<ProductOrVariant>;
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  /** Backorder information field */
  requestedShippingDate?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  stale: Scalars['Boolean']['output'];
  /** Backorder information field */
  stockAvailableDelay: Scalars['Int']['output'];
  /** Backorder information field */
  stockAvailableLater: Scalars['Int']['output'];
  /** Backorder information field */
  stockAvailableNow: Scalars['Int']['output'];
  /** Backorder information field */
  stockAvailableSoon: Scalars['Int']['output'];
  /** Backorder information field, represents user */
  submittedBy?: Maybe<UserInfo>;
  updated: Scalars['String']['output'];
  /** Id in the upstream ERP system. */
  upstreamRecId?: Maybe<Scalars['String']['output']>;
};

export type OrderItemListResult = {
  __typename: 'OrderItemListResult';
  brandFilterValues: Array<Scalars['String']['output']>;
  items: Array<OrderItem>;
  total: Scalars['Int']['output'];
};

export enum OrderItemOrdertypeEnum {
  DealerOrder = 'dealerOrder',
  Dropshipment = 'dropshipment',
  Preorder = 'preorder'
}

export type OrderLineItem = {
  __typename: 'OrderLineItem';
  name: Scalars['String']['output'];
  netSubtotal: Scalars['String']['output'];
  quantity: Scalars['String']['output'];
  subtotal: Scalars['String']['output'];
  type: Scalars['String']['output'];
  unitPrice: Scalars['String']['output'];
  vat?: Maybe<Scalars['String']['output']>;
  vatAmount?: Maybe<Scalars['String']['output']>;
};

export type OrderListResult = {
  __typename: 'OrderListResult';
  items: Array<Order>;
  total: Scalars['Int']['output'];
};

export enum OrderOrdertypeEnum {
  DealerOrder = 'dealerOrder',
  Dropshipment = 'dropshipment',
  Preorder = 'preorder'
}

export enum OrderOrigin {
  Offline = 'offline',
  Online = 'online'
}

export type OrderShippingMethodDetails = {
  __typename: 'OrderShippingMethodDetails';
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Float']['output']>;
};

export type OrderStats = {
  __typename: 'OrderStats';
  timestamps: Array<Scalars['String']['output']>;
};

export enum OrderStatus {
  Canceled = 'canceled',
  Confirmed = 'confirmed',
  Delivered = 'delivered',
  Dispatched = 'dispatched',
  Failure = 'failure',
  Invoiced = 'invoiced',
  PaymentFailure = 'paymentFailure',
  PaymentPending = 'paymentPending',
  Submitted = 'submitted'
}

export type OrderSummaryStats = {
  __typename: 'OrderSummaryStats';
  count: Scalars['Int']['output'];
  countDelta: Scalars['Float']['output'];
  revenue: Scalars['Decimal']['output'];
  revenueDelta: Scalars['Float']['output'];
};

export enum OrderType {
  DealerOrder = 'dealerOrder',
  Dropshipment = 'dropshipment',
  Preorder = 'preorder'
}

export type OrdersResult = {
  __typename: 'OrdersResult';
  /** List of items in the requested page. */
  items: Array<CustomerOrder>;
  /** Total number of items that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type ParentProduct = {
  __typename: 'ParentProduct';
  categoryId?: Maybe<Scalars['Int']['output']>;
  groupId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  name: LocalizedField;
  siblingValues?: Maybe<Scalars['JSON']['output']>;
};

export type PartialMetaProduct = {
  __typename: 'PartialMetaProduct';
  categoryDE?: Maybe<Scalars['String']['output']>;
  categoryEN?: Maybe<Scalars['String']['output']>;
  categoryFR?: Maybe<Scalars['String']['output']>;
  categoryIT?: Maybe<Scalars['String']['output']>;
  categoryValue?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  distributionChannels?: Maybe<Array<Scalars['String']['output']>>;
  filesOutdated?: Maybe<Scalars['Boolean']['output']>;
  futureVisibilityCompanyDates?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  images?: Maybe<Scalars['JSON']['output']>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturingEndYear?: Maybe<Scalars['Int']['output']>;
  manufacturingStartYear?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  publicFutureVisibilityCompanyDates?: Maybe<Scalars['JSON']['output']>;
  replacementParts?: Maybe<Array<Scalars['String']['output']>>;
  searchKeywordsDE?: Maybe<Scalars['String']['output']>;
  searchKeywordsEN?: Maybe<Scalars['String']['output']>;
  searchKeywordsFR?: Maybe<Scalars['String']['output']>;
  searchKeywordsIT?: Maybe<Scalars['String']['output']>;
  skuList?: Maybe<Scalars['String']['output']>;
  sparePartDocument?: Maybe<Scalars['JSON']['output']>;
  sparePartSkuPrefix?: Maybe<Scalars['String']['output']>;
  specificationAttributes?: Maybe<Scalars['JSON']['output']>;
  stores?: Maybe<Array<Scalars['Int']['output']>>;
  typeDE?: Maybe<Scalars['String']['output']>;
  typeEN?: Maybe<Scalars['String']['output']>;
  typeFR?: Maybe<Scalars['String']['output']>;
  typeIT?: Maybe<Scalars['String']['output']>;
  typeValue?: Maybe<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  visibility?: Maybe<Array<Scalars['String']['output']>>;
};

export type PartialNode = {
  __typename: 'PartialNode';
  attributes?: Maybe<Scalars['JSON']['output']>;
  children: Array<PartialNode>;
  created?: Maybe<Scalars['DateTime']['output']>;
  dataDE?: Maybe<Scalars['JSON']['output']>;
  dataEN?: Maybe<Scalars['JSON']['output']>;
  dataFR?: Maybe<Scalars['JSON']['output']>;
  dataIT?: Maybe<Scalars['JSON']['output']>;
  filesOutdated?: Maybe<Scalars['Boolean']['output']>;
  formattedAttributesValues?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
  isCategory?: Maybe<Scalars['Boolean']['output']>;
  isFolder?: Maybe<Scalars['Boolean']['output']>;
  isRoot?: Maybe<Scalars['Boolean']['output']>;
  labelDE?: Maybe<Scalars['String']['output']>;
  labelEN?: Maybe<Scalars['String']['output']>;
  labelFR?: Maybe<Scalars['String']['output']>;
  labelIT?: Maybe<Scalars['String']['output']>;
  marked?: Maybe<Scalars['Boolean']['output']>;
  mediaFileIds?: Maybe<Array<Scalars['Int']['output']>>;
  /** Stereotype of the node */
  nodeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  outdated?: Maybe<Scalars['Boolean']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  productTreeId?: Maybe<Scalars['Int']['output']>;
  sizeSpecificationDE?: Maybe<Scalars['String']['output']>;
  sizeSpecificationEN?: Maybe<Scalars['String']['output']>;
  sizeSpecificationFR?: Maybe<Scalars['String']['output']>;
  sizeSpecificationIT?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  unmapped?: Maybe<Scalars['Boolean']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type PartialProduct = {
  __typename: 'PartialProduct';
  attributes?: Maybe<ProductAttributes>;
  availability?: Maybe<Scalars['JSON']['output']>;
  availableInFuture?: Maybe<Scalars['Boolean']['output']>;
  barcode?: Maybe<Scalars['String']['output']>;
  catalogAttributes?: Maybe<Scalars['JSON']['output']>;
  catalogDescription?: Maybe<LocalizedField>;
  categoryId?: Maybe<Scalars['Int']['output']>;
  categoryName?: Maybe<LocalizedField>;
  chPreorder?: Maybe<Scalars['Boolean']['output']>;
  childBarcodeList?: Maybe<Scalars['String']['output']>;
  childExternalSkuList?: Maybe<Scalars['String']['output']>;
  childSkuList?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<PartialProduct>>;
  color?: Maybe<Scalars['String']['output']>;
  commentDE?: Maybe<Scalars['String']['output']>;
  commentEN?: Maybe<Scalars['String']['output']>;
  commentFR?: Maybe<Scalars['String']['output']>;
  commentIT?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  customSortIndex?: Maybe<Scalars['Int']['output']>;
  dealerOnlyCH?: Maybe<Scalars['Boolean']['output']>;
  dealerOnlyEU?: Maybe<Scalars['Boolean']['output']>;
  dealerOnlyIC?: Maybe<Scalars['Boolean']['output']>;
  distributionChannels?: Maybe<Array<Scalars['String']['output']>>;
  documents?: Maybe<LocalizedProductDocumentCollection>;
  dropshipmentIssue?: Maybe<Scalars['String']['output']>;
  excludedFromProductSearch?: Maybe<Scalars['Boolean']['output']>;
  externalSku?: Maybe<Scalars['String']['output']>;
  featuredAttributeDE?: Maybe<Scalars['String']['output']>;
  featuredAttributeEN?: Maybe<Scalars['String']['output']>;
  featuredAttributeFR?: Maybe<Scalars['String']['output']>;
  featuredAttributeIT?: Maybe<Scalars['String']['output']>;
  filterValues?: Maybe<Scalars['JSON']['output']>;
  futureVisibilityCompanyDates?: Maybe<Scalars['JSON']['output']>;
  futureVisibilityCompanyIds?: Maybe<Array<Scalars['Int']['output']>>;
  groupId?: Maybe<Scalars['Int']['output']>;
  icPreorder?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  legacySkuList?: Maybe<Scalars['String']['output']>;
  mainNameDE?: Maybe<Scalars['String']['output']>;
  mainNameEN?: Maybe<Scalars['String']['output']>;
  mainNameFR?: Maybe<Scalars['String']['output']>;
  mainNameIT?: Maybe<Scalars['String']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  manufacturerLogo?: Maybe<Scalars['String']['output']>;
  metaAttributes?: Maybe<Scalars['JSON']['output']>;
  new?: Maybe<Scalars['Boolean']['output']>;
  newCH?: Maybe<Scalars['Boolean']['output']>;
  newNonCH?: Maybe<Scalars['Boolean']['output']>;
  nodeParentId?: Maybe<Scalars['Int']['output']>;
  nutritionalDocuments?: Maybe<LocalizedProductDocumentCollection>;
  parentId?: Maybe<Scalars['Int']['output']>;
  pictograms?: Maybe<Array<ProductPictogramItem>>;
  preorderDeliveryDateDE?: Maybe<Scalars['String']['output']>;
  preorderDeliveryDateEN?: Maybe<Scalars['String']['output']>;
  preorderDeliveryDateFR?: Maybe<Scalars['String']['output']>;
  preorderDeliveryDateIT?: Maybe<Scalars['String']['output']>;
  productNumber?: Maybe<Scalars['String']['output']>;
  publicFutureVisibilityCompanyDates?: Maybe<Scalars['JSON']['output']>;
  publicFutureVisibilityCompanyIds?: Maybe<Array<Scalars['Int']['output']>>;
  retailPrice?: Maybe<Scalars['JSON']['output']>;
  retailPriceCH?: Maybe<Scalars['Decimal']['output']>;
  retailPriceEU?: Maybe<Scalars['Decimal']['output']>;
  retailPriceIC?: Maybe<Scalars['Decimal']['output']>;
  saleCH?: Maybe<Scalars['Boolean']['output']>;
  saleIC?: Maybe<Scalars['Boolean']['output']>;
  saleNonCH?: Maybe<Scalars['Boolean']['output']>;
  searchKeywordsDE?: Maybe<Scalars['String']['output']>;
  searchKeywordsEN?: Maybe<Scalars['String']['output']>;
  searchKeywordsFR?: Maybe<Scalars['String']['output']>;
  searchKeywordsIT?: Maybe<Scalars['String']['output']>;
  siblingAttributes?: Maybe<Scalars['JSON']['output']>;
  siblingValues?: Maybe<Scalars['JSON']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  sizeSpecificationDE?: Maybe<Scalars['String']['output']>;
  sizeSpecificationEN?: Maybe<Scalars['String']['output']>;
  sizeSpecificationFR?: Maybe<Scalars['String']['output']>;
  sizeSpecificationIT?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  specificationAttributes?: Maybe<Scalars['JSON']['output']>;
  startingPriceCH?: Maybe<Scalars['Boolean']['output']>;
  startingPriceNonCH?: Maybe<Scalars['Boolean']['output']>;
  stores?: Maybe<Array<Scalars['Int']['output']>>;
  thumbnail?: Maybe<Scalars['String']['output']>;
  tileAttributes?: Maybe<Scalars['JSON']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
  variationAttributes?: Maybe<Scalars['JSON']['output']>;
  variationIndex?: Maybe<Scalars['Int']['output']>;
  variationValues?: Maybe<Scalars['JSON']['output']>;
  videos?: Maybe<LocalizedProductVideoCollection>;
  visibility?: Maybe<Array<Scalars['String']['output']>>;
  wildcardKeywordsDE?: Maybe<Scalars['String']['output']>;
  wildcardKeywordsEN?: Maybe<Scalars['String']['output']>;
  wildcardKeywordsFR?: Maybe<Scalars['String']['output']>;
  wildcardKeywordsIT?: Maybe<Scalars['String']['output']>;
};

export type PartialVehicle = {
  __typename: 'PartialVehicle';
  ccm?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  /** @deprecated Use ratedPower instead */
  kw?: Maybe<Scalars['Int']['output']>;
  /**
   * Query linked products for parent [vehicles query](VehiclesQuery).
   *
   * This query is resolved by [`PartialVehicle::get_linked_products`]
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if [linked_products](PartialVehicle::linked_products)
   * field is unable to be deserialized as [`LinkedProducts`] by serde.
   */
  linkedProducts: Array<LinkedProducts>;
  manufacturerName?: Maybe<Scalars['String']['output']>;
  manufacturingEndYear?: Maybe<Scalars['Int']['output']>;
  manufacturingStartYear?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  nodeId?: Maybe<Scalars['Int']['output']>;
  ratedPower?: Maybe<Scalars['String']['output']>;
  /**
   * Query related products for parent [vehicles query](VehiclesQuery).
   *
   * This query is resolved by [`PartialVehicle::resolve_related_products`]
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if [linked_products](PartialVehicle::linked_products)
   * field is unable to be deserialized as [`RelatedProducts`] by serde.
   */
  relatedProducts: Array<RelatedProducts>;
  segment?: Maybe<Scalars['String']['output']>;
  tireApplicationRanges?: Maybe<Array<Scalars['String']['output']>>;
  tireCodes?: Maybe<Array<Scalars['String']['output']>>;
  /**
   * Query tire SKUs for parent [vehicles query](VehiclesQuery).
   *
   * This query is resolved by [`PartialVehicle::resolve_tires`]
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Panics
   *
   * Panics if any entry in [linked_products](PartialVehicle::linked_products) field of parent contains
   * invalid SKU that cannot be converted to string by serde.
   */
  tires: Array<Scalars['String']['output']>;
  /** @deprecated Use typeAcceptanceList instead */
  typeAcceptance?: Maybe<Scalars['String']['output']>;
  /** Query type acceptance list for parent [vehicles query](VehiclesQuery). */
  typeAcceptanceList: Array<Scalars['String']['output']>;
  /** @deprecated Use typeNumbers instead */
  typeNumber?: Maybe<Scalars['String']['output']>;
  /** Query type numbers for parent [vehicles query](VehiclesQuery). */
  typeNumbers: Array<Scalars['String']['output']>;
  updated?: Maybe<Scalars['DateTime']['output']>;
};

export type PasswordAnalysis = {
  __typename: 'PasswordAnalysis';
  isCommon: Scalars['Boolean']['output'];
  length: Scalars['Int']['output'];
  score: Scalars['Float']['output'];
};

export type PasswordResetToken = {
  __typename: 'PasswordResetToken';
  active: Scalars['Boolean']['output'];
  expires: Scalars['String']['output'];
  token: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type PaymentTerm = {
  __typename: 'PaymentTerm';
  active: Scalars['Boolean']['output'];
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  unavailableFor: Scalars['JSON']['output'];
};

export type PaymentTermEntity = {
  __typename: 'PaymentTermEntity';
  active: Scalars['Boolean']['output'];
  availableFor: Array<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  nameDe: Scalars['String']['output'];
  nameEn: Scalars['String']['output'];
  nameFr: Scalars['String']['output'];
  nameIt: Scalars['String']['output'];
  unavailableFor: Scalars['JSON']['output'];
  updated: Scalars['DateTime']['output'];
};

export type PendingOrderedQuantity = {
  __typename: 'PendingOrderedQuantity';
  pendingQuantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
};

export enum PermissionFilter {
  CompanyManager = 'CompanyManager',
  DataTeamMember = 'DataTeamMember',
  SalesRepresentative = 'SalesRepresentative',
  TicketManager = 'TicketManager'
}

export type PermissionInput = {
  action: Scalars['String']['input'];
  resource: Scalars['String']['input'];
};

export type Preorder = {
  __typename: 'Preorder';
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  defaultDeliveryDate?: Maybe<Scalars['String']['output']>;
  deliveryAddress: OrderDeliveryAddress;
  deliveryDates: Array<PreorderDeliveryDate>;
  discountLevel: PreorderDiscountTier;
  generalDealerPriceTotal?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalNote?: Maybe<Scalars['String']['output']>;
  items: Array<PreorderItem>;
  reference?: Maybe<Scalars['String']['output']>;
  shippingMethodId?: Maybe<Scalars['String']['output']>;
  status: OrderStatus;
  updated: Scalars['String']['output'];
  upstreamOrderNumber: Scalars['String']['output'];
};

export type PreorderBrandDiscount = {
  __typename: 'PreorderBrandDiscount';
  brandName: Scalars['String']['output'];
  discountPercentage: Scalars['Float']['output'];
};

export type PreorderCartBrandBreakdown = {
  __typename: 'PreorderCartBrandBreakdown';
  brandName: Scalars['String']['output'];
  generalDealerPrice: Scalars['Float']['output'];
};

export type PreorderDeliveryDate = {
  __typename: 'PreorderDeliveryDate';
  brandName?: Maybe<Scalars['String']['output']>;
  deliveryDate: Scalars['String']['output'];
  productGroupId?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  sortIndex?: Maybe<Scalars['Int']['output']>;
  variantGroupId?: Maybe<Scalars['String']['output']>;
};

export type PreorderDeliveryDateInput = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  deliveryDate: Scalars['String']['input'];
  productGroupId?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sortIndex?: InputMaybe<Scalars['Int']['input']>;
  variantGroupId?: InputMaybe<Scalars['String']['input']>;
};

export enum PreorderDiscountTier {
  Gold = 'gold',
  None = 'none',
  Platinum = 'platinum',
  Silver = 'silver'
}

export type PreorderDiscounts = {
  __typename: 'PreorderDiscounts';
  brandDiscounts: Array<PreorderBrandDiscount>;
  productPrices: Array<PreorderProductPriceOverride>;
};

export type PreorderItem = {
  __typename: 'PreorderItem';
  prices: UserProductPrice;
  product?: Maybe<ProductOrVariant>;
  productName?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
};

export type PreorderItemProduct = {
  __typename: 'PreorderItemProduct';
  groupId?: Maybe<Scalars['Int']['output']>;
  manufacturer: Scalars['String']['output'];
  parentId?: Maybe<Scalars['Int']['output']>;
  sku: Scalars['String']['output'];
};

export type PreorderProductPriceOverride = {
  __typename: 'PreorderProductPriceOverride';
  price: Scalars['Float']['output'];
  sku: Scalars['String']['output'];
};

export type PreorderSeason = {
  __typename: 'PreorderSeason';
  /** URL slug for StoryBlok CMS folder for this season */
  cmsRootFolder?: Maybe<Scalars['String']['output']>;
  /** End of permitted delivery dates */
  deliveryEnd: Scalars['String']['output'];
  /** Start of permitted delivery dates */
  deliveryStart: Scalars['String']['output'];
  disallowNonPreorderProducts: Scalars['Boolean']['output'];
  /** @deprecated Use customerPreorderSummary query instead */
  discountTier?: Maybe<Scalars['String']['output']>;
  discountTiers: Array<DiscountTier>;
  end: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  /** URL of the logo image for this preorder */
  logoUrl?: Maybe<Scalars['String']['output']>;
  nameDE: Scalars['String']['output'];
  nameEN: Scalars['String']['output'];
  nameFR: Scalars['String']['output'];
  nameIT: Scalars['String']['output'];
  /** Preorders made in the season, admin only */
  preorders: Array<Preorder>;
  start: Scalars['String']['output'];
  storeCode: Scalars['ID']['output'];
  /** @deprecated Use customerPreorderSummary query instead */
  totalValue?: Maybe<Scalars['Float']['output']>;
};

export type PreorderSeasonDiscountTier = {
  __typename: 'PreorderSeasonDiscountTier';
  nameDe: Scalars['String']['output'];
  nameEn: Scalars['String']['output'];
  nameFr: Scalars['String']['output'];
  nameIt: Scalars['String']['output'];
  threshold: Scalars['String']['output'];
  tier: Scalars['String']['output'];
};

export type PreorderSeasonDiscountTierInput = {
  nameDe: Scalars['String']['input'];
  nameEn: Scalars['String']['input'];
  nameFr: Scalars['String']['input'];
  nameIt: Scalars['String']['input'];
  threshold: Scalars['String']['input'];
  tier: Scalars['String']['input'];
};

export type PreorderSeasonPreorderDiscount = {
  __typename: 'PreorderSeasonPreorderDiscount';
  discount?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['String']['output']>;
  productGroupId?: Maybe<Scalars['String']['output']>;
  rebateCode?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  variantGroupId?: Maybe<Scalars['String']['output']>;
};

export type PreorderSeasonPreorderDiscountInput = {
  discount?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['String']['input']>;
  productGroupId?: InputMaybe<Scalars['String']['input']>;
  rebateCode?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  variantGroupId?: InputMaybe<Scalars['String']['input']>;
};

export type PreorderSeasonResult = {
  __typename: 'PreorderSeasonResult';
  items: Array<StorePreorderSeason>;
  total: Scalars['Int']['output'];
};

export type PreorderSeasonTieredDiscount = {
  __typename: 'PreorderSeasonTieredDiscount';
  discount: Scalars['String']['output'];
  rebateCode: Scalars['String']['output'];
  tier: Scalars['String']['output'];
};

export type PreorderSeasonTieredDiscountInput = {
  discount: Scalars['String']['input'];
  rebateCode: Scalars['String']['input'];
  tier: Scalars['String']['input'];
};

export type PreordersResult = {
  __typename: 'PreordersResult';
  /** List of items in the requested page. */
  items: Array<CustomerPreorder>;
  /** Total number of items that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type PriceCalculationExplanation = {
  __typename: 'PriceCalculationExplanation';
  steps: Array<Scalars['String']['output']>;
};

export type Product = {
  __typename: 'Product';
  accessories: Array<ProductOrVariant>;
  additionalCharges: Array<ProductAdditionalCharge>;
  alternativeProducts: Array<ProductOrVariant>;
  /** Barcode in case of a simple product */
  barcode?: Maybe<Scalars['String']['output']>;
  /** Category hierarchy list */
  categories: Array<Maybe<ProductCategory>>;
  /** Colors of the product */
  colors?: Maybe<Array<Maybe<ProductColor>>>;
  /** Product comment in selected language */
  comment?: Maybe<Scalars['String']['output']>;
  /** Created timestamp, debug only, do not use on frontend. */
  created?: Maybe<Scalars['String']['output']>;
  /** Customer price for this variant */
  customerPrice?: Maybe<UserProductPrice>;
  /** Product description in selected language */
  description?: Maybe<Scalars['String']['output']>;
  /** Downloadable documents */
  documents?: Maybe<Array<Maybe<ProductDocument>>>;
  /** Product external sku in case of a simple product */
  externalSku?: Maybe<Scalars['String']['output']>;
  /** Featured product attribute subtitle in selected language */
  featuredAttribute?: Maybe<Scalars['String']['output']>;
  /** Product group ID */
  groupId?: Maybe<Scalars['ID']['output']>;
  hazardStatements?: Maybe<Scalars['String']['output']>;
  /** Product ID */
  id: Scalars['ID']['output'];
  /** Cache key */
  key: Scalars['String']['output'];
  /** Legacy product SKUs */
  legacySkuList: Array<Scalars['String']['output']>;
  /** Manufacturer name */
  manufacturer?: Maybe<Scalars['String']['output']>;
  /** Manufacturer logo URL */
  manufacturerLogo?: Maybe<Scalars['String']['output']>;
  /** Produce media list that usually contains images */
  media: Array<Maybe<ProductMediaItem>>;
  /** Product name in selected language */
  name: Scalars['String']['output'];
  /** Flag that tells if a product is considered new in the selected store */
  new: Scalars['Boolean']['output'];
  /** Flag that tells if a product is on sale in the selected store */
  onSale: Scalars['Boolean']['output'];
  pairProductId?: Maybe<Scalars['String']['output']>;
  /** Pictograms */
  pictograms?: Maybe<Array<Maybe<ProductPictogram>>>;
  /** Flag that tells if a product is a preorder product */
  preorder: Scalars['Boolean']['output'];
  /** Optional localized preorder deliver date. */
  preorderDeliveryDate?: Maybe<Scalars['String']['output']>;
  /**
   * Rebate code for product that can be used for discounts.
   *
   * Not all products have rebate codes.
   *
   * Not all variations have the same rebate codes.
   */
  rebateCode?: Maybe<Scalars['String']['output']>;
  relatedProducts: Array<ProductOrVariant>;
  replacementProducts: Array<ProductOrVariant>;
  /** Product retail price in case of a simple product, starting / variant price otherwise */
  retailPrice?: Maybe<Scalars['Float']['output']>;
  safetyDatasheetUrl?: Maybe<Scalars['String']['output']>;
  /** Attributes that differentiate this product from its siblings. */
  siblingAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  siblings?: Maybe<Array<Maybe<Product>>>;
  /** Optional HTML content that describes how the sizing is specified */
  sizeSpecification?: Maybe<Scalars['String']['output']>;
  /** Product sku in case of a simple product */
  sku?: Maybe<Scalars['String']['output']>;
  spareParts: Array<ProductOrVariant>;
  /** Name and value pairs that define the product's specifications */
  specificationAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  /** Tells if the price is a starting price or all variants have the same price */
  startingPrice?: Maybe<Scalars['Boolean']['output']>;
  /** Product summary in selected language */
  summary?: Maybe<Scalars['String']['output']>;
  /** Main product thumbnail image */
  thumbnail?: Maybe<Scalars['String']['output']>;
  /** Name and value pairs highlighted on the product tile */
  tileAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  /** Updated timestamp, debug only, do not use on frontend. */
  updated?: Maybe<Scalars['String']['output']>;
  /** Very expensive query, request it separately */
  variantPricing?: Maybe<VariantCustomerPricing>;
  /** List of purchasable product variants */
  variants: Array<Maybe<ProductVariant>>;
  /** Sort index for variation */
  variationIndex?: Maybe<Scalars['Int']['output']>;
  /** Vehicle comment if vehicleId filter is present */
  vehicleComment?: Maybe<Scalars['String']['output']>;
  vehicles?: Maybe<Array<Maybe<Motorcycle>>>;
  /** Videos, usually youtube URLs */
  videos?: Maybe<Array<Maybe<ProductVideo>>>;
};


export type ProductCustomerPriceArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
};


export type ProductVariantPricingArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartType: CartType;
};

export type ProductAdditionalCharge = {
  __typename: 'ProductAdditionalCharge';
  /** Charge code string, identifies the extra charge */
  chargeCode: Scalars['String']['output'];
  /** Charge value without VAT */
  chargeExcl: Scalars['String']['output'];
  /** Charge value with VAT */
  chargeIncl: Scalars['String']['output'];
  /** Describes the extra charge in */
  description: Scalars['String']['output'];
  /** Unique identifier */
  id: Scalars['ID']['output'];
  /** Product SKU */
  sku: Scalars['String']['output'];
};

export type ProductAttribute = {
  __typename: 'ProductAttribute';
  /** Localized name of the attribute */
  name: Scalars['String']['output'];
  /** Localized value */
  value: Scalars['String']['output'];
};

export type ProductAttributes = {
  __typename: 'ProductAttributes';
  bestAvailability?: Maybe<Scalars['JSONObject']['output']>;
  saleStatus?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProductBestAvailabilityFilter = {
  /** Desired stock location. */
  stockLocations: Array<Scalars['String']['input']>;
  /** Available in calendar days. */
  value: Scalars['Int']['input'];
};

export type ProductCategory = {
  __typename: 'ProductCategory';
  /**
   * Filters defined for this category
   * @deprecated This field should be picked up from the category query
   */
  filters: Array<Maybe<CategoryFilter>>;
  /**
   * Tells if motorcycle filter is valid for the category
   * @deprecated This field should be picked up from the category query
   */
  hasMotorcycleFilter: Scalars['Boolean']['output'];
  /**
   * Tells if category is hidden during navigation
   * @deprecated This field should be picked up from the category query
   */
  hiddenInTree: Scalars['Boolean']['output'];
  /** Category ID */
  id: Scalars['ID']['output'];
  /**
   * Cache key
   * @deprecated This field should be picked up from the category query
   */
  key: Scalars['String']['output'];
  /**
   * Category name in selected language
   * @deprecated This field should be picked up from the category query
   */
  name: Scalars['String']['output'];
  /**
   * ID of the parent category
   * @deprecated This field should be picked up from the category query
   */
  parentId?: Maybe<Scalars['String']['output']>;
  /**
   * List of ancestors from the root category all the way to inclusive this one
   * @deprecated This field should be picked up from the category query
   */
  path: Array<Category>;
};

export type ProductClass = {
  __typename: 'ProductClass';
  approvalIsRequiredForPrint: Scalars['Boolean']['output'];
  eligibilityWindow?: Maybe<Scalars['Int']['output']>;
  imageOfProductRequired?: Maybe<Scalars['Boolean']['output']>;
  imageOfSerialNumberRequired?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  productClassId: Scalars['Int']['output'];
};

export type ProductColor = {
  __typename: 'ProductColor';
  name: Scalars['String']['output'];
};

export type ProductDocument = {
  __typename: 'ProductDocument';
  name: Scalars['String']['output'];
  previewUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProductDocumentItem = {
  __typename: 'ProductDocumentItem';
  id: Scalars['Int']['output'];
  index: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ProductFilterValue = {
  __typename: 'ProductFilterValue';
  total: Scalars['Int']['output'];
  value: Scalars['String']['output'];
  valueLabel?: Maybe<Scalars['String']['output']>;
};

/** Represents image renditions returned in the [images](ProductItem::images) query. */
export type ProductImageRenditions = {
  __typename: 'ProductImageRenditions';
  rendition176Px: Scalars['String']['output'];
  rendition176X176: Scalars['String']['output'];
  rendition352Px: Scalars['String']['output'];
  rendition352X352: Scalars['String']['output'];
  rendition704Px: Scalars['String']['output'];
  rendition704X704: Scalars['String']['output'];
  rendition1080P: Scalars['String']['output'];
};

/** Represents image data present in the parent [`ProductItem`] */
export type ProductImages = {
  __typename: 'ProductImages';
  index: Scalars['Int']['output'];
  /** Image renditions computed based on the source image. */
  renditions?: Maybe<ProductImageRenditions>;
};

export type ProductItem = {
  __typename: 'ProductItem';
  /**
   * Query accessories for parent [`ProductItem`]. The result of this query is computed from
   * [`ProductItem::accessories`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if
   * - The ID list contains invalid IDs that cannot be parsed as i64
   * - The DB query that resolves SKU data fails
   */
  accessories: Array<Scalars['String']['output']>;
  /**
   * Query alternative products for parent [`ProductItem`]. The result of this query is computed from
   * [`ProductItem::alternative_products`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if
   * - The ID list contains invalid IDs that cannot be parsed as i64
   * - The DB query that resolves SKU data fails
   */
  alternativeProducts: Array<Scalars['String']['output']>;
  attributes: ProductAttributes;
  barcode?: Maybe<Scalars['String']['output']>;
  breadcrumbs: Array<Scalars['Int']['output']>;
  /** Custom format that should only be used for CSV generation. */
  breadcrumbsCustomFormat1: LocalizedField;
  catalogAttributeList: Array<CatalogAttributeListItem>;
  catalogAttributes: Scalars['JSON']['output'];
  catalogDescription: LocalizedField;
  categoryId?: Maybe<Scalars['Int']['output']>;
  categoryName: LocalizedField;
  /**
   * Query localized description for [`ProductItem`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   */
  description: LocalizedField;
  documents?: Maybe<LocalizedProductDocumentCollection>;
  externalSku?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  /**
   * Query product image renditions for parent [`ProductItem`]. The result of this query is computed from
   * [`ProductItem::media`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if deserializing [`ProductImages`] from parent
   * [`ProductItem`] fails.
   */
  images: Array<ProductImages>;
  manufacturer: Scalars['String']['output'];
  /** URL for brand logo. */
  manufacturerLogo: Scalars['String']['output'];
  /**
   * Query localized name for [`ProductItem`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   */
  name: LocalizedField;
  nodeParentId: Scalars['Int']['output'];
  nutritionalDocuments?: Maybe<LocalizedProductDocumentCollection>;
  /**
   * Query parent product for [`ProductItem`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if
   * - The DB query that resolves parent product data fails
   */
  parent?: Maybe<ParentProduct>;
  parentId?: Maybe<Scalars['Int']['output']>;
  pictograms?: Maybe<Array<ProductPictogramItem>>;
  productNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Query additional products for parent [`ProductItem`]. The result of this query is computed from
   * [`ProductItem::related_products`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if
   * - The ID list contains invalid IDs that cannot be parsed as i64
   * - The DB query that resolves SKU data fails
   */
  relatedProducts: Array<Scalars['String']['output']>;
  /**
   * Query localized truncated (short) name for [`ProductItem`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   */
  shortName: LocalizedField;
  sku: Scalars['String']['output'];
  /**
   * Query replacement products for parent [`ProductItem`]. The result of this query is computed from
   * [`ProductItem::spare_parts`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Errors
   *
   * This function will return an error if
   * - The ID list contains invalid IDs that cannot be parsed as i64
   * - The DB query that resolves SKU data fails
   */
  spareParts: Array<Scalars['String']['output']>;
  specificationAttributes?: Maybe<Scalars['JSON']['output']>;
  stores: Array<Scalars['Int']['output']>;
  /**
   * Query localized summary for [`ProductItem`].
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   */
  summary: LocalizedField;
  treeName: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  variationValues?: Maybe<Scalars['JSON']['output']>;
  videos?: Maybe<LocalizedProductVideoCollection>;
};

export type ProductItemsResult = {
  __typename: 'ProductItemsResult';
  /** Opaque cursor that can be passed to subsequent queries to ensure result consistency. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** List of product items in the requested page. */
  items: Array<ProductItem>;
  /** Total number of product items in the DB that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type ProductListFilter = {
  name: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type ProductListResultSet = {
  __typename: 'ProductListResultSet';
  items: Array<Maybe<Product>>;
  total: Scalars['Int']['output'];
};

export type ProductMedia = {
  __typename: 'ProductMedia';
  renditionUrl: Scalars['String']['output'];
  /** Image renditions computed based on the source image. */
  renditions?: Maybe<ProductImageRenditions>;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** Product media items, usually images */
export type ProductMediaItem = {
  __typename: 'ProductMediaItem';
  /** 176px rendition */
  rendition176pxUrl: Scalars['String']['output'];
  /** 352px rendition */
  rendition352pxUrl: Scalars['String']['output'];
  /** 704px rendition */
  rendition704pxUrl: Scalars['String']['output'];
  /** 1080p rendition */
  rendition1080pUrl: Scalars['String']['output'];
  /** Generic rendition image, where we have a * instead of the rendition name. */
  renditionUrl: Scalars['String']['output'];
  /** Media item type */
  type: Scalars['String']['output'];
  /** Media item URL */
  url: Scalars['String']['output'];
};

export type ProductMetaAttributeValueItem = {
  __typename: 'ProductMetaAttributeValueItem';
  field: Scalars['String']['output'];
  names?: Maybe<Array<Array<Scalars['String']['output']>>>;
  values: Array<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProductOrVariant = Product | ProductVariant;

export type ProductOrderRestriction = {
  __typename: 'ProductOrderRestriction';
  restrictionId?: Maybe<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  store: Scalars['ID']['output'];
};

export type ProductOrderRestrictionMessage = {
  __typename: 'ProductOrderRestrictionMessage';
  id: Scalars['ID']['output'];
  messageDE?: Maybe<Scalars['String']['output']>;
  messageEN?: Maybe<Scalars['String']['output']>;
  messageFR?: Maybe<Scalars['String']['output']>;
  messageIT?: Maybe<Scalars['String']['output']>;
};

export type ProductOrderRestrictionResult = {
  __typename: 'ProductOrderRestrictionResult';
  items: Array<CustomerProductOrderRestriction>;
  total: Scalars['Int']['output'];
};

export type ProductPictogram = {
  __typename: 'ProductPictogram';
  information?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  renditionUrl?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type ProductPictogramItem = {
  __typename: 'ProductPictogramItem';
  index: Scalars['Int']['output'];
  information?: Maybe<LocalizedField>;
  label: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProductPriceRange = {
  __typename: 'ProductPriceRange';
  max: Scalars['Float']['output'];
  min: Scalars['Float']['output'];
};

export type ProductPricing = {
  __typename: 'ProductPricing';
  barcode?: Maybe<Scalars['String']['output']>;
  brand: Scalars['String']['output'];
  calculationFlag: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  inactive: Scalars['Boolean']['output'];
  itemPermission: Scalars['String']['output'];
  itemRabateCode: Scalars['String']['output'];
  nameDe: Scalars['String']['output'];
  nameEn: Scalars['String']['output'];
  nameFr: Scalars['String']['output'];
  nameIt: Scalars['String']['output'];
  netPriceExcl: Scalars['Decimal']['output'];
  replacementItemNumber: Scalars['String']['output'];
  rrpExcl?: Maybe<Scalars['Decimal']['output']>;
  rrpIncl: Scalars['Decimal']['output'];
  salesStatus: Scalars['String']['output'];
  salesUnit: Scalars['String']['output'];
  service: Scalars['Boolean']['output'];
  sku: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  vat: Scalars['String']['output'];
};

export type ProductPricingModelSort = {
  direction: SortDirection;
  field: ProductPricingSortField;
};

export type ProductPricingResult = {
  __typename: 'ProductPricingResult';
  items: Array<ProductPricing>;
  total: Scalars['Int']['output'];
};

export enum ProductPricingSortField {
  Created = 'created',
  Id = 'id',
  NetPriceExcl = 'netPriceExcl',
  RrpExcl = 'rrpExcl',
  RrpIncl = 'rrpIncl',
  Sku = 'sku',
  Updated = 'updated',
  Vat = 'vat'
}

export type ProductRestrictionValueEntry = {
  __typename: 'ProductRestrictionValueEntry';
  field: Scalars['String']['output'];
  names?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  values: Array<Array<Scalars['String']['output']>>;
};

export enum ProductSortField {
  BrandName = 'brandName',
  Custom = 'custom',
  Name = 'name',
  New = 'new',
  Price = 'price',
  Rank = 'rank',
  RetailPrice = 'retailPrice',
  TireCompatibility = 'tireCompatibility'
}

export enum ProductState {
  IsNew = 'isNew',
  OnSale = 'onSale'
}

export type ProductSummary = {
  __typename: 'ProductSummary';
  id: Scalars['Int']['output'];
  manufacturer: Scalars['String']['output'];
  manufacturerLogo?: Maybe<Scalars['String']['output']>;
  media: Array<ProductMedia>;
  name: LocalizedField;
  parentId?: Maybe<Scalars['Int']['output']>;
  sku: Scalars['String']['output'];
};

/** Product variant */
export type ProductVariant = {
  __typename: 'ProductVariant';
  additionalCharges: Array<ProductAdditionalCharge>;
  /** Variant Barcode */
  barcode?: Maybe<Scalars['String']['output']>;
  /** Colors of the product */
  colors?: Maybe<Array<Maybe<ProductColor>>>;
  /** Created timestamp, debug only, do not use on frontend. */
  created?: Maybe<Scalars['String']['output']>;
  /** Customer price for this variant */
  customerPrice?: Maybe<UserProductPrice>;
  /** Product description in selected language */
  description?: Maybe<Scalars['String']['output']>;
  /** Variant external sku in case of a simple product */
  externalSku?: Maybe<Scalars['String']['output']>;
  /** Product group ID */
  groupId?: Maybe<Scalars['ID']['output']>;
  /** Product ID */
  id: Scalars['ID']['output'];
  /** Cache key */
  key: Scalars['String']['output'];
  /** Legacy product SKUs */
  legacySkuList: Array<Scalars['String']['output']>;
  /** Variant name */
  name: Scalars['String']['output'];
  /** Flag that tells if a product is considered new in the selected store */
  new: Scalars['Boolean']['output'];
  /** Parent product of this variant. */
  parent: Product;
  /** Optional localized preorder deliver date. */
  preorderDeliveryDate?: Maybe<Scalars['String']['output']>;
  /**
   * Rebate code for product that can be used for discounts.
   *
   * Not all products have rebate codes.
   *
   * Not all variations have the same rebate codes.
   */
  rebateCode?: Maybe<Scalars['String']['output']>;
  replacementProducts: Array<ProductOrVariant>;
  /** Retail price for this variant */
  retailPrice?: Maybe<Scalars['Float']['output']>;
  /** Size attribute for this variant */
  size?: Maybe<Scalars['String']['output']>;
  /** Optional HTML content that describes how the sizing is specified */
  sizeSpecification?: Maybe<Scalars['String']['output']>;
  /** Variant SKU, primary key */
  sku: Scalars['String']['output'];
  /** Name and value pairs that define the product's specifications */
  specificationAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  /** Product thumbnail image */
  thumbnail?: Maybe<Scalars['String']['output']>;
  /** Updated timestamp, debug only, do not use on frontend. */
  updated?: Maybe<Scalars['String']['output']>;
  /** Attributes that differentiate this product from the other variations. */
  variationAttributes?: Maybe<Array<Maybe<ProductAttribute>>>;
  /** Sort index for variation */
  variationIndex?: Maybe<Scalars['Int']['output']>;
};


/** Product variant */
export type ProductVariantCustomerPriceArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
};

export type ProductVideo = {
  __typename: 'ProductVideo';
  description?: Maybe<Scalars['String']['output']>;
  embedUrl: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ProductVideoItem = {
  __typename: 'ProductVideoItem';
  description?: Maybe<Scalars['String']['output']>;
  embedUrl: Scalars['String']['output'];
  index: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

export type ProductWithIssue = {
  __typename: 'ProductWithIssue';
  id: Scalars['ID']['output'];
  nameDE?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  stores?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ProductWithStoresMismatch = {
  __typename: 'ProductWithStoresMismatch';
  futureVisibilityCompanyId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  sku?: Maybe<Scalars['String']['output']>;
  store?: Maybe<Scalars['Int']['output']>;
};

export type ProductsBrandBreakdown = {
  __typename: 'ProductsBrandBreakdown';
  brand: Brand;
  total: Scalars['Int']['output'];
};

export type ProductsCategoryBreakdown = {
  __typename: 'ProductsCategoryBreakdown';
  category: ProductCategory;
  total: Scalars['Int']['output'];
};

export type ProductsResult = {
  __typename: 'ProductsResult';
  /** List of product items in the requested page. */
  items: Array<PartialProduct>;
  /** Total number of product items in the DB that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type ProductsResultSet = {
  __typename: 'ProductsResultSet';
  brands: Array<ProductsBrandBreakdown>;
  categories: Array<ProductsCategoryBreakdown>;
  futureStockQuantities?: Maybe<Array<Maybe<FutureStockQuantityResult>>>;
  items: Array<Product>;
  priceRange?: Maybe<ProductPriceRange>;
  prices?: Maybe<Array<Maybe<UserProductPrice>>>;
  productOrderRestrictions?: Maybe<Array<Maybe<ProductOrderRestriction>>>;
  stockQuantities?: Maybe<Array<Maybe<OptionalStockQuantity>>>;
  total: Scalars['Int']['output'];
};


export type ProductsResultSetFutureStockQuantitiesArgs = {
  locations: Array<Scalars['String']['input']>;
};


export type ProductsResultSetPricesArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartId?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
};


export type ProductsResultSetStockQuantitiesArgs = {
  locations: Array<Scalars['String']['input']>;
};

export type PurchaseBreakdown = {
  __typename: 'PurchaseBreakdown';
  items: Array<PurchaseBreakdownItem>;
  type: PurchaseBreakdownType;
};

export type PurchaseBreakdownItem = {
  __typename: 'PurchaseBreakdownItem';
  id: Scalars['String']['output'];
  totalCartGeneralDealerPrice: Scalars['String']['output'];
  totalCartSemiFinalPrice: Scalars['String']['output'];
  totalSeasonGeneralDealerPrice?: Maybe<Scalars['String']['output']>;
  totalSeasonSemiFinalPrice?: Maybe<Scalars['String']['output']>;
};

export enum PurchaseBreakdownType {
  Brand = 'brand'
}

export type Query = {
  __typename: 'Query';
  activePreorderSeasons: Array<PreorderSeason>;
  activePreorderSeasonsForCustomer: Array<PreorderSeason>;
  /**
   * Query currently active system maintenance event. Resolves into either a single active event, or null if no
   * events are ongoing.
   *
   * This can be queried by anyone regardless of permissions or authentication status.
   */
  activeSystemMaintenance?: Maybe<SystemMaintenance>;
  adminStats: StatsResult;
  /**
   * Query brands either for a single store, all for all stores accessible by logged in user.
   *
   * This is decided based on permissions of the user and the companies they can manage.
   *
   * # Arguments
   *
   * * `store`: Optional store code to fetch brands for. Not specifying a store returns all
   * brands based on user's permissions.
   *
   * # Errors
   *
   * This function will return an error if:
   * - User is not logged in
   * - User lacks sufficient permissions.
   */
  allBrands: BrandResult;
  allMetaProducts: MetaProductResult;
  allOnlineDealers: OnlineDealerListResult;
  /**
   * Fetch all payment terms for either a given company code, or all companies managed by current
   * user.
   *
   * # Arguments
   *
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query. Defaults to 10
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * Defaults to 0.
   * * `company_code`: Company code to fetch payment terms for. If this is not provided, all payment
   * terms for all companies that the current user manages are returned.
   */
  allPaymentTerms: Array<PaymentTermEntity>;
  /**
   * Fetch all shipping methods for either a given company code, or all companies managed by current
   * user.
   *
   * # Arguments
   *
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query. Defaults to 10
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * Defaults to 0.
   * * `company_code`: Company code to fetch shipping methods for. If this is not provided, all shipping
   * methods for all companies that the current user manages are returned.
   */
  allShippingMethods: ShippingMethodResult;
  /**
   * Query to request all available stores in the database.
   *
   * # Permissions
   *
   * The query will resolve to only those stores that user's permissions allows them to access.
   *
   * # Errors
   *
   * This function will return an error if:
   * - User is not logged in
   * - User lacks sufficient permissions. See [Minimum Permission](#minimum-permission) for permission requirements.
   */
  allStores: StoreResult;
  analysePassword: PasswordAnalysis;
  /**
   * Fetch a list of all users that can be assigned to the ticket specified by ticket ID
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to find assignable users for
   */
  assignableUsers: Array<UserInfo>;
  availableOrderTypes: Array<Scalars['String']['output']>;
  /**
   * Query to fetch all available permissions supported by the system as a structured data that also defines additional metadata about
   * each permission (and its potential children, if any). This includes name, description, children name,
   * available options, etc.
   *
   * This data is supposed to be used by the client to be able to render the permissions for display
   * or for editing in a helpful manner.
   */
  availablePermissions: Array<AvailablePermission>;
  branches: Array<Branch>;
  brands: Array<Brand>;
  capabilities: CapabilitiesResult;
  cart?: Maybe<Cart>;
  cartDiscounts: CustomerCartPricing;
  cartFields: CartFields;
  cartPriceSummary?: Maybe<CustomerPriceSummary>;
  carts: Array<Maybe<Cart>>;
  /**
   * Query raw product data for product by ID or SKU. This query is only supposed to be
   * used with Console UI.
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Arguments
   * - `id`: ID of the product to fetch
   * - `sku`: SKU of the product to fetch
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   * - User doesn't have [ReadCatalog](READ_CATALOG) permission
   * - Neither `id` nor `sku` is provided.
   */
  catalogProduct: PartialProduct;
  /**
   * Query raw products data. This query is only supposed to be used with Console UI.
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Arguments
   * - `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * - `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   * - User doesn't have [ReadCatalog](READ_CATALOG) permission
   */
  catalogProducts: ProductsResult;
  catalogSnapshots: CatalogSnapshotResult;
  /** List of categories */
  categories: CategoriesResultSet;
  /** A category */
  category?: Maybe<Category>;
  categoryItem: Model;
  /**
   * Query categories for a store. Returns a flat list of all categories, along with their
   * parent IDs. This information can be used to reconstruct the tree if needed.
   *
   * # Arguments
   *
   * * `store`: Store code for which to fetch categories. Must be specified if user is not a
   * company manager for any companies.
   *
   * # Minimum Permissions
   *
   * This query requires minimum [`READ_CATALOG`] permissions.
   *
   * # Errors
   *
   * This query returns an error if
   * - User doesn't have sufficient permissions
   * - Requested store doesn't exist
   * - DB query fails to resolve.
   */
  categoryItems: Array<CategorySummary>;
  /** Flat list of nested categories */
  categoryList: Array<Maybe<Category>>;
  checkUsernameAvailability: Scalars['Boolean']['output'];
  checkout?: Maybe<CheckoutFields>;
  /**
   * Query companies based on manager access level.
   *
   * * `id`: ID of the company. If this is not provided, all companies that user can manage will
   * be returned
   *
   * # Minimum Permissions
   *
   * This query requires minimum [`Permission::Manage(ManagedResource::Company(_))`] permission.
   */
  companies: CompanyListResult;
  company?: Maybe<Company>;
  consoleDataQualityReport: ConsoleDataQualityReport;
  contentServNodeIdListUpload: NodeIdListExecutionPreview;
  contentServNodeIdListUploads: NodeIdListUploadsResult;
  crawlData?: Maybe<CrawlData>;
  crawlDataList: Array<Maybe<CrawlData>>;
  currentCustomer?: Maybe<Customer>;
  currentUser: CurrentUser;
  customer: CustomerEntity;
  customerAddresses: Array<Address>;
  customerCart: CustomerCart;
  customerCarts: CartResult;
  customerDiscounts: Array<CustomerDiscountRow>;
  customerLookup?: Maybe<CustomerInfo>;
  customerOrder: CustomerOrder;
  /**
   * Query orders. This query requires minimum [ReadCore](READ_CORE) permission.
   *
   * # Arguments
   * - `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * - `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * - `statuses`: Resolve orders that have any one of the provided statuses.
   * - `empty_fields`: Resolve orders that have empty value in columns specified by this filter.
   * - `customer_number`: Resolve orders for specific customer.
   * - `created_before_duration`: Only resolve orders that were created before the specified duration. Duration needs to
   * be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format. For example, a value of `PT1H` for this
   * filter will resolve all orders that are older than 1 hour from current time.
   * - `created_before`: Only resolve orders that were created before the specified timestamp. Needs to be in
   * [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   * - `updated_before_duration`: Only resolve orders that were updated before the specified duration. Duration needs to
   * be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format. For example, a value of `PT1H` for this
   * filter will resolve all orders that are last updated older than 1 hour from current time.
   * - `updated_before`: Only resolve orders that were updated before the specified timestamp. Needs to be in
   * [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   */
  customerOrders: OrdersResult;
  customerPreorder: CustomerPreorder;
  customerPreorderPricing?: Maybe<CustomerPreorderPricing>;
  customerPreorderSummary?: Maybe<CustomerPreorderSummary>;
  /**
   * Query orders. This query requires minimum [ReadCore](READ_CORE) permission.
   *
   * # Arguments
   * - `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * - `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * - `statuses`: Resolve orders that have any one of the provided statuses.
   * - `empty_fields`: Resolve orders that have empty value in columns specified by this filter.
   * - `customer_number`: Resolve orders for specific customer.
   * - `created_before_duration`: Only resolve orders that were created before the specified duration. Duration needs to
   * be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format. For example, a value of `PT1H` for this
   * filter will resolve all orders that are older than 1 hour from current time.
   * - `created_before`: Only resolve orders that were created before the specified timestamp. Needs to be in
   * [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   * - `updated_before_duration`: Only resolve orders that were updated before the specified duration. Duration needs to
   * be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format. For example, a value of `PT1H` for this
   * filter will resolve all orders that are last updated older than 1 hour from current time.
   * - `updated_before`: Only resolve orders that were updated before the specified timestamp. Needs to be in
   * [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   */
  customerPreorders: PreordersResult;
  customerPrice?: Maybe<UserProductPrice>;
  customerPriceCacheKey: Scalars['ID']['output'];
  customerPriceList: Array<CustomerPriceListItem>;
  customerPriceSummary?: Maybe<CustomerPriceSummary>;
  customerPrices: Array<Maybe<UserProductPrice>>;
  customerProductOrderRestrictions: ProductOrderRestrictionResult;
  customerSpecialPrices: Array<CustomerSpecialPriceRow>;
  customers: CustomerResult;
  /** @deprecated Use consoleDataQualityReport query instead. */
  dataQualityReport: DataQualityReport;
  dealers: Array<Dealer>;
  deliveredQuantity: DeliveredQuantitySourceResult;
  deliveries: DeliveriesResult;
  /**
   * Query status information of a download request by ID.
   *
   * # Arguments
   *
   * * `id`: ID of the download request to fetch status for.
   */
  downloadRequestInfo: DownloadRequestInfo;
  downloadRequestStatus: DownloadRequest;
  dropshippingStatus?: Maybe<DropshippingStatus>;
  /** Future stock quantities for a product */
  futureStockQuantities: Array<FutureStockQuantity>;
  iconLibrary: Array<IconLibraryItem>;
  lastSystemReload?: Maybe<SystemReloadEntry>;
  latestRun?: Maybe<TaskRun>;
  latestRuns: Array<TaskRun>;
  loggedInUser?: Maybe<UserLookupResultItem>;
  lookupMotorcycleLicensePlate: Array<MotorcycleLicensePlateLookupResult>;
  /** Admin only */
  metaProduct?: Maybe<MetaProduct>;
  metaProductFilters: MetaProductFilters;
  metaProductItem: PartialMetaProduct;
  metaProducts: Array<MetaProduct>;
  motorcycle?: Maybe<Motorcycle>;
  motorcycles: MotorcyclesResult;
  /**
   * Query single node by ID.
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Arguments
   *
   * * `id`: ID of the node to fetch details for
   *
   * returns: Result<PartialNode, Error>
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   * - User doesn't have [ReadCatalog](READ_CATALOG) permission
   */
  node: PartialNode;
  /**
   * Query list of nodes.
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Arguments
   * - `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * - `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   *
   * returns: Result<NodesResult, Error>
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   * - User doesn't have [ReadCatalog](READ_CATALOG) permission
   */
  nodes: NodesResult;
  notifications: NotificationsResult;
  onlineDealer: CustomerOnlineDealer;
  onlineDealers: Array<OnlineDealer>;
  order?: Maybe<Order>;
  orderDocument: OrderDocumentSourceResult;
  orderHistoryDeletedRecords: OrderHistoryDeletedRecordResult;
  orderItem?: Maybe<OrderItem>;
  orderItems?: Maybe<OrderItemListResult>;
  orderSummary: OrderSummaryStats;
  orders?: Maybe<OrderListResult>;
  passwordResetToken?: Maybe<PasswordResetToken>;
  passwordResetTokens?: Maybe<Array<Maybe<PasswordResetToken>>>;
  paymentTerms: Array<PaymentTerm>;
  pendingOrderedQuantities: Array<PendingOrderedQuantity>;
  preorder?: Maybe<Preorder>;
  preorderCartBrandBreakdown: Array<PreorderCartBrandBreakdown>;
  preorderDiscounts?: Maybe<PreorderDiscounts>;
  /**
   * Fetch all preorder seasons for either a given store, or all stores managed by current
   * user.
   *
   * # Arguments
   *
   * * `store`: Store to fetch payment terms for. If this is not provided, all preorder
   * seasons for all stores that the current user manages are returned.
   */
  preorderSeasons: PreorderSeasonResult;
  preorderSummary: OrderSummaryStats;
  preorders?: Maybe<Array<Maybe<Preorder>>>;
  product?: Maybe<Product>;
  productFilterValues: Array<Maybe<ProductFilterValue>>;
  /** Future stock quantities for a product */
  productFutureStock: FutureStockQuantityResult;
  productItem: PartialProduct;
  /** Lookup by internal or external SKU. */
  productItemLookup: Array<ProductOrVariant>;
  /**
   * Query product_items available in the catalog.
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Arguments
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * * `updated_since_duration`: Only resolve products that were updated after the specified duration. Duration
   * needs to be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format. For example,
   * a value of `PT1H` for this filter will resolve all productItems that are last updated within 1 hour
   * from current time.
   * * `updated_since`: Only resolve products that were updated after the specified timestamp. Needs to be
   * in [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   * - User doesn't have [ReadCatalog](READ_CATALOG) permission
   */
  productItems: ProductItemsResult;
  productList: Array<ProductOrVariant>;
  /** Future stock quantities for a product */
  productListFutureStock: Array<FutureStockQuantityResult>;
  productListOrderRestriction: Array<Maybe<ProductOrderRestriction>>;
  productMetaAttributeValues: Array<ProductMetaAttributeValueItem>;
  /** Future stock quantities for a product node */
  productNodeFutureStockQuantities: Array<FutureStockQuantity>;
  /** Stock quantities for a product node */
  productNodeReplacementProductSkus: Array<Scalars['String']['output']>;
  /** Stock quantities for a product node */
  productNodeStockQuantity: Array<StockQuantity>;
  productOrderRestriction: ProductOrderRestriction;
  productOrderRestrictionsForStore: Array<ProductOrderRestrictionMessage>;
  productPricing: ProductPricing;
  productPricings: ProductPricingResult;
  productRestrictionValues: Array<ProductRestrictionValueEntry>;
  /** Lookup product ID list by internal SKU list, returns a list of items, the item can be an ID or null if the lookup failed. */
  productSkuListLookup: Array<Maybe<ProductOrVariant>>;
  /**
   * Query basic product data for product by SKU.
   *
   * # Arguments
   * - `sku`: SKU of the product to fetch summary for
   */
  productSummary: ProductSummary;
  products: ProductsResultSet;
  /**
   * Query remote user permissions table. Optionally filter results by username.
   *
   * # Arguments
   *
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * * `search`: Filter remote user permissions by username of the target user
   *
   * # Minimum Permissions
   *
   * This query requires minimum [`SYSTEM_MANAGER`] permission.
   */
  remoteUserPermissions: Array<RemoteUserPermission>;
  /**
   * Stock quantities for a product
   *
   * Null means it cannot be ordered in this location
   */
  replacementProductSku?: Maybe<Scalars['String']['output']>;
  run?: Maybe<TaskRun>;
  runStatuses: Array<RunStatusEnum>;
  salesHeaders: SalesHeaderSourceResult;
  salesLine: SalesLineSourceResult;
  /**
   * Query to be used for global search feature in Horizon Client.
   *
   * # Errors
   *
   * This function will return an error if:
   * [`search_customers`] returns error
   * [`search_brands`] returns error
   * [`search_users`] returns error
   */
  searchAdmin: SearchResult;
  /**
   * Query to be used for global search feature in Console Client.
   *
   * # Errors
   *
   * This function will return an error if:
   * [`search_customers`] returns error
   * [`search_brands`] returns error
   * [`search_users`] returns error
   * [`search_nodes`] returns error
   * [`search_categories`] returns error
   * [`search_products`] returns error
   * [`search_preorders`] returns error
   */
  searchConsole: ConsoleSearchResult;
  shippingMethods: Array<ShipmentMethod>;
  shoppingList?: Maybe<ShoppingList>;
  /**
   * Stock quantities for a product
   *
   * Null quantity means it cannot be ordered in this location
   */
  singleStockQuantity: OptionalStockQuantity;
  sourceDeliveries: DeliverySourceResult;
  stats?: Maybe<Stats>;
  /**
   * Stock quantities for a product
   *
   * Null quantity means it cannot be ordered in this location
   */
  stockQuantities: Array<OptionalStockQuantity>;
  /**
   * Stock quantities for a product
   *
   * Null means it cannot be ordered in this location
   * @deprecated Use optionalStockQuantity instead
   */
  stockQuantity?: Maybe<StockQuantity>;
  store?: Maybe<Store>;
  /**
   * Query to request all available stores in the database.
   *
   * # Permissions
   *
   * The query will resolve to only those stores that user's permissions allows them to access.
   *
   * # Errors
   *
   * This function will return an error if:
   * - User is not logged in
   * - User lacks sufficient permissions. See [Minimum Permission](#minimum-permission) for permission requirements.
   */
  storeDetails: StoreEntity;
  storePreorderSeason: StorePreorderSeason;
  stores: Array<Maybe<Store>>;
  /**
   * Get ticket details by ID. If the query is performed using a permission token (x-permission-token)
   * header instead of a authentication token, only the following selection of fields are allowed to be
   * queried:
   * - `id`
   * - `ticket_type`
   * - `status`
   * - `productClassId`
   *
   * Additionally, only "active" tickets are allowed to be fetched using permission tokens.
   *
   * # Arguments
   *
   * * `id`: ID of the ticket to fetch
   */
  supportTicket: SupportTicket;
  supportTicketAttachmentEvents: Array<SupportTicketEvent>;
  supportTicketMessageTemplate: SupportTicketMessageTemplate;
  supportTicketMessageTemplates: TicketMessageTemplateResults;
  /**
   * Query tickets based on provided filters and user's company access.
   *
   * Customers can access tickets created by themselves
   * Company ticket managers can access tickets belonging to their company.
   *
   * # Arguments
   *
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve
   * pagination.
   * * `search`: Search tickets by ticket number or reference
   * * `customer_number`: Filter tickets for a particular customer by customer number
   * * `r#type`: Filter tickets by type
   * * `product_class_id`: Filter tickets by returns category. Only available when type 'return'
   * is supplied. Providing this parameter otherwise will result in an error
   * * `status`: Filter tickets by current status
   * * `submission_date`: Only return tickets that were submitted within the specified date range.
   * The date range can be open-ended on either start or end.
   * * `last_updated`: Only return tickets that were last updated within the specified date range.
   * The date range can be open-ended on either start or end.
   * * `assignees`: Filter tickets by username of the person who is assigned to the ticket
   * * `sku`: Filter tickets by SKU of the sales lines in the ticket.
   */
  supportTickets: SupportTicketsResult;
  /**
   * Query system maintenance event by id.
   *
   * # Arguments
   *
   * * `id`: ID of the system maintenance event entry to fetch details for.
   *
   * # Minimum Permission
   *
   * This query requires at least [CompanyManager](Permission::Manage) permission with [Company](ManagedResource::Company) resource.
   */
  systemMaintenance: SystemMaintenance;
  /**
   * List all system maintenance events
   *
   * # Arguments
   *
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * * `ids`: Only return tasks with these ids.
   * * `updated_since_duration`: Only resolve items that were updated after the specified duration. Duration needs to be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format. For example, a value of `PT1H` for this filter will resolve all vehicles that are last updated within 1 hour from current time.
   * * `updated_since`: Only resolve items that were updated after the specified timestamp. Needs to be in [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   * * `maintenance_type`: Only resolve maintenance events of a particular type
   * * `is_active`: Only resolve maintenance events that are presently ongoing
   * * `sort`: Describe how result should be sorted
   *
   * # Minimum Permission
   *
   * This query requires at least [CompanyManager](Permission::Manage) permission with [Company](ManagedResource::Company) resource.
   */
  systemMaintenances: SystemMaintenanceResult;
  taskTypes: Array<Scalars['String']['output']>;
  tasks: RunResult;
  taxCode: TaxCode;
  taxCodes: TaxCodeResult;
  ticketSchema: TicketSchemaResult;
  upstreamCacheEntries: UpstreamCacheResult;
  /**
   * Query user data by id.
   *
   * # Arguments
   *
   * * `id`: Id of the user
   */
  user: User;
  userList: Array<UserInfo>;
  userLookup: UserLookupResult;
  users: UsersResult;
  vehicleFilters: VehicleFilters;
  /**
   * Query vehicles available in the catalog and their linked products.
   *
   * This query requires minimum [ReadCatalog](READ_CATALOG) permission.
   *
   * # Arguments
   * * `limit`: Number of items to fetch. Cannot be more than 100 for a single query.
   * * `offset`: Skip first N items from the table. Used in combination with `limit` to achieve pagination.
   * * `updated_since_duration`: Only resolve vehicles that were updated after the specified duration.
   * Duration needs to be in [ISO8601 Duration](https://en.wikipedia.org/wiki/ISO_8601#Durations) format.
   * For example, a value of `PT1H` for this filter will resolve all vehicles that are last updated within
   * 1 hour from current time.
   * * `updated_since`: Only resolve vehicles that were updated after the specified timestamp. Needs to be
   * in [ISO8601 DateTime](https://en.wikipedia.org/wiki/ISO_8601#Combined_date_and_time_representations) format.
   *
   * # Errors
   *
   * This function will return an error if:
   * - The DB errors while resolving queries
   * - User doesn't have [ReadCatalog](READ_CATALOG) permission
   *
   * # Field Resolvers
   * - [Tires](PartialVehicle::tires)
   * - [RelatedProducts](PartialVehicle::related_products)
   */
  vehicles: VehiclesResult;
  version?: Maybe<Scalars['String']['output']>;
};


export type QueryActivePreorderSeasonsArgs = {
  store: Scalars['ID']['input'];
};


export type QueryActivePreorderSeasonsForCustomerArgs = {
  customerNumber: Scalars['ID']['input'];
};


export type QueryAllBrandsArgs = {
  company?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CatalogBrandModelSort>;
  store?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllMetaProductsArgs = {
  createCursor?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<MetaProductModelSort>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllOnlineDealersArgs = {
  company?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<CustomerOnlineDealerModelSort>;
};


export type QueryAllPaymentTermsArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};


export type QueryAllShippingMethodsArgs = {
  companyId?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CompanyShippingMethodModelSort>;
};


export type QueryAllStoresArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAnalysePasswordArgs = {
  password: Scalars['String']['input'];
};


export type QueryAssignableUsersArgs = {
  id: Scalars['Int']['input'];
};


export type QueryBrandsArgs = {
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
  withKeyDealers?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCapabilitiesArgs = {
  userId?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCartArgs = {
  cartType?: InputMaybe<CartType>;
  id?: InputMaybe<Scalars['Int']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryCartDiscountsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCartFieldsArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  store: Scalars['ID']['input'];
};


export type QueryCartPriceSummaryArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartId: Scalars['Int']['input'];
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryCartsArgs = {
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  store: Scalars['ID']['input'];
  type?: InputMaybe<CartType>;
};


export type QueryCatalogProductArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCatalogProductsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};


export type QueryCategoriesArgs = {
  depth?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  language: Language;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryCategoryArgs = {
  id: Scalars['ID']['input'];
  language: Language;
};


export type QueryCategoryItemArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCategoryItemsArgs = {
  store?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCategoryListArgs = {
  application?: InputMaybe<Scalars['String']['input']>;
  expandedIds: Array<InputMaybe<Scalars['ID']['input']>>;
  language: Language;
  store: Scalars['ID']['input'];
};


export type QueryCheckUsernameAvailabilityArgs = {
  username: Scalars['String']['input'];
};


export type QueryCheckoutArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  language: Language;
  store?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCompaniesArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCompanyArgs = {
  id: Scalars['Int']['input'];
};


export type QueryContentServNodeIdListUploadArgs = {
  id: Scalars['String']['input'];
};


export type QueryCrawlDataArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCustomerAddressesArgs = {
  customerNumber: Scalars['Int']['input'];
};


export type QueryCustomerCartArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCustomerCartsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  customerNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: Scalars['Int']['input'];
  notEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: Scalars['Int']['input'];
  paymentDueDate?: InputMaybe<Array<Scalars['String']['input']>>;
  paymentTermId?: InputMaybe<Array<Scalars['String']['input']>>;
  preorderSeasonId?: InputMaybe<Array<Scalars['Int']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  shippingMethod?: InputMaybe<Array<Scalars['String']['input']>>;
  sort?: InputMaybe<CustomerCartModelSort>;
  stockLocation?: InputMaybe<Array<Scalars['String']['input']>>;
  store?: InputMaybe<Array<Scalars['String']['input']>>;
  type?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryCustomerDiscountsArgs = {
  store: Scalars['ID']['input'];
};


export type QueryCustomerLookupArgs = {
  search: Scalars['String']['input'];
};


export type QueryCustomerOrderArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  upstreamOrderId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerOrdersArgs = {
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  createdBeforeDuration?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  emptyFields?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<CustomerOrderModelSort>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAfter?: InputMaybe<Scalars['String']['input']>;
  updatedBefore?: InputMaybe<Scalars['String']['input']>;
  updatedBeforeDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerPreorderArgs = {
  id: Scalars['Int']['input'];
};


export type QueryCustomerPreorderPricingArgs = {
  customerNumber: Scalars['ID']['input'];
  preorderSeasonId: Scalars['ID']['input'];
};


export type QueryCustomerPreorderSummaryArgs = {
  cartId: Scalars['Int']['input'];
};


export type QueryCustomerPreordersArgs = {
  createdBefore?: InputMaybe<Scalars['String']['input']>;
  createdBeforeDuration?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  emptyFields?: InputMaybe<Array<Scalars['String']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<CustomerPreorderModelSort>;
  statuses?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedAfter?: InputMaybe<Scalars['String']['input']>;
  updatedBefore?: InputMaybe<Scalars['String']['input']>;
  updatedBeforeDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerPriceArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  sku: Scalars['String']['input'];
  store?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCustomerPriceListArgs = {
  language: Language;
  store: Scalars['ID']['input'];
};


export type QueryCustomerPriceSummaryArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartType: CartType;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  items: Array<CartItemInput>;
  store: Scalars['ID']['input'];
};


export type QueryCustomerPricesArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartId?: InputMaybe<Scalars['Int']['input']>;
  cartType?: InputMaybe<CartType>;
  customerNumber?: InputMaybe<Scalars['ID']['input']>;
  skuList: Array<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCustomerProductOrderRestrictionsArgs = {
  companyId: Scalars['Int']['input'];
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<CustomerProductOrderRestrictionModelSort>;
};


export type QueryCustomerSpecialPricesArgs = {
  store: Scalars['ID']['input'];
};


export type QueryCustomersArgs = {
  cartPriceEditCustomersOnly?: InputMaybe<Scalars['Int']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  keyDealersOnly?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  managedCustomersOnly?: InputMaybe<Scalars['Int']['input']>;
  offset?: Scalars['Int']['input'];
  preorderPriceEditCustomersOnly?: InputMaybe<Scalars['Int']['input']>;
  representedCustomersOnly?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CustomerEntityModelSort>;
  withPendingPreorderCart?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDealersArgs = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  brandNames?: InputMaybe<Array<Scalars['String']['input']>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryDeliveredQuantityArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  deliveredQuantity?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  positionId?: InputMaybe<Scalars['String']['input']>;
  salesId?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDeliveriesArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDownloadRequestInfoArgs = {
  id: Scalars['String']['input'];
};


export type QueryDownloadRequestStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDropshippingStatusArgs = {
  location: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryFutureStockQuantitiesArgs = {
  location: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryIconLibraryArgs = {
  storeId: Scalars['Int']['input'];
};


export type QueryLatestRunArgs = {
  type: Scalars['String']['input'];
};


export type QueryLatestRunsArgs = {
  statusFilter?: InputMaybe<Array<RunStatusEnum>>;
};


export type QueryLookupMotorcycleLicensePlateArgs = {
  licensePlateNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMetaProductArgs = {
  id: Scalars['ID']['input'];
  language?: InputMaybe<Language>;
};


export type QueryMetaProductFiltersArgs = {
  categoryEN?: InputMaybe<Scalars['String']['input']>;
  categoryValue?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryMetaProductItemArgs = {
  id: Scalars['Int']['input'];
};


export type QueryMetaProductsArgs = {
  Offset?: InputMaybe<Scalars['Int']['input']>;
  categoryEN?: InputMaybe<Scalars['String']['input']>;
  categoryValue?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  limit?: InputMaybe<Scalars['Int']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  modelYear?: InputMaybe<Scalars['Int']['input']>;
  productType?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryMotorcycleArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type QueryMotorcyclesArgs = {
  ccmRangeEnd?: InputMaybe<Scalars['Int']['input']>;
  ccmRangeStart?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  manufacturerName?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ratedPower?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  typeAcceptanceList?: InputMaybe<Array<Scalars['String']['input']>>;
  typeNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryNodeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryNodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: Scalars['Int']['input'];
  outdated?: InputMaybe<Scalars['Boolean']['input']>;
  rootNodesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<NodeModelSort>;
  subtreeNodeId?: InputMaybe<Scalars['Int']['input']>;
  unmapped?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNotificationsArgs = {
  filters?: InputMaybe<NotificationEventContentFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  since?: InputMaybe<Scalars['Int']['input']>;
  types?: InputMaybe<Array<NotificationEventContentType>>;
  unreadOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryOnlineDealerArgs = {
  id: Scalars['Int']['input'];
};


export type QueryOnlineDealersArgs = {
  brandName: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
  language: Language;
  orderReference?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrderDocumentArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  documentNr?: InputMaybe<Scalars['String']['input']>;
  documentType?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  modifiedDate?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  positionId?: InputMaybe<Scalars['String']['input']>;
  recId?: InputMaybe<Scalars['String']['input']>;
  recIdDocument?: InputMaybe<Scalars['String']['input']>;
  salesId?: InputMaybe<Scalars['String']['input']>;
  stornoStatus?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrderHistoryDeletedRecordsArgs = {
  axTableNr?: InputMaybe<Scalars['String']['input']>;
  deletedRecId?: InputMaybe<Scalars['String']['input']>;
  deletedRecIdList?: InputMaybe<Array<Scalars['String']['input']>>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  modifiedDate?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrderItemArgs = {
  id: Scalars['ID']['input'];
  language: Language;
  store: Scalars['ID']['input'];
};


export type QueryOrderItemsArgs = {
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  deliveryOnRequest?: InputMaybe<Scalars['Boolean']['input']>;
  excludeServices?: InputMaybe<Scalars['Boolean']['input']>;
  hasDeliveries?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  orderEnd?: InputMaybe<Scalars['String']['input']>;
  orderStart?: InputMaybe<Scalars['String']['input']>;
  orderTypes?: InputMaybe<Array<OrderType>>;
  origin?: InputMaybe<OrderOrigin>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  productClassId?: InputMaybe<Scalars['Int']['input']>;
  requestedShippingDateEnd?: InputMaybe<Scalars['String']['input']>;
  requestedShippingDateStart?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skuList?: InputMaybe<Array<Scalars['String']['input']>>;
  statuses?: InputMaybe<Array<OrderStatus>>;
  store: Scalars['ID']['input'];
};


export type QueryOrderSummaryArgs = {
  timePeriod: StatTimePeriod;
};


export type QueryOrdersArgs = {
  end?: InputMaybe<Scalars['String']['input']>;
  language: Language;
  orderTypes?: InputMaybe<Array<OrderType>>;
  origin?: InputMaybe<OrderOrigin>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrderStatus>;
  store: Scalars['ID']['input'];
};


export type QueryPasswordResetTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryPaymentTermsArgs = {
  language: Language;
  store: Scalars['ID']['input'];
};


export type QueryPreorderArgs = {
  id: Scalars['ID']['input'];
  language?: InputMaybe<Language>;
};


export type QueryPreorderCartBrandBreakdownArgs = {
  cartId?: InputMaybe<Scalars['Int']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryPreorderSeasonsArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<StorePreorderSeasonModelSort>;
  store?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPreorderSummaryArgs = {
  timePeriod: StatTimePeriod;
};


export type QueryPreordersArgs = {
  allCustomers?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Language>;
};


export type QueryProductArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  language: Language;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryProductFilterValuesArgs = {
  attributeFilters?: InputMaybe<Scalars['JSON']['input']>;
  bestAvailability?: InputMaybe<ProductBestAvailabilityFilter>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  filterField: Scalars['String']['input'];
  filters?: InputMaybe<Array<InputMaybe<ProductListFilter>>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeExcludedCategories?: InputMaybe<Scalars['Boolean']['input']>;
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  onSale?: InputMaybe<Scalars['Boolean']['input']>;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sparePartParentId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<Array<InputMaybe<ProductState>>>;
  store: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductFutureStockArgs = {
  location: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryProductItemArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductItemLookupArgs = {
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  language: Language;
  limit?: InputMaybe<Scalars['Int']['input']>;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryProductItemsArgs = {
  createCursor?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  searchableCategoriesOnly?: InputMaybe<Scalars['Boolean']['input']>;
  store?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductListArgs = {
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  language: Language;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  skuList: Array<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryProductListFutureStockArgs = {
  location: Scalars['String']['input'];
  skuList: Array<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryProductListOrderRestrictionArgs = {
  skuList: Array<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryProductNodeFutureStockQuantitiesArgs = {
  id: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryProductNodeReplacementProductSkusArgs = {
  id: Scalars['ID']['input'];
  store: Scalars['ID']['input'];
};


export type QueryProductNodeStockQuantityArgs = {
  id: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryProductOrderRestrictionArgs = {
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryProductOrderRestrictionsForStoreArgs = {
  store: Scalars['ID']['input'];
};


export type QueryProductPricingArgs = {
  id: Scalars['Int']['input'];
};


export type QueryProductPricingsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<ProductPricingModelSort>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductSkuListLookupArgs = {
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  language: Language;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  skuList: Array<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryProductSummaryArgs = {
  sku: Scalars['String']['input'];
};


export type QueryProductsArgs = {
  attributeFilters?: InputMaybe<Scalars['JSON']['input']>;
  bestAvailability?: InputMaybe<ProductBestAvailabilityFilter>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductListFilter>>>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeExcludedCategories?: InputMaybe<Scalars['Boolean']['input']>;
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
  language: Language;
  maxPrice?: InputMaybe<Scalars['Float']['input']>;
  minPrice?: InputMaybe<Scalars['Float']['input']>;
  onSale?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sortDirection?: InputMaybe<SortDirection>;
  sortField?: InputMaybe<ProductSortField>;
  sparePartParentId?: InputMaybe<Scalars['ID']['input']>;
  states?: InputMaybe<Array<InputMaybe<ProductState>>>;
  store: Scalars['ID']['input'];
  vehicleId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRemoteUserPermissionsArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  username?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReplacementProductSkuArgs = {
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryRunArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySalesHeadersArgs = {
  buyerOrderNumber?: InputMaybe<Scalars['String']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  externalOrderNumber?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  modifiedDate?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  orderDate?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  orderStatus?: InputMaybe<Scalars['String']['input']>;
  orderType?: InputMaybe<Scalars['String']['input']>;
  preorderBackorderDelete?: InputMaybe<Scalars['String']['input']>;
  salesId?: InputMaybe<Scalars['String']['input']>;
  salesOriginId?: InputMaybe<Scalars['String']['input']>;
  stagingHeaderRecId?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySalesLineArgs = {
  articleNumber?: InputMaybe<Scalars['String']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  deliveryMode?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  itemStatus?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  modifiedDate?: InputMaybe<Scalars['String']['input']>;
  offset?: Scalars['Int']['input'];
  orderDate?: InputMaybe<Scalars['String']['input']>;
  orderLineStatus?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  place?: InputMaybe<Scalars['String']['input']>;
  positionId?: InputMaybe<Scalars['String']['input']>;
  preorderBackorderNoDelete?: InputMaybe<Scalars['String']['input']>;
  qtyOnOrder?: InputMaybe<Scalars['String']['input']>;
  qtyOpen?: InputMaybe<Scalars['String']['input']>;
  qtyOrdered?: InputMaybe<Scalars['String']['input']>;
  salesId?: InputMaybe<Scalars['String']['input']>;
  shippingDateRequested?: InputMaybe<Scalars['String']['input']>;
  site?: InputMaybe<Scalars['String']['input']>;
  stockAvailableDelay?: InputMaybe<Scalars['String']['input']>;
  stockAvailableLater?: InputMaybe<Scalars['String']['input']>;
  stockAvailableNow?: InputMaybe<Scalars['String']['input']>;
  stockAvailableSoon?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySearchAdminArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchConsoleArgs = {
  query: Scalars['String']['input'];
};


export type QueryShippingMethodsArgs = {
  store: Scalars['ID']['input'];
};


export type QueryShoppingListArgs = {
  code: Scalars['String']['input'];
  includePreorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
  language: Language;
  preorderSeasonId?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySingleStockQuantityArgs = {
  location: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QuerySourceDeliveriesArgs = {
  carrier?: InputMaybe<Scalars['String']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['String']['input']>;
  deliveryDate?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryNoteId?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  packingSlipId?: InputMaybe<Scalars['String']['input']>;
  salesId?: InputMaybe<Scalars['String']['input']>;
  shipment?: InputMaybe<Scalars['String']['input']>;
  trackingNumber?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStockQuantitiesArgs = {
  location: Scalars['String']['input'];
  skuList: Array<Scalars['String']['input']>;
  store: Scalars['ID']['input'];
};


export type QueryStockQuantityArgs = {
  location: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  store: Scalars['ID']['input'];
};


export type QueryStoreArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStoreDetailsArgs = {
  id: Scalars['Int']['input'];
};


export type QueryStorePreorderSeasonArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySupportTicketArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySupportTicketAttachmentEventsArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySupportTicketMessageTemplateArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySupportTicketMessageTemplatesArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SupportTicketMessageTemplateModelSort>;
};


export type QuerySupportTicketsArgs = {
  assignees?: InputMaybe<Array<Scalars['String']['input']>>;
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  customerNumber?: InputMaybe<Array<Scalars['Int']['input']>>;
  lastUpdated?: InputMaybe<DateRangeInput>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  productClassId?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<SupportTicketModelSort>;
  sortBy?: InputMaybe<SupportTicketSort>;
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  submissionDate?: InputMaybe<DateRangeInput>;
  type?: InputMaybe<SupportTicketType>;
};


export type QuerySystemMaintenanceArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySystemMaintenancesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: Scalars['Int']['input'];
  maintenanceType?: InputMaybe<SystemMaintenanceTypeEnum>;
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<SystemMaintenanceModelSort>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTasksArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  sort?: InputMaybe<TaskRunModelSort>;
  statuses?: InputMaybe<Array<RunStatusEnum>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
  usernames?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryTaxCodeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryTaxCodesArgs = {
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTicketSchemaArgs = {
  store: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUserListArgs = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  queryPermission?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserLookupArgs = {
  search: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  permission?: InputMaybe<PermissionFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<UserModelSort>;
  source?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVehicleFiltersArgs = {
  ccmRangeEnd?: InputMaybe<Scalars['Int']['input']>;
  ccmRangeStart?: InputMaybe<Scalars['Int']['input']>;
  manufacturerName?: InputMaybe<Array<Scalars['String']['input']>>;
  model?: InputMaybe<Array<Scalars['String']['input']>>;
  ratedPower?: InputMaybe<Array<Scalars['String']['input']>>;
  search?: InputMaybe<Scalars['String']['input']>;
  typeAcceptanceList?: InputMaybe<Array<Scalars['String']['input']>>;
  typeNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryVehiclesArgs = {
  createCursor?: InputMaybe<Scalars['Boolean']['input']>;
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
  updatedSince?: InputMaybe<Scalars['String']['input']>;
  updatedSinceDuration?: InputMaybe<Scalars['String']['input']>;
};

export type RawData = {
  __typename: 'RawData';
  fields: Array<RawDataField>;
  id: Scalars['ID']['output'];
};

export type RawDataField = {
  __typename: 'RawDataField';
  name: Scalars['String']['output'];
  value: RawDataValue;
};

export type RawDataResultSet = {
  __typename: 'RawDataResultSet';
  items: Array<RawData>;
  total: Scalars['Int']['output'];
};

export type RawDataValue = {
  __typename: 'RawDataValue';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  json?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['Float']['output']>;
  string?: Maybe<Scalars['String']['output']>;
};

export type RedirectUrlsInput = {
  cancel: Scalars['String']['input'];
  error: Scalars['String']['input'];
  success: Scalars['String']['input'];
};

/** Represents a related product entry */
export type RelatedProducts = {
  __typename: 'RelatedProducts';
  brandName: Scalars['String']['output'];
  comment?: Maybe<OptionalLocalizedField>;
  sku: Scalars['String']['output'];
};

export type RemoteUserPermission = {
  __typename: 'RemoteUserPermission';
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  permissions: Array<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
};

export type RunResult = {
  __typename: 'RunResult';
  /** List of task runs in the requested page. */
  items: Array<TaskRun>;
  /** Total number of vehicles in the DB that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export enum RunStatusEnum {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Error = 'error',
  Requested = 'requested',
  Running = 'running',
  Stopped = 'stopped',
  Stopping = 'stopping',
  Waiting = 'waiting'
}

export type RunTaskResult = {
  __typename: 'RunTaskResult';
  id: Scalars['Int']['output'];
};

export type SalesHeaderSourceResult = {
  __typename: 'SalesHeaderSourceResult';
  items: Array<SourceSalesHeader>;
  total: Scalars['Int']['output'];
};

export enum SalesLineBackorderDecreaseIssues {
  AdditionalProduct = 'ADDITIONAL_PRODUCT',
  ArticleStatus = 'ARTICLE_STATUS',
  ManualCancellationRequired = 'MANUAL_CANCELLATION_REQUIRED',
  PendingWork = 'PENDING_WORK',
  PickingList = 'PICKING_LIST',
  Preorder = 'PREORDER',
  ReferenceNumber = 'REFERENCE_NUMBER',
  Supplier = 'SUPPLIER'
}

export type SalesLineSourceResult = {
  __typename: 'SalesLineSourceResult';
  items: Array<SourceSalesLine>;
  total: Scalars['Int']['output'];
};

export type SearchBrandResult = {
  __typename: 'SearchBrandResult';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type SearchCategoryResult = {
  __typename: 'SearchCategoryResult';
  id: Scalars['Int']['output'];
  nameDe: Scalars['String']['output'];
};

export type SearchCustomerResult = {
  __typename: 'SearchCustomerResult';
  companyCode: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SearchNodeResult = {
  __typename: 'SearchNodeResult';
  id: Scalars['Int']['output'];
  labelEn?: Maybe<Scalars['String']['output']>;
};

export type SearchOrderResult = {
  __typename: 'SearchOrderResult';
  customerNumber: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  upstreamOrderId: Scalars['String']['output'];
};

export type SearchPreorderResult = {
  __typename: 'SearchPreorderResult';
  customerNumber: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  upstreamOrderNumber: Scalars['String']['output'];
};

export type SearchProductResult = {
  __typename: 'SearchProductResult';
  id: Scalars['Int']['output'];
  nameDe: Scalars['String']['output'];
  sku?: Maybe<Scalars['String']['output']>;
};

export type SearchResult = {
  __typename: 'SearchResult';
  /**
   * Perform a text search for [brands](entities::core::brand::Model) in `name` column.
   *
   * # Errors
   *
   * This function will return an error if DB query returns error.
   */
  brands: Array<SearchBrandResult>;
  /**
   * Perform a text search for [customers](entities::customer::customer::Model) in `id`, `name` and `email` columns
   *
   * # Errors
   *
   * This function will return an error if DB query returns error.
   */
  customers: Array<SearchCustomerResult>;
  products: Array<SearchProductResult>;
  query: Scalars['String']['output'];
  /**
   * Perform a text search for [users](entities::auth::User) in `id`, `username` and `email` columns
   *
   * # Errors
   *
   * This function will return an error if DB query returns error.
   */
  users: Array<SearchUserResult>;
};


export type SearchResultCustomersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type SearchResultProductsArgs = {
  store?: InputMaybe<Scalars['String']['input']>;
};


export type SearchResultUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type SearchUserResult = {
  __typename: 'SearchUserResult';
  customerNumber?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  username: Scalars['String']['output'];
};

export type SendOrderOutcome = {
  __typename: 'SendOrderOutcome';
  order?: Maybe<Order>;
  preorder?: Maybe<Preorder>;
  success: Scalars['Boolean']['output'];
};

export type SendPreorderOutcome = {
  __typename: 'SendPreorderOutcome';
  preorder?: Maybe<Preorder>;
  success: Scalars['Boolean']['output'];
};

export type ShipmentMethod = {
  __typename: 'ShipmentMethod';
  dropshipment: Scalars['Boolean']['output'];
  nameDE: Scalars['String']['output'];
  nameEN: Scalars['String']['output'];
  nameFR: Scalars['String']['output'];
  nameIT: Scalars['String']['output'];
  shipmentCode: Scalars['String']['output'];
};

export type ShippingMethod = {
  __typename: 'ShippingMethod';
  /** Currency of shipment price */
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** Whether this shipping method is the default one */
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  /** Name of shipment method */
  name: Scalars['String']['output'];
  /** Price of shipment */
  price?: Maybe<Scalars['Float']['output']>;
};

export type ShippingMethodResult = {
  __typename: 'ShippingMethodResult';
  items: Array<CompanyShippingMethod>;
  total: Scalars['Int']['output'];
};

export type ShoppingList = {
  __typename: 'ShoppingList';
  code: Scalars['String']['output'];
  created: Scalars['String']['output'];
  data: ShoppingListData;
  expiration: Scalars['String']['output'];
};

export type ShoppingListData = {
  __typename: 'ShoppingListData';
  creatorName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  items: Array<ShoppingListDataItem>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ShoppingListDataItem = {
  __typename: 'ShoppingListDataItem';
  comment?: Maybe<Scalars['String']['output']>;
  product?: Maybe<ProductOrVariant>;
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
};

export type ShoppingListDataItemInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  sku: Scalars['String']['input'];
};

export type SmallOrderSurcharge = {
  __typename: 'SmallOrderSurcharge';
  /** Price of shipping */
  price?: Maybe<Scalars['Float']['output']>;
  /** VAT For the delivery charge */
  vatAmount?: Maybe<Scalars['Float']['output']>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SourceDeliveredQuantity = {
  __typename: 'SourceDeliveredQuantity';
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deliveredQuantity: Scalars['String']['output'];
  id: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  positionId: Scalars['String']['output'];
  salesId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type SourceDelivery = {
  __typename: 'SourceDelivery';
  address: Scalars['String']['output'];
  carrier: Scalars['String']['output'];
  city: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  customerNumber: Scalars['Int']['output'];
  deliveryDate: Scalars['DateTime']['output'];
  deliveryNoteId: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  packingSlipId: Scalars['String']['output'];
  postbox?: Maybe<Scalars['String']['output']>;
  postcode: Scalars['String']['output'];
  salesId: Scalars['String']['output'];
  shipment: Scalars['String']['output'];
  trackingNumber: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamCreateDate: Scalars['DateTime']['output'];
  upstreamModified: Scalars['DateTime']['output'];
};

export type SourceOrderDocument = {
  __typename: 'SourceOrderDocument';
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  documentNr: Scalars['String']['output'];
  documentType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modifiedDate: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  positionId: Scalars['String']['output'];
  recId: Scalars['String']['output'];
  recIdDocument: Scalars['String']['output'];
  salesId: Scalars['String']['output'];
  stornoStatus: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamUpdateDate: Scalars['DateTime']['output'];
};

export type SourceSalesHeader = {
  __typename: 'SourceSalesHeader';
  buyerOrderNumber: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  customerNumber: Scalars['String']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  externalOrderNumber: Scalars['String']['output'];
  id: Scalars['String']['output'];
  modifiedDate: Scalars['String']['output'];
  orderDate: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  orderStatus: Scalars['String']['output'];
  orderType: Scalars['String']['output'];
  preorderBackorderDelete: Scalars['String']['output'];
  salesId: Scalars['String']['output'];
  salesOriginId: Scalars['String']['output'];
  stagingHeaderRecId: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamUpdateDate: Scalars['DateTime']['output'];
};

export type SourceSalesLine = {
  __typename: 'SourceSalesLine';
  articleNumber: Scalars['String']['output'];
  automaticReservation: Scalars['Boolean']['output'];
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  deliveryMode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  itemStatus: Scalars['String']['output'];
  location: Scalars['String']['output'];
  modifiedDate: Scalars['String']['output'];
  orderDate: Scalars['String']['output'];
  orderLineStatus: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  place: Scalars['String']['output'];
  positionId: Scalars['String']['output'];
  preorderBackorderNoDelete: Scalars['String']['output'];
  qtyOnOrder: Scalars['String']['output'];
  qtyOpen: Scalars['String']['output'];
  qtyOrdered: Scalars['String']['output'];
  salesId: Scalars['String']['output'];
  shippingDateRequested: Scalars['String']['output'];
  site: Scalars['String']['output'];
  stockAvailableDelay: Scalars['String']['output'];
  stockAvailableLater: Scalars['String']['output'];
  stockAvailableNow: Scalars['String']['output'];
  stockAvailableSoon: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  upstreamUpdateDate: Scalars['DateTime']['output'];
};

export enum StatTimePeriod {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year'
}

export type Stats = {
  __typename: 'Stats';
  addressCount: Scalars['Int']['output'];
  attributeCount: Scalars['Int']['output'];
  brandCount: Scalars['Int']['output'];
  categoryCount: Scalars['Int']['output'];
  customerCount: Scalars['Int']['output'];
  markedNodeCount: Scalars['Int']['output'];
  metaProductCount: Scalars['Int']['output'];
  metaProductLinkCount: Scalars['Int']['output'];
  nodeCount: Scalars['Int']['output'];
  onlineDealersCount: Scalars['Int']['output'];
  orderCount: Scalars['Int']['output'];
  outdatedNodesCount: Scalars['Int']['output'];
  preorderCount: Scalars['Int']['output'];
  productPricingCount: Scalars['Int']['output'];
  productsCount: Scalars['Int']['output'];
  remoteUserPermissionCount?: Maybe<Scalars['Int']['output']>;
  stockQuantities: Scalars['Int']['output'];
  storeCount: Scalars['Int']['output'];
  taxCodeCount: Scalars['Int']['output'];
  unmappedNodesCount: Scalars['Int']['output'];
  updateOnDemandCount: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
  vehicleCount: Scalars['Int']['output'];
  vehicleProductLinkCount: Scalars['Int']['output'];
};

export type StatsResult = {
  __typename: 'StatsResult';
  last24HOrders: OrderStats;
  last24HPreorders: OrderStats;
  lastFetched: Scalars['DateTime']['output'];
};

export type StockBranch = {
  __typename: 'StockBranch';
  id: Scalars['String']['output'];
  isMainStock: Scalars['Boolean']['output'];
  name: LocalizedField;
  /** @deprecated Use `name` field */
  nameDE: Scalars['String']['output'];
  /** @deprecated Use `name` field */
  nameEN: Scalars['String']['output'];
  /** @deprecated Use `name` field */
  nameFR: Scalars['String']['output'];
  /** @deprecated Use `name` field */
  nameIT: Scalars['String']['output'];
};

export type StockQuantity = {
  __typename: 'StockQuantity';
  location: Scalars['String']['output'];
  nextAvailability: GeneralNextAvailabilityStatus;
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  store: Scalars['ID']['output'];
};

export type Store = {
  __typename: 'Store';
  baseUrl?: Maybe<Scalars['String']['output']>;
  cartSharingEnabled: Scalars['Boolean']['output'];
  companyCode: Scalars['String']['output'];
  companyInfo?: Maybe<CompanyInfo>;
  companyName: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  currency: Currency;
  customerOnboardingUrl?: Maybe<Scalars['String']['output']>;
  dealerSearchEnabled: Scalars['Boolean']['output'];
  features: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  /** Debug only */
  index: Scalars['Int']['output'];
  languages: Array<Maybe<StoreLanguage>>;
  orderCommentsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Please use currentPreorderSeason */
  preorderEnabled?: Maybe<Scalars['Boolean']['output']>;
  semiFinalPriceForCartBrandTotals: Scalars['Boolean']['output'];
  smallOrderSurchargeThreshold?: Maybe<Scalars['String']['output']>;
  sparePartSearchEnabled: Scalars['Boolean']['output'];
  stockListEnabledCategories: Array<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  useEmailAddressLabelForUsername?: Maybe<Scalars['Boolean']['output']>;
};

export type StoreEntity = {
  __typename: 'StoreEntity';
  baseUrl?: Maybe<Scalars['String']['output']>;
  blacklistedPaymentTerms: Scalars['JSON']['output'];
  branches: Array<CompanyBranch>;
  cartSharingEnabled: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  companyCode: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  country: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  customDealerStartingDateAttribute?: Maybe<Scalars['String']['output']>;
  customerOnboardingUrl?: Maybe<Scalars['String']['output']>;
  dealerSearchEnabled: Scalars['Boolean']['output'];
  deleted?: Maybe<Scalars['DateTime']['output']>;
  dropshipmentAllowed: Scalars['Boolean']['output'];
  features: StoreFeature;
  id: Scalars['Int']['output'];
  languages: Array<StoreLanguage>;
  name: Scalars['String']['output'];
  orderCommentsEnabled: Scalars['Boolean']['output'];
  productVisibleIfPresentInAnyOtherStore: Scalars['Boolean']['output'];
  rootCategoryId: Scalars['Int']['output'];
  segments: Array<Scalars['String']['output']>;
  senderEmail?: Maybe<Scalars['String']['output']>;
  sparePartSearchEnabled: Scalars['Boolean']['output'];
  stockListEnabledCategories: Array<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  url: Scalars['String']['output'];
  useEmailAddressLabelForUsername: Scalars['Boolean']['output'];
  webFlagRequired: Scalars['Boolean']['output'];
};

export type StoreFeature = {
  __typename: 'StoreFeature';
  availabilityFilter?: Maybe<Scalars['Boolean']['output']>;
  checkoutPaymentDueDate?: Maybe<Scalars['Boolean']['output']>;
  customBrandDivisions?: Maybe<CustomBrandDivisions>;
  customBrandLogos?: Maybe<CustomBrandLogos>;
  customCatalog?: Maybe<CustomCatalog>;
  customFalseDataReportNotificationRecipient?: Maybe<CustomFalseDataReportNotificationRecipient>;
  datatransOnlinePayment?: Maybe<DatatransOnlinePayment>;
  featuredCategories?: Maybe<FeatureCategories>;
  specialPriceListDownload?: Maybe<Scalars['Boolean']['output']>;
};

export type StoreFeatureUpdateInput = {
  availabilityFilter?: InputMaybe<Scalars['Boolean']['input']>;
  checkoutPaymentDueDate?: InputMaybe<Scalars['Boolean']['input']>;
  customBrandDivisions?: InputMaybe<CustomBrandDivisionsInput>;
  customBrandLogos?: InputMaybe<CustomBrandLogosInput>;
  customCatalog?: InputMaybe<CustomCatalogInput>;
  customFalseDataReportNotificationRecipient?: InputMaybe<CustomFalseDataReportNotificationRecipientInput>;
  datatransOnlinePayment?: InputMaybe<DatatransOnlinePaymentInput>;
  featuredCategories?: InputMaybe<FeatureCategoriesInput>;
  specialPriceListDownload?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StoreLanguage = {
  __typename: 'StoreLanguage';
  language: Language;
  locale?: Maybe<Scalars['String']['output']>;
};

export type StoreLanguageInput = {
  language: Language;
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type StorePreorderSeason = {
  __typename: 'StorePreorderSeason';
  cmsRootFolder?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  deliveryEnd: Scalars['DateTime']['output'];
  deliveryStart: Scalars['DateTime']['output'];
  disallowNonPreorderProducts: Scalars['Boolean']['output'];
  discountTiers: Array<PreorderSeasonDiscountTier>;
  distributionChannel?: Maybe<Scalars['String']['output']>;
  employeeNotificationTargets: Array<Scalars['Int']['output']>;
  end: Scalars['DateTime']['output'];
  futurePriceStartDate: Scalars['NaiveDate']['output'];
  id: Scalars['Int']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: LocalizedField;
  notificationTargets: Array<Scalars['String']['output']>;
  preorderDiscounts: Array<PreorderSeasonPreorderDiscount>;
  rebates: Array<CustomerPricingRebate>;
  segments: Array<Scalars['String']['output']>;
  specialPrices: Array<CustomerPricingSpecialPrice>;
  start: Scalars['DateTime']['output'];
  storeId: Scalars['Int']['output'];
  suppressNonPreorderPricing: Scalars['Boolean']['output'];
  tieredDiscounts: Array<PreorderSeasonTieredDiscount>;
  updated: Scalars['DateTime']['output'];
};

export type StorePreorderSeasonModelSort = {
  direction: SortDirection;
  field: StorePreorderSeasonSortField;
};

export enum StorePreorderSeasonSortField {
  Created = 'created',
  DeliveryEnd = 'deliveryEnd',
  DeliveryStart = 'deliveryStart',
  DistributionChannel = 'distributionChannel',
  End = 'end',
  Id = 'id',
  NameDe = 'nameDe',
  NameEn = 'nameEn',
  NameFr = 'nameFr',
  NameIt = 'nameIt',
  Start = 'start',
  Updated = 'updated'
}

export type StoreResult = {
  __typename: 'StoreResult';
  items: Array<StoreEntity>;
  total: Scalars['Int']['output'];
};

export enum SupportAttachmentType {
  Document = 'document',
  Excel = 'excel',
  Image = 'image',
  Pdf = 'pdf',
  Video = 'video',
  Word = 'word'
}

export type SupportInternalNoteEvent = {
  __typename: 'SupportInternalNoteEvent';
  attachments?: Maybe<Array<SupportTicketEventAttachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

export type SupportMessageEvent = {
  __typename: 'SupportMessageEvent';
  attachments?: Maybe<Array<SupportTicketEventAttachment>>;
  comment?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

export type SupportTicket = {
  __typename: 'SupportTicket';
  /** Query user information of the user who is assigned to this ticket. */
  assignee?: Maybe<UserInfo>;
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  /**
   * Query user information of the user who submitted the ticket.
   *
   * - If the ticket was submitted by customer themself, this will return the user corresponding to the customer.
   * - If the ticket was submitted by a sales rep, this will return the user corresponding to the sales rep.
   */
  creator?: Maybe<UserInfo>;
  /** Query customer information for the customer who this ticket is for. */
  customer: SearchCustomerResult;
  customerContactEmail?: Maybe<Scalars['String']['output']>;
  customerContactName?: Maybe<Scalars['String']['output']>;
  customerContactPhone?: Maybe<Scalars['String']['output']>;
  customerReference?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  events: Array<SupportTicketEvent>;
  fromAddress?: Maybe<TicketFromAddress>;
  id: Scalars['Int']['output'];
  informationRequested: Scalars['Boolean']['output'];
  items: Array<SupportTicketItem>;
  language: Scalars['String']['output'];
  opened?: Maybe<Scalars['DateTime']['output']>;
  productClassId?: Maybe<Scalars['Int']['output']>;
  returnNoteUrl?: Maybe<Scalars['String']['output']>;
  searchKeywords: Scalars['String']['output'];
  shipmentRequested: Scalars['Boolean']['output'];
  shippingLabelUrl?: Maybe<Scalars['String']['output']>;
  status: SupportTicketStatus;
  type: SupportTicketType;
  updated: Scalars['DateTime']['output'];
  uploadPermissionToken: Scalars['String']['output'];
};


export type SupportTicketReturnNoteUrlArgs = {
  viewMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SupportTicketShippingLabelUrlArgs = {
  viewMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SupportTicketAssignmentChangeNotification = {
  __typename: 'SupportTicketAssignmentChangeNotification';
  by: UserBasicInfo;
  comment?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  ticketId: Scalars['Int']['output'];
  to?: Maybe<Scalars['String']['output']>;
};

export type SupportTicketCertificateOfGuaranteeDocumentEvent = {
  __typename: 'SupportTicketCertificateOfGuaranteeDocumentEvent';
  attachments: Array<SupportTicketEventAttachment>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

/** A ticket event entry that can be used to represent a chat message or attachments upload or both. */
export type SupportTicketEvent = SupportInternalNoteEvent | SupportMessageEvent | SupportTicketCertificateOfGuaranteeDocumentEvent | SupportTicketImageOfFaultDocumentEvent | SupportTicketImageOfProductDocumentEvent | SupportTicketImageOfSerialNumberDocumentEvent | SupportTicketItemRejectionEvent | SupportTicketItemResolutionEvent | SupportTicketOtherDocumentEvent | SupportTicketUpdateEvent;

export type SupportTicketEventAttachment = DeletedSupportTicketEventAttachmentItem | SupportTicketEventAttachmentItem;

export type SupportTicketEventAttachmentItem = {
  __typename: 'SupportTicketEventAttachmentItem';
  attachmentType: SupportAttachmentType;
  index: Scalars['Int']['output'];
  processing: Scalars['Boolean']['output'];
  sourceFile: Scalars['String']['output'];
  sourceFileName: Scalars['String']['output'];
  sourceFileSize: Scalars['Int']['output'];
  thumbnail?: Maybe<Scalars['String']['output']>;
  webSafeFile?: Maybe<Scalars['String']['output']>;
};


export type SupportTicketEventAttachmentItemSourceFileArgs = {
  viewMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SupportTicketEventAttachmentItemThumbnailArgs = {
  viewMode?: InputMaybe<Scalars['Boolean']['input']>;
};


export type SupportTicketEventAttachmentItemWebSafeFileArgs = {
  viewMode?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SupportTicketEventDocumentTypes {
  CertificateOfGuarantee = 'certificateOfGuarantee',
  ImageOfFault = 'imageOfFault',
  ImageOfProduct = 'imageOfProduct',
  ImageOfSerialNumber = 'imageOfSerialNumber',
  OtherDocuments = 'otherDocuments'
}

export type SupportTicketEventUser = {
  __typename: 'SupportTicketEventUser';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export enum SupportTicketExportFileFormat {
  Csv = 'csv',
  Xlsx = 'xlsx'
}

export type SupportTicketImageOfFaultDocumentEvent = {
  __typename: 'SupportTicketImageOfFaultDocumentEvent';
  attachments: Array<SupportTicketEventAttachment>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

export type SupportTicketImageOfProductDocumentEvent = {
  __typename: 'SupportTicketImageOfProductDocumentEvent';
  attachments: Array<SupportTicketEventAttachment>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

export type SupportTicketImageOfSerialNumberDocumentEvent = {
  __typename: 'SupportTicketImageOfSerialNumberDocumentEvent';
  attachments: Array<SupportTicketEventAttachment>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

export type SupportTicketItem = SupportTicketManualItem | SupportTicketReturnItem | SupportTicketWarrantyOrRepairItem;

/** A Combination of all reasons from [`ReturnReason`] and [`ComplaintReason`]. */
export enum SupportTicketItemReason {
  None = 'NONE',
  ArticleIncomplete = 'articleIncomplete',
  LateDelivery = 'lateDelivery',
  NotFitting = 'notFitting',
  TransportDamage = 'transportDamage',
  WrongDescription = 'wrongDescription',
  WrongOrder = 'wrongOrder',
  WrongQuantity = 'wrongQuantity'
}

export type SupportTicketItemRejectionEvent = {
  __typename: 'SupportTicketItemRejectionEvent';
  attachments?: Maybe<Array<SupportTicketEventAttachment>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  salesLines: Array<SupportTicketItem>;
  user: SupportTicketEventUser;
};

/**
 * Possible resolutions for a ticket item entry. A Combination of all resolutions from
 * [`ReturnResolution`], [`WarrantyResolution`], [`RepairResolution`], and [`ComplaintResolution`].
 */
export enum SupportTicketItemResolution {
  None = 'NONE',
  CreditNote = 'creditNote',
  PartiallyRejected = 'partiallyRejected',
  Quotation = 'quotation',
  Rejected = 'rejected',
  Repair = 'repair',
  Replacement = 'replacement'
}

export type SupportTicketItemResolutionChangeNotification = {
  __typename: 'SupportTicketItemResolutionChangeNotification';
  by: UserBasicInfo;
  reason?: Maybe<Scalars['String']['output']>;
  resolution: SupportTicketItemResolution;
  salesLines: Array<SupportTicketItem>;
  ticketId: Scalars['Int']['output'];
};

export type SupportTicketItemResolutionEvent = {
  __typename: 'SupportTicketItemResolutionEvent';
  attachments?: Maybe<Array<SupportTicketEventAttachment>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  resolution: SupportTicketItemResolution;
  salesLines: Array<SupportTicketItem>;
  user: SupportTicketEventUser;
};

export type SupportTicketItemUpdateInput = {
  manual?: InputMaybe<SupportTicketManualItemUpdateInput>;
  return?: InputMaybe<SupportTicketReturnItemUpdateInput>;
  warrantyOrRepair?: InputMaybe<SupportTicketWarrantyOrRepairItemUpdateInput>;
};

/** A ticket item entry. Each entry is a unique combination of sku, upstream_rec_id and reason. */
export type SupportTicketManualItem = {
  __typename: 'SupportTicketManualItem';
  brandName?: Maybe<Scalars['String']['output']>;
  /** Date when the order was placed. */
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  /** Automatically populated order number */
  orderNumber?: Maybe<Scalars['String']['output']>;
  product?: Maybe<ProductSummary>;
  productName: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  /** Mark sales line as received by the after sales team. */
  received?: Maybe<Scalars['Boolean']['output']>;
  /** Resolution for this item. */
  resolution?: Maybe<SupportTicketItemResolution>;
  /** Serial number of product. */
  serialNumber: Scalars['String']['output'];
  /** SKU of the product in sales line. */
  sku?: Maybe<Scalars['String']['output']>;
};

export type SupportTicketManualItemUpdateInput = {
  brandName?: InputMaybe<Scalars['String']['input']>;
  orderDate?: InputMaybe<Scalars['DateTime']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  productName: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  received?: InputMaybe<Scalars['Boolean']['input']>;
  resolution?: InputMaybe<SupportTicketItemResolution>;
  serialNumber: Scalars['String']['input'];
  sku?: InputMaybe<Scalars['String']['input']>;
};

export type SupportTicketMessageTemplate = {
  __typename: 'SupportTicketMessageTemplate';
  companyCode: Scalars['String']['output'];
  content: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
};

export type SupportTicketMessageTemplateModelSort = {
  direction: SortDirection;
  field: SupportTicketMessageTemplateSortField;
};

export enum SupportTicketMessageTemplateSortField {
  CompanyCode = 'companyCode',
  Created = 'created',
  Id = 'id',
  Language = 'language',
  Name = 'name',
  Updated = 'updated'
}

export type SupportTicketModelSort = {
  direction: SortDirection;
  field: SupportTicketSortField;
};

export type SupportTicketNewDocumentNotification = {
  __typename: 'SupportTicketNewDocumentNotification';
  attachments: Array<Scalars['String']['output']>;
  by: UserBasicInfo;
  documentType: SupportTicketEventDocumentTypes;
  eventId: Scalars['String']['output'];
  ticketId: Scalars['Int']['output'];
};

export type SupportTicketNewInternalNoteNotification = {
  __typename: 'SupportTicketNewInternalNoteNotification';
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  by: UserBasicInfo;
  eventId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  ticketId: Scalars['Int']['output'];
};

export type SupportTicketNewMessageNotification = {
  __typename: 'SupportTicketNewMessageNotification';
  attachments?: Maybe<Array<Scalars['String']['output']>>;
  by: UserBasicInfo;
  eventId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  ticketId: Scalars['Int']['output'];
};

export type SupportTicketNotificationFilters = {
  ticketIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type SupportTicketOtherDocumentEvent = {
  __typename: 'SupportTicketOtherDocumentEvent';
  attachments: Array<SupportTicketEventAttachment>;
  created: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  user: SupportTicketEventUser;
};

/** A ticket item entry. Each entry is a unique combination of sku, upstream_rec_id and reason. */
export type SupportTicketReturnItem = {
  __typename: 'SupportTicketReturnItem';
  /** Automatically populated brand name, usually used for filtering. */
  brandName: Scalars['String']['output'];
  /** Automatically populated confirmed quantity from the sales line. */
  confirmedQuantity: Scalars['Int']['output'];
  /** Date when the order was placed. */
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  /** Automatically populated order number */
  orderNumber?: Maybe<Scalars['String']['output']>;
  /** Original quantity submitted by the user. Useful for facilitating returns. */
  originalQuantity?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<ProductSummary>;
  /** Automatically populated product name, usually used for filtering. */
  productName: LocalizedField;
  /** Quantity of product in this sales line. */
  quantity: Scalars['Int']['output'];
  /** Reason for return in case of return ticket. */
  reason: SupportTicketItemReason;
  /** Mark sales line as received by the after sales team. */
  received?: Maybe<Scalars['Boolean']['output']>;
  /** Resolution for this item. */
  resolution?: Maybe<SupportTicketItemResolution>;
  /** Serial number of product. */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** SKU of the product in sales line. */
  sku: Scalars['String']['output'];
  /** Record ID of the sales line. */
  upstreamRecId: Scalars['String']['output'];
};

export type SupportTicketReturnItemUpdateInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  reason: SupportTicketItemReason;
  received?: InputMaybe<Scalars['Boolean']['input']>;
  resolution?: InputMaybe<SupportTicketItemResolution>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  upstreamRecId: Scalars['String']['input'];
};

export type SupportTicketSort = {
  created?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  opened?: InputMaybe<SortDirection>;
};

export enum SupportTicketSortField {
  Assignee = 'assignee',
  Created = 'created',
  CustomerNumber = 'customerNumber',
  Id = 'id',
  Opened = 'opened',
  Status = 'status',
  Type = 'type',
  Updated = 'updated'
}

export enum SupportTicketStatus {
  ActionRequired = 'actionRequired',
  AwaitingReturn = 'awaitingReturn',
  Closed = 'closed',
  Draft = 'draft',
  New = 'new',
  OnHold = 'onHold',
  Processing = 'processing'
}

export type SupportTicketStatusChangeNotification = {
  __typename: 'SupportTicketStatusChangeNotification';
  by: UserBasicInfo;
  comment?: Maybe<Scalars['String']['output']>;
  from: SupportTicketStatus;
  ticketId: Scalars['Int']['output'];
  to: SupportTicketStatus;
};

export type SupportTicketSummary = {
  __typename: 'SupportTicketSummary';
  /** Query user information of the user who is assigned to this ticket. */
  assignee?: Maybe<UserInfo>;
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  /**
   * Query user information of the user who submitted the ticket.
   *
   * - If the ticket was submitted by customer themself, this will return the user corresponding to the customer.
   * - If the ticket was submitted by a sales rep, this will return the user corresponding to the sales rep.
   * @deprecated This functionality is no longer needed, so it is no longer maintained.
   */
  creator?: Maybe<UserInfo>;
  /** Query customer information for the customer who this ticket is for. */
  customer: SearchCustomerResult;
  customerReference?: Maybe<Scalars['String']['output']>;
  events: Array<SupportTicketEvent>;
  id: Scalars['Int']['output'];
  items: Array<SupportTicketItem>;
  language: Scalars['String']['output'];
  opened?: Maybe<Scalars['DateTime']['output']>;
  productClassId?: Maybe<Scalars['Int']['output']>;
  status: SupportTicketStatus;
  type: SupportTicketType;
  updated: Scalars['DateTime']['output'];
};

export enum SupportTicketType {
  Complaint = 'complaint',
  Inquiry = 'inquiry',
  Repair = 'repair',
  Return = 'return',
  Warranty = 'warranty'
}

export type SupportTicketTypeChangeNotification = {
  __typename: 'SupportTicketTypeChangeNotification';
  by: UserBasicInfo;
  eventId: Scalars['String']['output'];
  from: SupportTicketType;
  ticketId: Scalars['Int']['output'];
  to: SupportTicketType;
};

export type SupportTicketUpdateEvent = {
  __typename: 'SupportTicketUpdateEvent';
  created: Scalars['DateTime']['output'];
  field: Scalars['String']['output'];
  from?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
  to?: Maybe<Scalars['JSON']['output']>;
  user: SupportTicketEventUser;
};

/** A ticket item entry. Each entry is a unique combination of sku, upstream_rec_id and reason. */
export type SupportTicketWarrantyOrRepairItem = {
  __typename: 'SupportTicketWarrantyOrRepairItem';
  /** Automatically populated brand name, usually used for filtering. */
  brandName: Scalars['String']['output'];
  /** Automatically populated confirmed quantity from the sales line. */
  confirmedQuantity: Scalars['Int']['output'];
  /** Date when the order was placed. */
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  /** Automatically populated order number */
  orderNumber?: Maybe<Scalars['String']['output']>;
  product?: Maybe<ProductSummary>;
  /** Automatically populated product name, usually used for filtering. */
  productName: LocalizedField;
  quantity: Scalars['Int']['output'];
  /** Mark sales line as received by the after sales team. */
  received?: Maybe<Scalars['Boolean']['output']>;
  /** Resolution for this item. */
  resolution?: Maybe<SupportTicketItemResolution>;
  /** Serial number of product. */
  serialNumber?: Maybe<Scalars['String']['output']>;
  /** SKU of the product in sales line. */
  sku: Scalars['String']['output'];
  /** Record ID of the sales line. */
  upstreamRecId: Scalars['String']['output'];
};

export type SupportTicketWarrantyOrRepairItemUpdateInput = {
  quantity?: InputMaybe<Scalars['Int']['input']>;
  received?: InputMaybe<Scalars['Boolean']['input']>;
  resolution?: InputMaybe<SupportTicketItemResolution>;
  serialNumber?: InputMaybe<Scalars['String']['input']>;
  sku: Scalars['String']['input'];
  upstreamRecId: Scalars['String']['input'];
};

export type SupportTicketsResult = {
  __typename: 'SupportTicketsResult';
  items: Array<SupportTicketSummary>;
  total: Scalars['Int']['output'];
};

export type SystemMaintenance = {
  __typename: 'SystemMaintenance';
  created: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  end?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  lastUpdatedBy: Scalars['String']['output'];
  messageDe: Scalars['String']['output'];
  messageEn: Scalars['String']['output'];
  messageFr: Scalars['String']['output'];
  messageIt: Scalars['String']['output'];
  start?: Maybe<Scalars['DateTime']['output']>;
  type: SystemMaintenanceTypeEnum;
  updated: Scalars['DateTime']['output'];
};

export type SystemMaintenanceModelSort = {
  direction: SortDirection;
  field: SystemMaintenanceSortField;
};

export type SystemMaintenanceResult = {
  __typename: 'SystemMaintenanceResult';
  items: Array<SystemMaintenance>;
  total: Scalars['Int']['output'];
};

export enum SystemMaintenanceSortField {
  Created = 'created',
  End = 'end',
  Id = 'id',
  Start = 'start',
  Type = 'type',
  Updated = 'updated'
}

export enum SystemMaintenanceTypeEnum {
  Full = 'full',
  Partial = 'partial'
}

export type SystemReloadEntry = {
  __typename: 'SystemReloadEntry';
  created: Scalars['String']['output'];
  status: SystemReloadEntryStatus;
};

export enum SystemReloadEntryStatus {
  Completed = 'completed',
  Error = 'error',
  Requested = 'requested',
  Running = 'running'
}

export type TaskRun = {
  __typename: 'TaskRun';
  args: Scalars['JSON']['output'];
  created: Scalars['DateTime']['output'];
  ecsTaskArn?: Maybe<Scalars['String']['output']>;
  ecsTaskId?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  logUrl?: Maybe<Scalars['String']['output']>;
  output?: Maybe<Scalars['String']['output']>;
  start: Scalars['DateTime']['output'];
  stats: Scalars['JSON']['output'];
  status: RunStatusEnum;
  type: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  user?: Maybe<UserInfo>;
};


export type TaskRunOutputArgs = {
  lineLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskRunModelSort = {
  direction: SortDirection;
  field: TaskRunSortField;
};

export enum TaskRunSortField {
  Created = 'created',
  End = 'end',
  Id = 'id',
  Start = 'start',
  Status = 'status',
  Updated = 'updated',
  UserId = 'userId'
}

export type TaxCode = {
  __typename: 'TaxCode';
  articleCode?: Maybe<Scalars['String']['output']>;
  companyCode: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  groupId: Scalars['String']['output'];
  groupName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  taxAccountingGroup?: Maybe<Scalars['String']['output']>;
  taxCurrencyCode?: Maybe<Scalars['String']['output']>;
  taxName?: Maybe<Scalars['String']['output']>;
  taxPeriod?: Maybe<Scalars['String']['output']>;
  taxValue: Scalars['Decimal']['output'];
  updated: Scalars['DateTime']['output'];
};

export type TaxCodeResult = {
  __typename: 'TaxCodeResult';
  items: Array<TaxCode>;
  total: Scalars['Int']['output'];
};

export type TestUserAddressInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  isDefault: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type TicketFromAddress = {
  __typename: 'TicketFromAddress';
  address: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  postcode: Scalars['String']['output'];
};

export type TicketFromAddressInput = {
  address: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  postcode: Scalars['String']['input'];
};

export type TicketMessageTemplateResults = {
  __typename: 'TicketMessageTemplateResults';
  items: Array<SupportTicketMessageTemplate>;
  total: Scalars['Int']['output'];
};

export type TicketSchemaItem = {
  __typename: 'TicketSchemaItem';
  availableDocuments: Array<AvailableDocuments>;
  productClasses: Array<ProductClass>;
  ticketType: SupportTicketType;
};

export type TicketSchemaResult = {
  __typename: 'TicketSchemaResult';
  ticketTypes: Array<TicketSchemaItem>;
};

export type UpdateCatalogBrandInput = {
  deleted?: InputMaybe<Scalars['DateTime']['input']>;
  excludeAsKeyDealers?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['Int']['input'];
  includeAsKeyDealers?: InputMaybe<Scalars['JSON']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  minimumKeyDealerTurnovers?: InputMaybe<Scalars['JSON']['input']>;
};

export type UpdateCompanyInput = {
  branches?: InputMaybe<Array<CompanyBranchInput>>;
  code?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<CompanyDetailsInput>;
  finalPriceForCartBrandTotals?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  manualPreorderReservation?: InputMaybe<Scalars['Boolean']['input']>;
  manualPrepayCustomerOrderReservation?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  semiFinalPriceForCartBrandTotals?: InputMaybe<Scalars['Boolean']['input']>;
  smallOrderSurchargeThreshold?: InputMaybe<Scalars['Decimal']['input']>;
  sourceName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerCartInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  default?: InputMaybe<Scalars['Boolean']['input']>;
  defaultDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressId?: InputMaybe<Scalars['String']['input']>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  internalNote?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<CustomerCartItemInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  paymentDueDate?: InputMaybe<Scalars['String']['input']>;
  paymentTermId?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  shippingMethod?: InputMaybe<Scalars['String']['input']>;
  stockLocation?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerOnlineDealerInput = {
  brandUrls?: InputMaybe<Scalars['JSON']['input']>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['Int']['input'];
};

export type UpdateCustomerProductOrderRestrictionInput = {
  brands?: InputMaybe<Array<Scalars['String']['input']>>;
  companyId?: InputMaybe<Scalars['Int']['input']>;
  conditions?: InputMaybe<Scalars['ProductOrderRestrictionConditions']['input']>;
  customerSegments?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['Int']['input'];
  messageDe?: InputMaybe<Scalars['String']['input']>;
  messageEn?: InputMaybe<Scalars['String']['input']>;
  messageFr?: InputMaybe<Scalars['String']['input']>;
  messageIt?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMetaProductInput = {
  categoryDe?: InputMaybe<Scalars['String']['input']>;
  categoryEn?: InputMaybe<Scalars['String']['input']>;
  categoryFr?: InputMaybe<Scalars['String']['input']>;
  categoryIt?: InputMaybe<Scalars['String']['input']>;
  categoryValue?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  distributionChannels?: InputMaybe<Array<Scalars['String']['input']>>;
  filesOutdated?: InputMaybe<Scalars['Boolean']['input']>;
  futureVisibilityCompanyDates?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  images?: InputMaybe<Scalars['JSON']['input']>;
  manufacturerName?: InputMaybe<Scalars['String']['input']>;
  manufacturingEndYear?: InputMaybe<Scalars['Int']['input']>;
  manufacturingStartYear?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  publicFutureVisibilityCompanyDates?: InputMaybe<Scalars['JSON']['input']>;
  replacementParts?: InputMaybe<Array<Scalars['String']['input']>>;
  searchKeywordsDe?: InputMaybe<Scalars['String']['input']>;
  searchKeywordsEn?: InputMaybe<Scalars['String']['input']>;
  searchKeywordsFr?: InputMaybe<Scalars['String']['input']>;
  searchKeywordsIt?: InputMaybe<Scalars['String']['input']>;
  skuList?: InputMaybe<Scalars['String']['input']>;
  sparePartDocument?: InputMaybe<Scalars['JSON']['input']>;
  sparePartSkuPrefix?: InputMaybe<Scalars['String']['input']>;
  specificationAttributes?: InputMaybe<Scalars['JSON']['input']>;
  stores?: InputMaybe<Array<Scalars['Int']['input']>>;
  typeDe?: InputMaybe<Scalars['String']['input']>;
  typeEn?: InputMaybe<Scalars['String']['input']>;
  typeFr?: InputMaybe<Scalars['String']['input']>;
  typeIt?: InputMaybe<Scalars['String']['input']>;
  typeValue?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  visibility?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateNodeInput = {
  id: Scalars['Int']['input'];
  outdated?: InputMaybe<Scalars['Boolean']['input']>;
  unmapped?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateStoreInput = {
  baseUrl?: InputMaybe<Scalars['String']['input']>;
  blacklistedPaymentTerms?: InputMaybe<Scalars['JSON']['input']>;
  cartSharingEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customDealerStartingDateAttribute?: InputMaybe<Scalars['String']['input']>;
  customerOnboardingUrl?: InputMaybe<Scalars['String']['input']>;
  dealerSearchEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  deleted?: InputMaybe<Scalars['DateTime']['input']>;
  dropshipmentAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['Int']['input'];
  languages?: InputMaybe<Array<StoreLanguageInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderCommentsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  productVisibleIfPresentInAnyOtherStore?: InputMaybe<Scalars['Boolean']['input']>;
  rootCategoryId?: InputMaybe<Scalars['Int']['input']>;
  segments?: InputMaybe<Array<Scalars['String']['input']>>;
  senderEmail?: InputMaybe<Scalars['String']['input']>;
  sparePartSearchEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  stockListEnabledCategories?: InputMaybe<Array<Scalars['String']['input']>>;
  supportEmail?: InputMaybe<Scalars['String']['input']>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  useEmailAddressLabelForUsername?: InputMaybe<Scalars['Boolean']['input']>;
  webFlagRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateStorePreorderSeasonInput = {
  cmsRootFolder?: InputMaybe<Scalars['String']['input']>;
  deliveryEnd?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryStart?: InputMaybe<Scalars['DateTime']['input']>;
  disallowNonPreorderProducts?: InputMaybe<Scalars['Boolean']['input']>;
  discountTiers?: InputMaybe<Array<PreorderSeasonDiscountTierInput>>;
  distributionChannel?: InputMaybe<Scalars['String']['input']>;
  employeeNotificationTargets?: InputMaybe<Array<Scalars['Int']['input']>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  futurePriceStartDate?: InputMaybe<Scalars['NaiveDate']['input']>;
  id: Scalars['Int']['input'];
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  nameDe?: InputMaybe<Scalars['String']['input']>;
  nameEn?: InputMaybe<Scalars['String']['input']>;
  nameFr?: InputMaybe<Scalars['String']['input']>;
  nameIt?: InputMaybe<Scalars['String']['input']>;
  notificationTargets?: InputMaybe<Array<Scalars['String']['input']>>;
  preorderDiscounts?: InputMaybe<Array<PreorderSeasonPreorderDiscountInput>>;
  rebates?: InputMaybe<Array<CustomerPricingRebateInput>>;
  segments?: InputMaybe<Array<Scalars['String']['input']>>;
  specialPrices?: InputMaybe<Array<CustomerPricingSpecialPriceInput>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
  suppressNonPreorderPricing?: InputMaybe<Scalars['Boolean']['input']>;
  tieredDiscounts?: InputMaybe<Array<PreorderSeasonTieredDiscountInput>>;
};

export type UpdateSupportTicketInput = {
  assignee?: InputMaybe<Scalars['String']['input']>;
  companyCode?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<Scalars['DateTime']['input']>;
  customerContactEmail?: InputMaybe<Scalars['String']['input']>;
  customerContactName?: InputMaybe<Scalars['String']['input']>;
  customerContactPhone?: InputMaybe<Scalars['String']['input']>;
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  customerReference?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  fromAddress?: InputMaybe<TicketFromAddressInput>;
  id: Scalars['Int']['input'];
  informationRequested?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  opened?: InputMaybe<Scalars['DateTime']['input']>;
  productClassId?: InputMaybe<Scalars['Int']['input']>;
  searchKeywords?: InputMaybe<Scalars['String']['input']>;
  shipmentRequested?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<SupportTicketStatus>;
  type?: InputMaybe<SupportTicketType>;
  updated?: InputMaybe<Scalars['DateTime']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSupportTicketMessageTemplateInput = {
  companyCode?: InputMaybe<Scalars['String']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSystemMaintenanceInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['Int']['input'];
  messageDe?: InputMaybe<Scalars['String']['input']>;
  messageEn?: InputMaybe<Scalars['String']['input']>;
  messageFr?: InputMaybe<Scalars['String']['input']>;
  messageIt?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<SystemMaintenanceTypeEnum>;
};

export type UpdateUserInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  customerNumber?: InputMaybe<Scalars['Int']['input']>;
  dealerPricesEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  invitedBy?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  orderNotificationEmails?: InputMaybe<Array<Scalars['String']['input']>>;
  password?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<Scalars['String']['input']>>;
  source?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['Int']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type UploadUrl = {
  __typename: 'UploadUrl';
  expires: Scalars['Int']['output'];
  fields: Array<FileMetadata>;
  method: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type UpstreamCacheItem = {
  __typename: 'UpstreamCacheItem';
  lastModified: Scalars['String']['output'];
  name: Scalars['String']['output'];
  size: Scalars['Int']['output'];
};

export type UpstreamCacheResult = {
  __typename: 'UpstreamCacheResult';
  items: Array<UpstreamCacheItem>;
};

export type User = {
  __typename: 'User';
  active: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  customerNumber?: Maybe<Scalars['Int']['output']>;
  dealerPricesEnabled: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  icId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  invitedBy?: Maybe<Scalars['String']['output']>;
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  orderNotificationEmails: Array<Scalars['String']['output']>;
  orderNotificationEnabled: Scalars['Boolean']['output'];
  permissions: Array<Scalars['String']['output']>;
  requiresPasswordChange: Scalars['Boolean']['output'];
  source: Scalars['String']['output'];
  storeId?: Maybe<Scalars['Int']['output']>;
  stores?: Maybe<Array<Scalars['Int']['output']>>;
  title?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  username: Scalars['String']['output'];
  websiteId?: Maybe<Scalars['Int']['output']>;
};

export type UserBasicInfo = {
  __typename: 'UserBasicInfo';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UserInfo = {
  __typename: 'UserInfo';
  active?: Maybe<Scalars['Boolean']['output']>;
  customerNumber?: Maybe<Scalars['Int']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<Scalars['String']['output']>>;
  source: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserLogin = {
  __typename: 'UserLogin';
  accessToken: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserLookupResult = {
  __typename: 'UserLookupResult';
  items?: Maybe<Array<Maybe<UserLookupResultItem>>>;
};

export type UserLookupResultItem = {
  __typename: 'UserLookupResultItem';
  customer?: Maybe<CustomerInfo>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type UserModelSort = {
  direction: SortDirection;
  field: UserSortField;
};

export type UserProductPrice = {
  __typename: 'UserProductPrice';
  /** Percentage of the dealer discount */
  additionalDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  /** The discount rule that was applied, null means no rule. */
  appliedDiscountRule?: Maybe<AppliedDiscountRule>;
  /** Key that can be used for caching */
  cacheKey: Scalars['String']['output'];
  /** Id of cart if the price was requested for a logged in user. */
  cartId?: Maybe<Scalars['Int']['output']>;
  /** Specifies the type of cart the price belongs to */
  cartType: CartType;
  /** Price Currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Percentage of the dealer discount, percentage difference between retailPrice and finalPrice without additional discounts */
  dealerDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Privileged field, explains the price calculation */
  explanation?: Maybe<PriceCalculationExplanation>;
  /** Final price for the user */
  finalPrice?: Maybe<Scalars['Float']['output']>;
  /** General dealer price */
  generalDealerPrice?: Maybe<Scalars['Float']['output']>;
  /** Recommended net retail / consumer price */
  netRetailPrice: Scalars['Float']['output'];
  /** Recommended retail / consumer price */
  retailPrice: Scalars['Float']['output'];
  /** If the item is on sale, the final sales percentage */
  salesDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  /** SKU of the product, useful for caching */
  sku: Scalars['String']['output'];
  /** Store which the product with teh price belongs to */
  store: Scalars['ID']['output'];
  /** VAT Rate */
  vatRate?: Maybe<Scalars['Float']['output']>;
};

export enum UserSortField {
  Created = 'created',
  CustomerNumber = 'customerNumber',
  Email = 'email',
  Id = 'id',
  Name = 'name',
  Source = 'source',
  Updated = 'updated',
  Username = 'username'
}

export type UsersResult = {
  __typename: 'UsersResult';
  items: Array<User>;
  total: Scalars['Int']['output'];
};

export type VariantCustomerPricing = {
  __typename: 'VariantCustomerPricing';
  /** Starting customer price or price for all variants */
  customerPrice: UserProductPrice;
  /** Tells if the price is a starting price or all variants have the same price */
  startingPrice: Scalars['Boolean']['output'];
};


export type VariantCustomerPricingCustomerPriceArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartType: CartType;
};


export type VariantCustomerPricingStartingPriceArgs = {
  cacheKey?: InputMaybe<Scalars['ID']['input']>;
  cartType: CartType;
};

export type VehicleFilters = {
  __typename: 'VehicleFilters';
  ccmEnd: Scalars['Int']['output'];
  ccmStart: Scalars['Int']['output'];
  manufacturerName: Array<Scalars['String']['output']>;
  model: Array<Scalars['String']['output']>;
  ratedPower: Array<Scalars['String']['output']>;
  typeAcceptanceList: Array<Scalars['String']['output']>;
  typeNumbers: Array<Scalars['String']['output']>;
};

export type VehiclesResult = {
  __typename: 'VehiclesResult';
  /** Opaque cursor that can be passed to subsequent queries to ensure result consistency. */
  cursor?: Maybe<Scalars['String']['output']>;
  /** List of vehicles in the requested page. */
  items: Array<PartialVehicle>;
  /** Total number of vehicles in the DB that match the given filter parameters (excluding pagination parameters). */
  total: Scalars['Int']['output'];
};

export type ZeroPriceIssue = {
  __typename: 'ZeroPriceIssue';
  companyCode: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sku: Scalars['String']['output'];
};

export type ContactBackorderSupportMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  store: Scalars['ID']['input'];
  language: Language;
  message: Scalars['String']['input'];
}>;


export type ContactBackorderSupportMutation = { __typename: 'Mutation', contactBackorderSupport: boolean };

export type OrderHistoryBrandsQueryVariables = Exact<{
  language: Language;
  store: Scalars['ID']['input'];
  origin?: InputMaybe<OrderOrigin>;
  search?: InputMaybe<Scalars['String']['input']>;
  orderStart?: InputMaybe<Scalars['String']['input']>;
  orderEnd?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderStatus> | OrderStatus>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  pendingOnly?: InputMaybe<Scalars['Boolean']['input']>;
  productClassId?: InputMaybe<Scalars['Int']['input']>;
  brands?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  excludeServices?: InputMaybe<Scalars['Boolean']['input']>;
  orderTypes?: InputMaybe<Array<OrderType> | OrderType>;
  deliveryOnRequest?: InputMaybe<Scalars['Boolean']['input']>;
  requestedShippingDateStart?: InputMaybe<Scalars['String']['input']>;
}>;


export type OrderHistoryBrandsQuery = { __typename: 'Query', orderItems?: { __typename: 'OrderItemListResult', brandFilterValues: Array<string> } | undefined };

export type RequestDeliveryMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  store: Scalars['ID']['input'];
  language: Language;
  quantity: Scalars['Int']['input'];
  requestedShippingDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type RequestDeliveryMutation = { __typename: 'Mutation', requestDelivery: boolean };

export type RequestQuantityChangeMutationVariables = Exact<{
  quantity: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  store: Scalars['ID']['input'];
}>;


export type RequestQuantityChangeMutation = { __typename: 'Mutation', requestQuantityChange: boolean };


export const ContactBackorderSupportDocument = gql`
    mutation contactBackorderSupport($id: Int!, $store: ID!, $language: Language!, $message: String!) {
  contactBackorderSupport(
    id: $id
    store: $store
    language: $language
    message: $message
  )
}
    `;
export type ContactBackorderSupportMutationFn = Apollo.MutationFunction<ContactBackorderSupportMutation, ContactBackorderSupportMutationVariables>;

/**
 * __useContactBackorderSupportMutationAPI__
 *
 * To run a mutation, you first call `useContactBackorderSupportMutationAPI` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactBackorderSupportMutationAPI` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactBackorderSupportMutationApi, { data, loading, error }] = useContactBackorderSupportMutationAPI({
 *   variables: {
 *      id: // value for 'id'
 *      store: // value for 'store'
 *      language: // value for 'language'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useContactBackorderSupportMutationAPI(baseOptions?: Apollo.MutationHookOptions<ContactBackorderSupportMutation, ContactBackorderSupportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactBackorderSupportMutation, ContactBackorderSupportMutationVariables>(ContactBackorderSupportDocument, options);
      }
export type ContactBackorderSupportMutationAPIHookResult = ReturnType<typeof useContactBackorderSupportMutationAPI>;
export type ContactBackorderSupportMutationResult = Apollo.MutationResult<ContactBackorderSupportMutation>;
export type ContactBackorderSupportMutationOptions = Apollo.BaseMutationOptions<ContactBackorderSupportMutation, ContactBackorderSupportMutationVariables>;
export const OrderHistoryBrandsDocument = gql`
    query orderHistoryBrands($language: Language!, $store: ID!, $origin: OrderOrigin, $search: String, $orderStart: String, $orderEnd: String, $statuses: [OrderStatus!], $page: Int, $pageSize: Int, $pendingOnly: Boolean, $productClassId: Int, $brands: [String!], $excludeServices: Boolean, $orderTypes: [OrderType!], $deliveryOnRequest: Boolean, $requestedShippingDateStart: String) {
  orderItems(
    language: $language
    store: $store
    origin: $origin
    search: $search
    orderStart: $orderStart
    orderEnd: $orderEnd
    statuses: $statuses
    page: $page
    pageSize: $pageSize
    pendingOnly: $pendingOnly
    productClassId: $productClassId
    brands: $brands
    excludeServices: $excludeServices
    orderTypes: $orderTypes
    deliveryOnRequest: $deliveryOnRequest
    requestedShippingDateStart: $requestedShippingDateStart
  ) {
    brandFilterValues
  }
}
    `;

/**
 * __useOrderHistoryBrandsQueryAPI__
 *
 * To run a query within a React component, call `useOrderHistoryBrandsQueryAPI` and pass it any options that fit your needs.
 * When your component renders, `useOrderHistoryBrandsQueryAPI` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderHistoryBrandsQueryAPI({
 *   variables: {
 *      language: // value for 'language'
 *      store: // value for 'store'
 *      origin: // value for 'origin'
 *      search: // value for 'search'
 *      orderStart: // value for 'orderStart'
 *      orderEnd: // value for 'orderEnd'
 *      statuses: // value for 'statuses'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      pendingOnly: // value for 'pendingOnly'
 *      productClassId: // value for 'productClassId'
 *      brands: // value for 'brands'
 *      excludeServices: // value for 'excludeServices'
 *      orderTypes: // value for 'orderTypes'
 *      deliveryOnRequest: // value for 'deliveryOnRequest'
 *      requestedShippingDateStart: // value for 'requestedShippingDateStart'
 *   },
 * });
 */
export function useOrderHistoryBrandsQueryAPI(baseOptions: Apollo.QueryHookOptions<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables> & ({ variables: OrderHistoryBrandsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables>(OrderHistoryBrandsDocument, options);
      }
export function useOrderHistoryBrandsLazyQueryAPI(baseOptions?: Apollo.LazyQueryHookOptions<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables>(OrderHistoryBrandsDocument, options);
        }
export function useOrderHistoryBrandsSuspenseQueryAPI(baseOptions?: Apollo.SuspenseQueryHookOptions<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables>(OrderHistoryBrandsDocument, options);
        }
export type OrderHistoryBrandsQueryAPIHookResult = ReturnType<typeof useOrderHistoryBrandsQueryAPI>;
export type OrderHistoryBrandsLazyQueryAPIHookResult = ReturnType<typeof useOrderHistoryBrandsLazyQueryAPI>;
export type OrderHistoryBrandsSuspenseQueryAPIHookResult = ReturnType<typeof useOrderHistoryBrandsSuspenseQueryAPI>;
export type OrderHistoryBrandsQueryResult = Apollo.QueryResult<OrderHistoryBrandsQuery, OrderHistoryBrandsQueryVariables>;
export const RequestDeliveryDocument = gql`
    mutation requestDelivery($id: Int!, $store: ID!, $language: Language!, $quantity: Int!, $requestedShippingDate: String) {
  requestDelivery(
    id: $id
    store: $store
    language: $language
    quantity: $quantity
    requestedShippingDate: $requestedShippingDate
  )
}
    `;
export type RequestDeliveryMutationFn = Apollo.MutationFunction<RequestDeliveryMutation, RequestDeliveryMutationVariables>;

/**
 * __useRequestDeliveryMutationAPI__
 *
 * To run a mutation, you first call `useRequestDeliveryMutationAPI` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestDeliveryMutationAPI` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestDeliveryMutationApi, { data, loading, error }] = useRequestDeliveryMutationAPI({
 *   variables: {
 *      id: // value for 'id'
 *      store: // value for 'store'
 *      language: // value for 'language'
 *      quantity: // value for 'quantity'
 *      requestedShippingDate: // value for 'requestedShippingDate'
 *   },
 * });
 */
export function useRequestDeliveryMutationAPI(baseOptions?: Apollo.MutationHookOptions<RequestDeliveryMutation, RequestDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestDeliveryMutation, RequestDeliveryMutationVariables>(RequestDeliveryDocument, options);
      }
export type RequestDeliveryMutationAPIHookResult = ReturnType<typeof useRequestDeliveryMutationAPI>;
export type RequestDeliveryMutationResult = Apollo.MutationResult<RequestDeliveryMutation>;
export type RequestDeliveryMutationOptions = Apollo.BaseMutationOptions<RequestDeliveryMutation, RequestDeliveryMutationVariables>;
export const RequestQuantityChangeDocument = gql`
    mutation requestQuantityChange($quantity: Int!, $id: Int!, $store: ID!) {
  requestQuantityChange(quantity: $quantity, id: $id, store: $store)
}
    `;
export type RequestQuantityChangeMutationFn = Apollo.MutationFunction<RequestQuantityChangeMutation, RequestQuantityChangeMutationVariables>;

/**
 * __useRequestQuantityChangeMutationAPI__
 *
 * To run a mutation, you first call `useRequestQuantityChangeMutationAPI` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestQuantityChangeMutationAPI` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestQuantityChangeMutationApi, { data, loading, error }] = useRequestQuantityChangeMutationAPI({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      id: // value for 'id'
 *      store: // value for 'store'
 *   },
 * });
 */
export function useRequestQuantityChangeMutationAPI(baseOptions?: Apollo.MutationHookOptions<RequestQuantityChangeMutation, RequestQuantityChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestQuantityChangeMutation, RequestQuantityChangeMutationVariables>(RequestQuantityChangeDocument, options);
      }
export type RequestQuantityChangeMutationAPIHookResult = ReturnType<typeof useRequestQuantityChangeMutationAPI>;
export type RequestQuantityChangeMutationResult = Apollo.MutationResult<RequestQuantityChangeMutation>;
export type RequestQuantityChangeMutationOptions = Apollo.BaseMutationOptions<RequestQuantityChangeMutation, RequestQuantityChangeMutationVariables>;